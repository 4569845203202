import {
  GET_STATE_CITY_BY_ZIPCODE,
  GET_STATE_CITY_BY_ZIPCODE_SUCCESS,
  GET_STATE_CITY_BY_ZIPCODE_FAILURE,
  TAX_CALCULATION_BY_ADDRESS_TYPE1,
  TAX_CALCULATION_BY_ADDRESS_TYPE1_SUCCESS,
  TAX_CALCULATION_BY_ADDRESS_TYPE1_FAILURE,
  TAX_CALCULATION_BY_ADDRESS_TYPE2,
  TAX_CALCULATION_BY_ADDRESS_TYPE2_SUCCESS,
  TAX_CALCULATION_BY_ADDRESS_TYPE2_FAILURE,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  CHECK_EMAIL_AVAILBLITY,
  CHECK_EMAIL_AVAILBLITY_SUCCESS,
  CHECK_EMAIL_AVAILBLITY_FAILURE,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  UPDATE_SECONDARY_CUSTOMER,
  UPDATE_SECONDARY_CUSTOMER_SUCCESS,
  UPDATE_SECONDARY_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_SOURCE,
  UPDATE_CUSTOMER_SOURCE_SUCCESS,
  UPDATE_CUSTOMER_SOURCE_FAILURE,
  ELIGIBILITY,
  ELIGIBILITY_SUCCESS,
  ELIGIBILITY_FAILURE,
  COUPON,
  COUPON_SUCCESS,
  COUPON_FAILURE,
  PAYMENT,
  PAYMENT_SUCCESS,
  PAYMENT_FAILURE,
  PAYMENT_GET,
  PAYMENT_GET_SUCCESS,
  PAYMENT_GET_FAILURE,
  COUPON_RESERVE,
  COUPON_RESERVE_SUCCESS,
  COUPON_RESERVE_FAILURE,
  RESET_CUSTOMER,
  LOGIN_NUMBER,
  LOGIN_NUMBER_SUCCESS,
  LOGIN_NUMBER_FAILURE,
  SHIPPING_GET_DATA,
  SHIPPING_SUCCESS,
  SHIPPING_FAILURE,
  GET_SHIPMENT_ORDER_BY_ID,
  GET_SHIPMENT_ORDER_BY_ID_SUCCESS,
  GET_SHIPMENT_ORDER_BY_ID_FAILURE,
  HSD_ADD_SUCCESS,
  HSD_ADD_FAILURE,
} from "./actionTypes";

import { CheckoutState, CheckoutActions } from "./types";

const initialState: CheckoutState = {
  stateCityByZipcode: [],
  errorStateCityByZipcode: null,
  taxCalculationByAddressType1: [],
  taxCalculationByAddressType2: [],
  errorTaxCalculationByAddressType1: null,
  errorTaxCalculationByAddressType2: null,
  createCustomer: [],
  errorCreateCustomer: null,
  getCustomer: [],
  errorGetCustomer: null,
  updateCustomer: [],
  updateSecondaryCustomer: [],
  errorUpdateSecondaryCustomer: null,
  errorUpdateCustomer: null,
  updateCustomerSource: [],
  errorUpdateCustomerSource: null,
  eligibility: [],
  errorEligibility: null,
  coupon: [],
  errorCoupon: null,
  payment: [],
  errorPayment: null,
  paymentGet: [],
  errorPaymentGet: null,
  couponReserve: [],
  errorCouponReserve: null,
  isZipLoaded: false,
  isTax1Loaded: false,
  isTax2Loaded: false,
  isCreateCustomerLoaded: false,
  isGetCustomerLoaded: false,
  isUpdateCustomerLoaded: false,
  isUpdateSecondaryCustomerLoaded: false,
  isUpdateCustomerSourceLoaded: false,
  isEligibiltyLoaded: false,
  isCouponLoaded: false,
  isPaymentLoaded: false,
  isPaymentGetLoaded: false,
  isCouponReserveLoaded: false,
  isCheckEmailLoaded: false,
  loginNumberSuccess: null,
  errorLoginNumber: null,
  shippingData: null,
  shippingError: null,
  isShippingDataLoaded: false,
  getShipmentOrderById: [],
  isGetShipmentOrderByIdLoaded: false,
  errorGetShipmentOrderById: null,
  hsdAdd: null,
  hsdAddError: null,
};

export default (state = initialState, action: CheckoutActions) => {
  switch (action.type) {
    case GET_STATE_CITY_BY_ZIPCODE:
      return {
        ...state,
        isZipLoaded: true,
      };
    case GET_STATE_CITY_BY_ZIPCODE_SUCCESS:
      return {
        ...state,
        stateCityByZipcode: action.payload.stateCityByZipcode,
        errorStateCityByZipcode: null,
        isZipLoaded: false,
      };
    case GET_STATE_CITY_BY_ZIPCODE_FAILURE:
      return {
        ...state,
        stateCityByZipcode: [],
        errorStateCityByZipcode: action.payload.errorStateCityByZipcode,
        isZipLoaded: false,
      };
    case TAX_CALCULATION_BY_ADDRESS_TYPE1:
      return {
        ...state,
        isTax1Loaded: true,
      };
    case TAX_CALCULATION_BY_ADDRESS_TYPE1_SUCCESS:
      return {
        ...state,
        taxCalculationByAddressType1:
          action.payload.taxCalculationByAddressType1,
        errorTaxCalculationByAddressType1: null,
        isTax1Loaded: false,
      };
    case TAX_CALCULATION_BY_ADDRESS_TYPE1_FAILURE:
      return {
        ...state,
        taxCalculationByAddressType1: [],
        errorTaxCalculationByAddressType1:
          action.payload.errorTaxCalculationByAddressType1,
        isTax1Loaded: false,
      };
    case TAX_CALCULATION_BY_ADDRESS_TYPE2:
      return {
        ...state,
        isTax2Loaded: true,
      };
    case TAX_CALCULATION_BY_ADDRESS_TYPE2_SUCCESS:
      return {
        ...state,
        taxCalculationByAddressType2:
          action.payload.taxCalculationByAddressType2,
        errorTaxCalculationByAddressType2: null,
        isTax2Loaded: false,
      };
    case TAX_CALCULATION_BY_ADDRESS_TYPE2_FAILURE:
      return {
        ...state,
        taxCalculationByAddressType2: [],
        errorTaxCalculationByAddressType2:
          action.payload.errorTaxCalculationByAddressType2,
        isTax2Loaded: false,
      };
    case CREATE_CUSTOMER:
      return {
        ...state,
        isCreateCustomerLoaded: true,
      };
    case CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        createCustomer: action.payload.createCustomer,
        errorCreateCustomer: null,
        isCreateCustomerLoaded: false,
      };
    case CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        createCustomer: [],
        errorCreateCustomer: action.payload.errorCreateCustomer,
        isCreateCustomerLoaded: false,
      };

    case SHIPPING_GET_DATA:
      return {
        ...state,
        isShippingDataLoaded: true,
      };
    case SHIPPING_SUCCESS:
      return {
        ...state,
        shippingData: action.payload.shippingData,
        shippingError: null,
        isShippingDataLoaded: false,
      };
    case SHIPPING_FAILURE:
      return {
        ...state,
        shippingData: [],
        shippingError: action.payload.errorShippingData,
        isShippingDataLoaded: false,
      };

    case GET_CUSTOMER:
      return {
        ...state,
        isGetCustomerLoaded: true,
      };
    case RESET_CUSTOMER:
      return {
        ...state,
        getCustomer: [],
      };
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        getCustomer: action.payload.getCustomer,
        errorCreateCustomer: null,
        isGetCustomerLoaded: false,
      };
    case GET_CUSTOMER_FAILURE:
      return {
        ...state,
        getCustomer: [],
        errorGetCustomer: action.payload.errorGetCustomer,
        isGetCustomerLoaded: false,
      };

    case GET_SHIPMENT_ORDER_BY_ID:
      return {
        ...state,
        isGetShipmentOrderByIdLoaded: true,
      };
    case GET_SHIPMENT_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        getShipmentOrderById: action.payload.getShipmentOrderById,
        errorGetShipmentOrderById: null,
        isGetShipmentOrderByIdLoaded: false,
      };
    case GET_SHIPMENT_ORDER_BY_ID_FAILURE:
      return {
        ...state,
        getShipmentOrderById: [],
        errorGetShipmentOrderById: action.payload.errorGetShipmentOrderById,
        isGetShipmentOrderByIdLoaded: false,
      };

    case CHECK_EMAIL_AVAILBLITY:
      return {
        ...state,
        isCheckEmailLoaded: true,
      };
    case CHECK_EMAIL_AVAILBLITY_SUCCESS:
      return {
        ...state,
        checkEmailAvailability: action.payload.checkEmailAvailability,
        errorcheckEmailAvailability: null,
        isCheckEmailLoaded: false,
      };
    case CHECK_EMAIL_AVAILBLITY_FAILURE:
      return {
        ...state,
        checkEmailAvailability: [],
        errorCheckEmailAvailability: action.payload.errorCheckEmailAvailability,
        isCheckEmailLoaded: false,
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        isUpdateCustomerLoaded: true,
      };
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        updateCustomer: action.payload.updateCustomer,
        errorUpdateCustomer: null,
        isUpdateCustomerLoaded: false,
      };
    case UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        updateCustomer: [],
        errorUpdateCustomer: action.payload.errorUpdateCustomer,
        isUpdateCustomerLoaded: false,
      };

    case UPDATE_SECONDARY_CUSTOMER:
      return {
        ...state,
        isUpdateSecondaryCustomerLoaded: true,
      };
    case UPDATE_SECONDARY_CUSTOMER_SUCCESS:
      return {
        ...state,
        updateSecondaryCustomer: action.payload.updateSecondaryCustomer,
        errorUpdateSecondaryCustomer: null,
        isUpdateSecondaryCustomerLoaded: false,
      };
    case UPDATE_SECONDARY_CUSTOMER_FAILURE:
      return {
        ...state,
        updateSecondaryCustomer: [],
        errorUpdateSecondaryCustomer:
          action.payload.errorUpdateSecondaryCustomer,
        isUpdateSecondaryCustomerLoaded: false,
      };

    case UPDATE_CUSTOMER_SOURCE:
      return {
        ...state,
        isUpdateCustomerSourceLoaded: true,
      };
    case UPDATE_CUSTOMER_SOURCE_SUCCESS:
      return {
        ...state,
        updateCustomerSource: action.payload.updateCustomerSource,
        errorUpdateCustomerSource: null,
        isUpdateCustomerSourceLoaded: false,
      };
    case UPDATE_CUSTOMER_SOURCE_FAILURE:
      return {
        ...state,
        updateCustomerSource: [],
        errorUpdateCustomerSource: action.payload.errorUpdateCustomerSource,
        isUpdateCustomerSourceLoaded: false,
      };
    case ELIGIBILITY:
      return {
        ...state,
        isEligibiltyLoaded: true,
      };
    case ELIGIBILITY_SUCCESS:
      return {
        ...state,
        eligibility: action.payload.eligibility,
        errorEligibility: null,
        isEligibiltyLoaded: false,
      };
    case ELIGIBILITY_FAILURE:
      return {
        ...state,
        eligibility: [],
        errorEligibility: action.payload.errorEligibility,
        isEligibiltyLoaded: false,
      };
    case COUPON:
      return {
        ...state,
        isCouponLoaded: true,
      };
    case COUPON_SUCCESS:
      return {
        ...state,
        coupon: action.payload.coupon,
        errorCoupon: null,
        isCouponLoaded: false,
      };
    case COUPON_FAILURE:
      return {
        ...state,
        coupon: [],
        errorCoupon: action.payload.errorCoupon,
        isCouponLoaded: false,
      };
    case PAYMENT:
      return {
        ...state,
        isPaymentLoaded: true,
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payload.payment,
        errorPayment: null,
        isPaymentLoaded: false,
      };
    case PAYMENT_FAILURE:
      return {
        ...state,
        payment: [],
        errorPayment: action.payload.errorPayment,
        isPaymentLoaded: false,
      };
    case PAYMENT_GET:
      return {
        ...state,
        isPaymentGetLoaded: true,
      };
    case PAYMENT_GET_SUCCESS:
      return {
        ...state,
        paymentGet: action.payload.paymentGet,
        errorPaymentGet: null,
        isPaymentGetLoaded: false,
      };
    case PAYMENT_GET_FAILURE:
      return {
        ...state,
        paymentGet: [],
        errorPaymentGet: action.payload.errorPaymentGet,
        isPaymentGetLoaded: false,
      };
    case COUPON_RESERVE:
      return {
        ...state,
        isCouponReserveLoaded: true,
      };
    case COUPON_RESERVE_SUCCESS:
      return {
        ...state,
        couponReserve: action.payload.couponReserve,
        errorCouponReserve: null,
        isCouponReserveLoaded: false,
      };
    case COUPON_RESERVE_FAILURE:
      return {
        ...state,
        couponReserve: [],
        errorCouponReserve: action.payload.errorCouponReserve,
        isCouponReserveLoaded: false,
      };
    case LOGIN_NUMBER:
      return {
        ...state,
      };
    case LOGIN_NUMBER_SUCCESS:
      return {
        ...state,
        loginNumberSuccess: action.payload.loginNumberSuccess,
        errorReachNumberSignup: null,
      };
    case LOGIN_NUMBER_FAILURE:
      return {
        ...state,
        reachNumberSignup: [],
        errorLoginNumber: action.payload.errorLoginNumber,
      };
    case HSD_ADD_SUCCESS:
      return {
        ...state,
        hsdAdd: action.payload.hsdAdd,
      };

    case HSD_ADD_FAILURE:
      return {
        ...state,
        hsdAddError: action.payload.hsdAddError,
      };
    default:
      return {
        ...state,
      };
  }
};
