import { combineReducers } from "redux";

// import todoReducer from './todo/reducer';
import HomeReducer from "./HomePage/reducer";
import AuthReducer from "./Auth/reducer";
import PlanReducer from "./PlanPage/reducer";
import CheckoutReducer from "./Checkout/reducer";
import ActivationReducer from "./Activation/reducer";
// import planReducer from './PlanPage/reducer';
// import InternationalServiceReducer from './InternationalServices/reducer';
// import CheckoutReducer from './Checkout/reducer';
import TrackOrderReducer from "./TrackOrder/reducer";
// import ActivationReducer from './Activation/reducer';
import PostActivationReducer from "./PostActivation/reducer";
import BillingReducer from "./Billing/reducer";
// import DeviceReducer from './EarthlinkDevice/reducer';

const rootReducer = combineReducers({
  // todo: todoReducer,
  home: HomeReducer,
  auth: AuthReducer,
  plan: PlanReducer,
  checkout: CheckoutReducer,

  // internationalService: InternationalServiceReducer,
  // checkout: CheckoutReducer,
  trackOrder: TrackOrderReducer,
  activation: ActivationReducer,
  postActivation: PostActivationReducer,
  billing: BillingReducer,
  // earthDevice: DeviceReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
