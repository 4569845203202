//imei details
export const IMEI = 'IMEI';
export const IMEI_SUCCESS = 'IMEI_SUCCESS';
export const IMEI_FAILURE = 'IMEI_FAILURE';

//port validate details
export const PORT = 'PORT';
export const PORT_SUCCESS = 'PORT_SUCCESS';
export const PORT_FAILURE = 'PORT_FAILURE';

//port validate details result
export const PORT_RESULT = 'PORT_RESULT';
export const PORT_RESULT_SUCCESS = 'PORT_RESULT_SUCCESS';
export const PORT_RESULT_FAILURE = 'PORT_RESULT_FAILURE';

//get customer credit card
export const GET_CC = 'GET_CC';
export const GET_CC_SUCCESS = 'GET_CC_SUCCESS';
export const GET_CC_FAILURE = 'GET_CC_FAILURE';

// update credit to autppay
export const UPDATE_CC_AUTOPAY = 'UPDATE_CC_AUTOPAY';
export const UPDATE_CC_AUTOPAY_SUCCESS = 'UPDATE_CC_AUTOPAY_SUCCESS';
export const UPDATE_CC_AUTOPAY_FAILURE = 'UPDATE_CC_AUTOPAY_FAILURE';

// remove cc
export const REMOVE_CC = 'REMOVE_CC';
export const REMOVE_CC_SUCCESS = 'REMOVE_CC_SUCCESS';
export const REMOVE_CC_FAILURE = 'REMOVE_CC_FAILURE';

// card hold
export const CC_HOLD = 'CC_HOLD';
export const CC_HOLD_SUCCESS = 'CC_HOLD_SUCCESS';
export const CC_HOLD_FAILURE = 'CC_HOLD_FAILURE';

// check Icc
export const CHECK_ICC = 'CHECK_ICC';
export const CHECK_ICC_SUCCESS = 'CHECK_ICC_SUCCESS';
export const CHECK_ICC_FAILURE = 'CHECK_ICC_FAILURE';

// update cc expiry
export const UPDATE_CC_EXPIRY = 'UPDATE_CC_EXPIRY';
export const UPDATE_CC_EXPIRY_SUCCESS = 'UPDATE_CC_EXPIRY_SUCCESS';
export const UPDATE_CC_EXPIRY_FAILURE = 'UPDATE_CC_EXPIRY_FAILURE';

// add card
export const ADD_CARD = 'ADD_CARD';
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';
export const ADD_CARD_FAILURE = 'ADD_CARD_FAILURE';

// activation
export const ACTIVATION = 'ACTIVATION';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAILURE = 'ACTIVATION_FAILURE';

//activation status
export const ACTIVATION_STATUS = 'ACTIVATION_STATUS';
export const ACTIVATION_STATUS_SUCCESS = 'ACTIVATION_STATUS_SUCCESS';
export const ACTIVATION_STATUS_FAILURE = 'ACTIVATION_STATUS_FAILURE';

//data usage
export const DATA_USAGE = 'DATA_USAGE';
export const DATA_USAGE_SUCCESS = 'DATA_USAGE_SUCCESS';
export const DATA_USAGE_FAILURE = 'DATA_USAGE_FAILURE';

//current data usage
export const CURRENT_DATA_USAGE = 'CURRENT_DATA_USAGE';
export const CURRENT_DATA_USAGE_SUCCESS = 'CURRENT_DATA_USAGE_SUCCESS';
export const CURRENT_DATA_USAGE_FAILURE = 'CURRENT_DATA_USAGE_FAILURE';

//credit
export const REACH_CREDIT = 'REACH_CREDIT';
export const REACH_CREDIT_SUCCESS = 'REACH_CREDIT_SUCCESS';
export const REACH_CREDIT_FAILURE = 'REACH_CREDIT_FAILURE';

//home overall
export const OVER_ALL = 'OVER_ALL';
export const OVER_ALL_SUCCESS = 'OVER_ALL_SUCCESS';
export const OVER_ALL_FAILURE = 'OVER_ALL_FAILURE';

//monthInAdvance
export const MONTH_IN_ADVANCE = 'MONTH_IN_ADVANCE';
export const MONTH_IN_ADVANCE_POST = 'MONTH_IN_ADVANCE_POST';
export const MONTH_IN_ADVANCE_SUCCESS = 'MONTH_IN_ADVANCE_SUCCESS';
export const MONTH_IN_ADVANCE_FAILURE = 'MONTH_IN_ADVANCE_FAILURE';
export const MONTH_IN_ADVANCE_POST_SUCCESS= 'MONTH_IN_ADVANCE_POST_SUCCESS';
export const MONTH_IN_ADVANCE_POST_FAILURE = 'MONTH_IN_ADVANCE_POST_FAILURE';