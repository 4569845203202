import * as types from './actionTypes';

import { BillingState, BillingActions } from './types';

const initialState: BillingState = {
    getBill: [],
    errorGetBill: null,
    billCustomize: [],
    errorBillCustomize: null,
    orderHistory: [],
    errorOrderHistory: null,
    userPlans: [],
    errorUserPlans: null,
    billForecast: [],
    errorBillForecast: null,
    eligiblePayLater: [],
    errorEligiblePayLater: null,
    billPay: [],
    errorBillPay: null,
    emailBill: [],
    errorEmailBill: null,
    promisePay: [],
    errorPromisePay: null,
    getAdvance: [],
    errorGetAdvcance: null,
    payAdvance: [],
    errorPayAdvance: null,
    dataUsage: [],
    errorDataUsage: null,
    config: [],
    errorConfig: null,
    referalDetail: [],
    errorReferalDetail: null,
};

export default (state = initialState, action: BillingActions) => {
    switch (action.type) {
        case types.GET_BILL:
            return {
                ...state,
                getBill: [],
                errorGetBill: null,
            };
        case types.GET_BILL_SUCCESS:
            return {
                ...state,
                getBill: action.payload.getBill,
                errorGetBill: null,
            };
        case types.GET_BILL_FAILURE:
            return {
                ...state,
                getBill: [],
                errorGetBill: action.payload.errorGetBill,
            };

        case types.BILL_CUSTOMIZE:
            return {
                ...state,
                billCustomize: [],
                errorBillCustomize: null,
            };
        case types.BILL_CUSTOMIZE_SUCCESS:
            return {
                ...state,
                billCustomize: action.payload.billCustomize,
                errorBillCustomize: null,
            };
        case types.BILL_CUSTOMIZE_FAILURE:
            return {
                ...state,
                billCustomize: [],
                errorBillCustomize: action.payload.errorBillCustomize,
            };
        case types.ORDER_HISTORY:
            return {
                ...state,
                orderHistory: [],
                errorOrderHistory: null,
            };
        case types.ORDER_HISTORY_SUCCESS:
            return {
                ...state,
                orderHistory: action.payload.orderHistory,
                errorOrderHistory: null,
            };
        case types.ORDER_HISTORY_FAILURE:
            return {
                ...state,
                orderHistory: [],
                errorOrderHistory: action.payload.errorOrderHistory,
            };
        case types.USER_PLANS:
            return {
                ...state,
                userPlans: [],
                errorUserPlans: null,
            };
        case types.USER_PLANS_SUCCESS:
            return {
                ...state,
                userPlans: action.payload.userPlans,
                errorUserPlans: null,
            };
        case types.USER_PLANS_FAILURE:
            return {
                ...state,
                userPlans: [],
                errorUserPlans: action.payload.errorUserPlans,
            };
        case types.BILL_FORECAST:
            return {
                ...state,
                billForecast: [],
                errorBillForecast: null,
            };
        case types.BILL_FORECAST_SUCCESS:
            return {
                ...state,
                billForecast: action.payload.billForecast,
                errorBillForecast: null,
            };
        case types.BILL_FORECAST_FAILURE:
            return {
                ...state,
                billForecast: [],
                errorBillForecast: action.payload.errorBillForecast,
            };
        case types.ELIGIBLE_PAY_LATER:
            return {
                ...state,
                eligiblePayLater: [],
                errorEligiblePayLater: null,
            };
        case types.ELIGIBLE_PAY_LATER_SUCCESS:
            return {
                ...state,
                eligiblePayLater: action.payload.eligiblePayLater,
                errorEligiblePayLater: null,
            };
        case types.ELIGIBLE_PAY_LATER_FAILURE:
            return {
                ...state,
                eligiblePayLater: [],
                errorEligiblePayLater: action.payload.errorEligiblePayLater,
            };
        case types.BILL_PAY:
            return {
                ...state,
                billPay: [],
                errorBillPay: null,
            };
        case types.BILL_PAY_SUCCESS:
            return {
                ...state,
                billPay: action.payload.billPay,
                errorBillPay: null,
            };
        case types.BILL_PAY_FAILURE:
            return {
                ...state,
                billPay: [],
                errorBillPay: action.payload.errorBillPay,
            };
        case types.EMAIL_BILL:
            return {
                ...state,
                emailBill: [],
                errorEmailBill: null,
            };
        case types.EMAIL_BILL_SUCCESS:
            return {
                ...state,
                emailBill: action.payload.emailBill,
                errorEmailBill: null,
            };
        case types.EMAIL_BILL_FAILURE:
            return {
                ...state,
                emailBill: [],
                errorEmailBill: action.payload.errorEmailBill,
            };
        case types.PROMISE_PAY:
            return {
                ...state,
                promisePay: [],
                errorPromisePay: null,
            };
        case types.PROMISE_PAY_SUCCESS:
            return {
                ...state,
                promisePay: action.payload.promisePay,
                errorPromisePay: null,
            };
        case types.PROMISE_PAY_FAILURE:
            return {
                ...state,
                promisePay: [],
                errorPromisePay: action.payload.errorPromisePay,
            };
        case types.GET_ADVANCE:
            return {
                ...state,
                getAdvance: [],
                errorGetAdvance: null,
            };
        case types.GET_ADVANCE_SUCCESS:
            return {
                ...state,
                getAdvance: action.payload.getAdvance,
                errorGetAdvance: null,
            };
        case types.GET_ADVANCE_FAILURE:
            return {
                ...state,
                getAdvance: [],
                errorGetAdvance: action.payload.errorGetAdvance,
            };
        case types.DATA_USAGE:
            return {
                ...state,
                dataUsage: [],
                errorDataUsage: null,
            };
        case types.DATA_USAGE_SUCCESS:
            return {
                ...state,
                dataUsage: action.payload.dataUsage,
                errorDataUsage: null,
            };
        case types.DATA_USAGE_FAILURE:
            return {
                ...state,
                dataUsage: [],
                errorDataUsage: action.payload.errorDataUsage,
            };
        case types.PAY_ADVANCE:
            return {
                ...state,
                payAdvance: [],
                errorPayAdvance: null,
            };
        case types.PAY_ADVANCE_SUCCESS:
            return {
                ...state,
                payAdvance: action.payload.payAdvance,
                errorPayAdvance: null,
            };
        case types.PAY_ADVANCE_FAILURE:
            return {
                ...state,
                payAdvance: [],
                errorPayAdvance: action.payload.errorPayAdvance,
            };

        case types.CONFIG:
            return {
                ...state,
                config: [],
                errorConfig: null,
            };
        case types.CONFIG_SUCCESS:
            return {
                ...state,
                config: action.payload.config,
                errorConfig: null,
            };
        case types.CONFIG_FAILURE:
            return {
                ...state,
                config: [],
                errorConfig: action.payload.errorConfig,
            };

        case types.REFERAL_DETAIL:
            return {
                ...state,
                referalDetail: [],
                errorReferalDetail: null,
            };
        case types.REFERAL_DETAIL_SUCCESS:
            return {
                ...state,
                referalDetail: action.payload.referalDetail,
                errorReferalDetail: null,
            };
        case types.REFERAL_DETAIL_FAILURE:
            return {
                ...state,
                referalDetail: [],
                errorReferalDetail: action.payload.errorReferalDetail,
            };

        default:
            return {
                ...state,
            };
    }
};
