import { Close } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Button, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { Modal as HeaderModal } from 'react-bootstrap';
import { Store } from 'react-notifications-component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { globalVal } from '../../global/globalvalues';
import { ResetCustomer } from '../../redux/Checkout/actions';
import firebase from '../../utils/firebase';

type Props = {
    isPrimary: boolean;
};

const PostHeader = (props: Props) => {
    const navigate = useNavigate();
    // let navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setanchorEl] = useState(null);
    const [anchorEl1, setanchorEl1] = useState(null);
    const [anchorEl3, setanchorEl3] = useState(null);
    const [phoneOptionSelected, setphoneOptionSelected] = useState('');
    const [servicesOptionSelected, setservicesOptionSelected] = useState('');
    const [user, setuser] = useState({ displayName: '' });
    const [isShowPlan, setisShowPlan] = useState(true);
    const [isModal, setisModal] = useState(false);
    const [isServiceEnable, setisServiceEnable] = useState(false);
    const [isPhoneEnable, setisPhoneEnable] = useState(false);

    // componentDidMount() {
    //     let user1: any = localStorage.getItem('currentuser');
    //     user1 = JSON.parse(user1);
    //     user1 &&
    //         !user1.isAnonymous &&
    //         this.setState({
    //             user: user1,
    //         });
    //     let customer: any = localStorage.getItem('customerDetail');
    //     customer = JSON.parse(customer);
    //     customer &&
    //         customer &&
    //         customer.shipmentInfo &&
    //         customer.reachPlanId &&
    //         Object.keys(customer.shipmentInfo).length > 0 &&
    //         Object.keys(customer.shipmentInfo)[0] &&
    //         this.setState({
    //             isShowPlan: false,
    //         });
    // }

    const handleClick = (event: any) => {
        setanchorEl(event.currentTarget);
    };
    const handleLogout = () => {
        setanchorEl3(null);
        localStorage.clear();

        Store.removeAllNotifications();

        firebase
            .auth()
            .signOut()
            .then(() => {
                localStorage.removeItem('currentuser');
                localStorage.removeItem('accessToken');

                setuser({ displayName: '' });

                // navigate("/home");
            })
            .catch((err) => {
                console.log(err);
            });
        dispatch(ResetCustomer());
        navigate('/home');
    };

    const handleClose = () => {
        setanchorEl(null);
    };
    const handleClick1 = (event: any) => {
        setanchorEl1(event.currentTarget);
    };
    const handleClick3 = (event: any) => {
        setanchorEl3(event.currentTarget);
    };
    const handlePhoneOptions = (e: any) => {
        setphoneOptionSelected(e);

        if (e === 'Buy a phone') {
            window.open(`${globalVal && globalVal.shopPhone}`, '_blank');
        } else if (e === 'Bring your own') {
            navigate('/imei-compatibility-check');
        }
        handleClose1();
    };
    const handleServicesOptions = (e: any) => {
        setservicesOptionSelected(e);
        if (e === 'International Services') {
            navigate('/international_services');
        }
        handleClose();
    };

    const handleClose1 = () => {
        setanchorEl1(null);
    };
    const handleClose3 = () => {
        setanchorEl3(null);
    };
    const handleClickedMiddleButtons = (e: any) => {
        setisModal(false);

        if (e === 'billing') {
            navigate('/billing');
        } else if (e === 'home') {
            navigate('/post-activation-home');
        } else if (e === 'account') {
            navigate('/account');
        } else if (e === 'services') {
            navigate('/services');
        } else if (e === 'phones') {
            window.open(`${globalVal && globalVal.shopPhone}`, '_blank');
        } else if (e === 'profile') {
            navigate('/profile');
        } else if (e === 'help') {
            navigate('/help');
        } else if (e === 'settings') {
            navigate('/settings');
        }
    };
    const openModal = () => {
        setisModal(true);
    };
    const onHide = () => {
        setisModal(false);
    };
    const toggleServices = () => {
        setisServiceEnable(!isServiceEnable);
    };
    const togglePhone = () => {
        setisPhoneEnable(!isPhoneEnable);
    };

    useEffect(() => {
        let user1: any = localStorage.getItem('currentuser');
        user1 = JSON.parse(user1);
        user1 && !user1.isAnonymous && setuser(user1);
        let customer: any = localStorage.getItem('customerDetail');
        customer = JSON.parse(customer);
        customer &&
            customer &&
            customer.shipmentInfo &&
            customer.reachPlanId &&
            Object.keys(customer.shipmentInfo).length > 0 &&
            Object.keys(customer.shipmentInfo)[0] &&
            setisShowPlan(false);
    }, []);

    return (
        <div className="headerMain">
            <AppBar position="fixed" className="headerContent">
                <div className="headerInnerDiv">
                    <div onClick={() => navigate('/')}>
                        <img className="headerLogo" src={globalVal && globalVal.logo} alt="" />
                    </div>
                    <div className="arrayDivHeader">
                        <div
                            className={
                                window.location.pathname === '/post-activation-home'
                                    ? 'buttonMiddleHeaderPost activeRouteHeader'
                                    : 'buttonMiddleHeaderPost'
                            }
                        >
                            <Button
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                className="fontStyleHeader"
                                onClick={(e: any) => handleClickedMiddleButtons('home')}
                            >
                                HOME
                            </Button>
                        </div>
                        <div
                            className={
                                window.location.pathname === '/account'
                                    ? 'buttonMiddleHeaderPost activeRouteHeader'
                                    : 'buttonMiddleHeaderPost'
                            }
                        >
                            <Button
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                className="fontStyleHeader"
                                onClick={(e: any) => handleClickedMiddleButtons('account')}
                            >
                                ACCOUNT
                            </Button>
                        </div>
                        <div
                            className={
                                window.location.pathname === '/services'
                                    ? 'buttonMiddleHeaderPost activeRouteHeader'
                                    : 'buttonMiddleHeaderPost'
                            }
                        >
                            <Button
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                className="fontStyleHeader"
                                onClick={(e: any) => handleClickedMiddleButtons('services')}
                            >
                                SERVICES
                            </Button>
                        </div>
                        <div
                            className={
                                window.location.pathname === '/imei-compatibility-check'
                                    ? 'buttonMiddleHeaderPost activeRouteHeader'
                                    : 'buttonMiddleHeaderPost'
                            }
                        >
                            <Button
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                className="fontStyleHeader"
                                onClick={(e: any) => handleClickedMiddleButtons('phones')}
                            >
                                Phones
                            </Button>
                        </div>

                        {props && props.isPrimary ? (
                            <div
                                className={
                                    window.location.pathname === '/billing'
                                        ? 'buttonMiddleHeaderPost activeRouteHeader'
                                        : 'buttonMiddleHeaderPost'
                                }
                            >
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className="fontStyleHeader"
                                    onClick={(e: any) => handleClickedMiddleButtons('billing')}
                                >
                                    BILLING
                                </Button>
                            </div>
                        ) : null}
                    </div>
                    <div className="arrayDivHeader">
                        <Button aria-controls="simple-menu" aria-haspopup="true" className="fontStyleHeader">
                            <a
                                href={globalVal.homeInternet}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="homeinternetlink"
                            >
                                HOME INTERNET
                            </a>
                        </Button>
                    </div>
                    <div className="arrayDivHeader otherButtons">
                        <div className={'buttonMiddleHeader'}>
                            <Button
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                className="fontStyleHeader"
                                onClick={(e: any) => handleClickedMiddleButtons('help')}
                            >
                                HELP
                            </Button>
                        </div>
                        <div className={'buttonMiddleHeader1'}>
                            <div className="lastDiv1">
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className={anchorEl ? 'clickedHeaderTab fontStyleHeader' : 'fontStyleHeader'}
                                    // onMouseOver={this.handleClick3}
                                    onClick={handleClick3}
                                >
                                    <div className="innerLast">
                                        <AccountCircleIcon />
                                        <div className="name">
                                            {user.displayName ? user.displayName.split(' ')[0] : ''}{' '}
                                        </div>
                                    </div>
                                </Button>

                                <Menu
                                    id="simple-menu"
                                    className="SimpleHeaderDropdown"
                                    anchorEl={anchorEl3}
                                    keepMounted
                                    open={Boolean(anchorEl3)}
                                    // MenuListProps={{ onMouseLeave: this.handleClose3 }}
                                    onClose={handleClose3}
                                >
                                    <MenuItem
                                        className="menuitemHeader"
                                        onClick={() => {
                                            setanchorEl3(null);
                                            navigate('/profile');
                                        }}
                                    >
                                        PROFILE
                                    </MenuItem>
                                    <MenuItem
                                        className="menuitemHeader"
                                        onClick={() => {
                                            setanchorEl3(null);
                                            navigate('/settings');
                                        }}
                                    >
                                        SETTINGS
                                    </MenuItem>
                                    <MenuItem className="menuitemHeader" onClick={handleLogout}>
                                        LOGOUT
                                    </MenuItem>
                                </Menu>

                                {/* 
                <Menu
                  id="simple-menu"
                  className="SimpleHeaderDropdown"
                  anchorEl={anchorEl3}
                  keepMounted
                  open={Boolean(anchorEl3)}
                  // MenuListProps={{ onMouseLeave: this.handleClose3 }}
                  onClose={handleClose3}
                >
                  <MenuItem className="menuitemHeader" onClick={handleLogout}>
                    Logout
                  </MenuItem>
                </Menu> */}
                            </div>
                        </div>
                    </div>
                </div>
            </AppBar>

            <AppBar position="fixed" className="headerContentMobile">
                <div className="headerContentMobileinner">
                    <div>
                        <MenuIcon
                            style={{
                                color: 'var(--primary_color)',
                                width: '50px',
                                height: '51px',
                                padding: '0px 0px 0px 15px',
                            }}
                            onClick={openModal}
                        />
                    </div>

                    <div
                    //   onClick={() => navigate("/")}
                    >
                        <img className="headerLogoMobile" src={globalVal && globalVal.logo} alt="header logo" />
                    </div>
                </div>
            </AppBar>
            <HeaderModal
                show={isModal}
                onHide={onHide}
                size={'xl'}
                className="modalMainHeader"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Close className="closeIcon" onClick={onHide} />
                <div className="bottomContent">
                    <div
                        className={
                            window.location.pathname === '/post-activation-home'
                                ? 'menuItems  activeRouteHeader'
                                : 'menuItems'
                        }
                        onClick={(e: any) => handleClickedMiddleButtons('home')}
                    >
                        HOME
                    </div>
                    <div
                        className={
                            window.location.pathname === '/account' ? 'menuItems  activeRouteHeader' : 'menuItems'
                        }
                        onClick={(e: any) => handleClickedMiddleButtons('account')}
                    >
                        ACCOUNT
                    </div>
                    <div
                        className={
                            window.location.pathname === '/services' ? 'menuItems  activeRouteHeader' : 'menuItems'
                        }
                        onClick={(e: any) => handleClickedMiddleButtons('services')}
                    >
                        SERVICES
                    </div>
                    <div
                        className={
                            window.location.pathname === '/phones' ? 'menuItems  activeRouteHeader' : 'menuItems'
                        }
                        onClick={(e: any) => handleClickedMiddleButtons('phones')}
                    >
                        PHONES
                    </div>

                    {props && props.isPrimary ? (
                        <div
                            className={
                                window.location.pathname === '/billing' ? 'menuItems  activeRouteHeader' : 'menuItems'
                            }
                            onClick={(e: any) => handleClickedMiddleButtons('billing')}
                        >
                            BILLING
                        </div>
                    ) : null}

                    <div className="menuItems">
                        <a
                            href={globalVal.homeInternet}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="homeinternetlink"
                        >
                            HOME INTERNET
                        </a>
                    </div>
                    <div
                        className={window.location.pathname === '/help' ? 'menuItems  activeRouteHeader' : 'menuItems'}
                        onClick={(e: any) => handleClickedMiddleButtons('help')}
                    >
                        HELP
                    </div>
                    <div
                        className={
                            window.location.pathname === '/profile' ? 'menuItems  activeRouteHeader' : 'menuItems'
                        }
                        onClick={(e: any) => handleClickedMiddleButtons('profile')}
                    >
                        PROFILE
                    </div>
                    <div className="menuItems greyColor" onClick={(e: any) => handleClickedMiddleButtons('settings')}>
                        SETTINGS
                    </div>
                    <div className="menuItems greyColor" onClick={handleLogout}>
                        LOGOUT
                    </div>
                </div>
            </HeaderModal>
        </div>
    );
};

export default PostHeader;
