import apiJunction from "../utils/api";
import { version } from "../config/config";
import {
  checkCoverage,
  checkCoverageFailure,
  checkCoverageSuccess,
  coveragePlansFailure,
  coveragePlansSuccess,
  discountAppliedFailure,
  discountAppliedSuccess,
  getInTouch,
  getInTouchFailure,
  getInTouchSuccess,
  wowEligibilityFailure,
  wowEligibilitySuccess,
} from "../redux/HomePage/actions";

export const checkCoverageApi = (address: any) => {
  return async (dispatch: any) => {
    dispatch(checkCoverage({}));
    try {
      let res = await apiJunction.makeRequest({
        method: "post",
        url: `/provsvc/${version}/address/network/coverage`,
        body: address,
      });

      if (res.status === 200) {
        dispatch(checkCoverageSuccess({ coverageStatus: res.data.data }));
      }
    } catch (err: any) {
      dispatch(
        checkCoverageFailure({
          errorCoverageStatus: err.response?.data ?? err,
        })
      );
    }
  };
};

export const getInTouchApi = (action: any) => {
  return async (dispatch: any) => {
    // dispatch(getInTouch(action.data))
    try {
      let res = await apiJunction.makeRequest({
        method: "post",
        url: `/custsvc/${version}/customers/hubspot`,
        body: action.data,
      });
      if (res.status === 200) {
        dispatch(getInTouchSuccess({ getInTouch: res.data }));
      }
    } catch (err: any) {
      dispatch(
        getInTouchFailure({
          getInTouchError: err.message,
        })
      );
    }
  };
};

//coveragePlans
export const coveragePlansApi = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "get",
        url: `/provsvc/${version}/mdn/zipcode/availability/${payload.zipcode}`,
      });
      if (res.status === 200) {
        dispatch(
          coveragePlansSuccess({
            coveragePlans: res.data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        coveragePlansFailure({
          coverageError: {
            data: err.response && err.response.data,
            status: err.response && err.response.status,
          },
        })
      );
    }
  };
};

export const wowEligibilityApi = (action: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "post",
        url: `/wowsvc/fetch/fetchDiscountCode`,
        body: action.data,
        contentType: "application/json",
      });

      if (res.status === 200 || res.status === 206) {
        dispatch(wowEligibilitySuccess({ wowEligibility: res.data }));
      }
    } catch (err: any) {
      dispatch(
        wowEligibilityFailure({
          wowEligibilityError: {
            data: err.response && err.response.data,
            status: err.response && err.response.status,
          },
        })
      );
    }
  };
};

export const discountAppliedApi = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "get",
        url: `wowutilssvc/v0/hsd/discount/applied/${payload.wowCustomerId}`,
        contentType: "application/json",
      });

      if (
        res.status === 200 ||
        (res && res.data && res.data.status === "SUCCESS")
      ) {
        dispatch(discountAppliedSuccess({ discountApplied: res.data }));
      }
    } catch (err: any) {
      if (
        (err && err.response && err.response.status) === 404 ||
        (err &&
          err.response &&
          err.response.data &&
          err.response.data.status) === "FAILURE"
      ) {
        dispatch(
          discountAppliedFailure({
            discountAppliedError: {
              data: err.response && err.response.data,
              status: err.response && err.response.status,
            },
          })
        );
      }
    }
  };
};

export const HomeService = {
  checkCoverageApi,
  getInTouchApi,
  coveragePlansApi,
  wowEligibilityApi,
  discountAppliedApi,
};
