import * as types from "./actionTypes";

import { PostActivationState, PostActivationActions } from "./types";

const initialState: PostActivationState = {
  dataCutOff: [],
  errorDataCutOff: null,
  topUp: [],
  errorTopUp: null,
  topUpPulse: [],
  errorTopUpPulse: null,
  group: [],
  errorGroup: null,
  topUpGroup: [],
  errorTopUpGroup: null,
  getCredit: [],
  errorGetCredit: null,
  type4Tax: [],
  errorType4Tax: null,
  topUpCustomer: [],
  errorTopUpCustomer: null,
  referalCredit: [],
  errorReferalCredit: null,
  changePlan: [],
  errorChangePlan: null,
  topUpCustomerStatus: [],
  errorTopUpCustomerStatus: null,
  planChangeCustomerStatus: [],
  errorPlanChangeCustomerStatus: null,
};

export default (state = initialState, action: PostActivationActions) => {
  switch (action.type) {
    case types.DATA_CUTOFF:
      return {
        ...state,
      };
    case types.DATA_CUTOFF_SUCCESS:
      return {
        ...state,
        dataCutOff: action.payload.dataCutOff,
        errorDataCutOff: null,
      };
    case types.DATA_CUTOFF_FAILURE:
      return {
        ...state,
        dataCutOff: [],
        errorDataCutOff: action.payload.errorDataCutOff,
      };

    case types.TOP_UP:
      return {
        ...state,
      };
    case types.TOP_UP_SUCCESS:
      return {
        ...state,
        topUp: action.payload.topUp,
        errorTopUp: null,
      };
    case types.TOP_UP_FAILURE:
      return {
        ...state,
        topUp: [],
        errorTopUp: action.payload.errorTopUp,
      };

    case types.TOP_UP_PULSE:
      return {
        ...state,
      };
    case types.TOP_UP_PULSE_SUCCESS:
      return {
        ...state,
        topUpPulse: action.payload.topUpPulse,
        errorTopUpPulse: null,
      };
    case types.TOP_UP_PULSE_FAILURE:
      return {
        ...state,
        topUpPulse: [],
        errorTopUpPulse: action.payload.errorTopUpPulse,
      };

    case types.GROUPS:
      return {
        ...state,
      };
    case types.GROUPS_SUCCESS:
      return {
        ...state,
        group: action.payload.group,
        errorGroup: null,
      };
    case types.GROUPS_FAILURE:
      return {
        ...state,
        group: [],
        errorGroup: action.payload.errorGroup,
      };

    case types.TOP_UP_GROUPS:
      return {
        ...state,
      };
    case types.TOP_UP_GROUPS_SUCCESS:
      return {
        ...state,
        topUpGroup: action.payload.topUpGroup,
        errorTopUpGroup: null,
      };
    case types.TOP_UP_GROUPS_FAILURE:
      return {
        ...state,
        topUpGroup: [],
        errorTopUpGroup: action.payload.errorTopUpGroup,
      };

    case types.GET_CREDIT:
      return {
        ...state,
      };
    case types.GET_CREDIT_SUCCESS:
      return {
        ...state,
        getCredit: action.payload.getCredit,
        errorGetCredit: null,
      };
    case types.GET_CREDIT_FAILURE:
      return {
        ...state,
        getCredit: [],
        errorGetCredit: action.payload.errorGetCredit,
      };

    case types.TAX_4_TYPE:
      return {
        ...state,
      };

    case types.RESET_TAX_4_TYPE:
      return {
        ...state,
        type4Tax: [],
      };

    case types.TAX_4_TYPE_SUCCESS:
      return {
        ...state,
        type4Tax: action.payload.type4Tax,
        errorType4Tax: null,
      };
    case types.TAX_4_TYPE_FAILURE:
      return {
        ...state,
        type4Tax: [],
        errorType4Tax: action.payload.errorType4Tax,
      };
    case types.REFERAL_CREDIT:
      return {
        ...state,
      };
    case types.REFERAL_CREDIT_SUCCESS:
      return {
        ...state,
        referalCredit: action.payload.referalCredit,
        errorReferalCredit: null,
      };
    case types.REFERAL_CREDIT_FAILURE:
      return {
        ...state,
        referalCredit: [],
        errorReferalCredit: action.payload.errorReferalCredit,
      };
    case types.CHANGE_PLAN:
      return {
        ...state,
      };
    case types.CHANGE_PLAN_SUCCESS:
      return {
        ...state,
        changePlan: action.payload.changePlan,
        errorChangePlan: null,
      };
    case types.CHANGE_PLAN_FAILURE:
      return {
        ...state,
        changePlan: [],
        errorChangePlan: action.payload.errorChangePlan,
      };

    case types.TOP_UP_CUSTOMER:
      return {
        ...state,
      };
    case types.TOP_UP_CUSTOMER_SUCCESS:
      return {
        ...state,
        topUpCustomer: action.payload.topUpCustomer,
        errorTopUpCustomer: null,
      };
    case types.TOP_UP_CUSTOMER_FAILURE:
      return {
        ...state,
        topUpCustomer: [],
        errorTopUpCustomer: action.payload.errorTopUpCustomer,
      };
      case types.TOP_UP_CUSTOMER:
        return {
            ...state,
        };
    case types.TOP_UP_CUSTOMER_SUCCESS:
        return {
            ...state,
            topUpCustomer: action.payload.topUpCustomer,
            errorTopUpCustomer: null,
        };
    case types.TOP_UP_CUSTOMER_FAILURE:
        return {
            ...state,
            topUpCustomer: [],
            errorTopUpCustomer: action.payload.errorTopUpCustomer,
        };

    case types.TOP_UP_CUSTOMER_STATUS:
    return {
        ...state,
    };
    case types.TOP_UP_CUSTOMER_STATUS_SUCCESS:
        return {
            ...state,
            topUpCustomerStatus: action.payload.topUpCustomerStatus,
            errorTopUpCustomerStatus: null,
        };
    case types.TOP_UP_CUSTOMER_STATUS_FAILURE:
        return {
            ...state,
            topUpCustomerStatus: [],
            errorTopUpCustomerStatus: action.payload.errorTopUpCustomerStatus,
        };

        case types.PLAN_CHANGE_CUSTOMER_STATUS:
        return {
            ...state,
        };
        case types.PLAN_CHANGE_CUSTOMER_STATUS_SUCCESS:
            return {
                ...state,
                planChangeCustomerStatus: action.payload.planChangeCustomerStatus,
                errorPlanChangeCustomerStatus: null,
            };
        case types.PLAN_CHANGE_CUSTOMER_STATUS_FAILURE:
            return {
                ...state,
                planChangeCustomerStatus: [],
                errorPlanChangeCustomerStatus: action.payload.errorPlanChangeCustomerStatus,
            };


    default:
      return {
        ...state,
      };
  }
};
