import image1 from "../../assets/images/Home/homeTT.svg";
import image2 from "../../assets/images/Home/no-contract.svg";
import image3 from "../../assets/images/Home/helpful-humans.svg";
import image4 from "../../assets/images/Home/homeHotspot.svg";
import image5 from "../../assets/images/Home/international-services.svg";
import image6 from "../../assets/images/Home/upto-6-lines.svg";
import image7 from "../../assets/images/Home/HD-streaming.svg";
import image8 from "../../assets/images/Home/keep-your-num.svg";
import { homePage } from "../../global/texts/Homepage";
import "./HomeSpotlightCards.scss";
import { useLocation } from "react-router-dom";

type Props = {};

const HomeSpotlightCards = (props: Props) => {
  const location = useLocation();
  let planDetail: any = localStorage.getItem("reachSelectedPlanDetail");
  if (planDetail) {
    planDetail = JSON.parse(planDetail);
  }

  return (
    <div className="homeSpotlightCardsBlk">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 col-sm-3">
            <div className="itemBox text-center">
              <div className="itemImage">
                <img src={image1} alt="image" width="76" />
              </div>
              <h5>{homePage.homeSpotlight.heading.heading1}</h5>
              {/* <p>{homePage.homeSpotlight.subHeading.subHeading1}</p> */}
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <div className="itemBox text-center">
              <div className="itemImage">
                <img src={image2} alt="image" width="76" />
              </div>
              <h5>{homePage.homeSpotlight.heading.heading2}</h5>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <div className="itemBox text-center">
              <div className="itemImage">
                <img src={image3} alt="image" width="76" />
              </div>
              <h5>{homePage.homeSpotlight.heading.heading3}</h5>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <div className="itemBox text-center">
              <div className="itemImage">
                <img src={image4} alt="image" width="76" />
              </div>
              <h5>{homePage.homeSpotlight.heading.heading4}</h5>
            </div>
          </div>
        </div>
        <div className="row feature-second-sec justify-content-center">
          <div className="col-md-3 col-sm-3">
            <div className="itemBox text-center">
              <div className="itemImage">
                <img src={image5} alt="image" width="76" />
              </div>
              <h5>{homePage.homeSpotlight.heading.heading5}</h5>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <div className="itemBox text-center">
              <div className="itemImage">
                <img src={image6} alt="image" width="76" />
              </div>
              <h5>{homePage.homeSpotlight.heading.heading6}</h5>
            </div>
          </div>
          {location.pathname.toLowerCase() !== "/ul-bundle-promo" && (
            <div className="col-md-3 col-sm-3">
              <div className="itemBox text-center">
                <div className="itemImage">
                  <img src={image7} alt="image" width="76" />
                </div>
                <h5>{homePage.homeSpotlight.heading.heading7}</h5>
              </div>
            </div>
          )}
          <div className="col-md-3 col-sm-3">
            <div className="itemBox text-center">
              <div className="itemImage">
                <img src={image8} alt="image" width="76" />
              </div>
              <h5>{homePage.homeSpotlight.heading.heading8}</h5>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="itemBox text-center">
        <div className="itemImage">
          <img src={image1} alt="image" width="76" />
        </div>
        <h5>{homePage.homeSpotlight.heading.heading1}</h5>
        <p>{homePage.homeSpotlight.subHeading.subHeading1}</p>
      </div>
      <div className="itemBox text-center">
        <div className="itemImage">
          <img src={noContract} alt="image" width="76" />
        </div>
        <h5>{homePage.homeSpotlight.heading.heading4}</h5>
        <p>{homePage.homeSpotlight.subHeading.subHeading2}</p>
      </div>
      <div className="itemBox text-center">
        <div className="itemImage">
          <img src={humans} alt="image" width="76" />
        </div>
        <h5>{homePage.homeSpotlight.heading.heading5}</h5>
        <p>{homePage.homeSpotlight.subHeading.subHeading3}</p>
      </div>
      <div className="itemBox text-center">
        <div className="itemImage">
          <img src={image3} alt="image" width="76" />
        </div>
        <h5>{homePage.homeSpotlight.heading.heading2}</h5>
        <p>{homePage.homeSpotlight.subHeading.subHeading2}</p>
      </div> */}
    </div>
  );
};

export default HomeSpotlightCards;
