// import React, { useEffect, useState } from "react";
// import { Navigate } from "react-router-dom";

// type Props = { user?: any; children?: any };

// const ProtectedRoutes = ({ user, children }: Props) => {
//   //   const [currentUser, setcurrentUser] = useState<any>(null);

//   //   useEffect(() => {
//   //     let user = localStorage.getItem("currentuser");
//   //     setcurrentUser(user);
//   //   }, []);

//   //   return currentUser &&
//   //     currentUser &&
//   //     currentUser.uid &&
//   //     currentUser.stsTokenManager &&
//   //     currentUser.stsTokenManager.accessToken &&
//   //     !currentUser.isAnonymous ? (
//   //     <div>ProtectedRoutes</div>
//   //   ) : (
//   //     <div>Unprotech</div>
//   //   );
//   // };

//   // const ProtectedRoute = ({ user, children }) => {
//   if (!user) {
//     return <Navigate to="/login" replace />;
//   }

//   return children;
//   // };
// };

// export default ProtectedRoutes;

import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoutes = () => {
  const location = useLocation();
  let user: any = localStorage.getItem("currentuser");
  const currentuser: any = JSON.parse(user);

  return currentuser &&
    currentuser.uid &&
    currentuser.stsTokenManager &&
    currentuser.stsTokenManager.accessToken &&
    !currentuser.isAnonymous ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};
export default ProtectedRoutes;
