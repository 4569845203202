import { globalVal } from '../globalvalues';

export function updateToHubspot(event: Event, email: string) {
    var isTestObj: any = {};
    isTestObj.is_test = globalVal && globalVal.hubspot_is_test;
    isTestObj.is_qa = globalVal && globalVal.hubspot_is_qa;
    var hubObj = { email: email };
    var hubPropObj = Object.assign({}, hubObj, isTestObj);
    var _hsq = ((window as any)._hsq = (window as any)._hsq || []);
    _hsq.push(['identify', hubPropObj]);
    _hsq.push(['trackPageView', 'Home']);
    if (event) {
        _hsq.push([
            'trackEvent',
            {
                id: event,
            },
        ]);
    }
}
