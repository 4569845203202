import ReachLogo from "../assets/images/commonImages/wow_logo.svg";

export const globalVal: any = {
  logo: ReachLogo,
  long_name: "WOW! mobile",
  short_name: "WOW!",
  long_name_lowercase: "wow mobile",
  long_capital_name: "WOW! Mobile",
  primary_color: "#10ace3",
  primary_font_color: "#48688f",
  primary_brand_color: "#12abe3",
  white: "#ffffff",
  honey: "#eeab18",
  burnt_sienna: "#c06b14",
  blush: "#f79a91",
  dusk: "#48688f",
  lavender: "#a976a2",
  jade: "#3a8580",
  hubspot_is_test:
    process.env.REACT_APP_SERVER_NAME === "wow-prod" ? false : true, // set "false" for production
  hubspot_is_qa:
    process.env.REACT_APP_SERVER_NAME === "wow-prod" ? false : true, // set false for dev and production only
  sorting_order: "LH", // low to high - LH and hight to low -HL
  max_card_count: 4,
  max_line_count: 6,
  dev_hubSpot_id: process.env.REACT_APP_HUBSPOT_DEV_ID,
  prod_hubSpot_id: process.env.REACT_APP_HUBSPOT_PROD_ID,
  source: "TMOBILE", // 'TELISPIRE'
  aws_url: process.env.REACT_APP_LP_CLOUDFRONT_URL,
  phoneNumber: "(833) 909-4402",
  support_time: "Call us 9AM - 9PM EST",
  email: "wowmobile@reachmobile.com",
  REACH_INVITE_REFERRALS_BID_E: "A528E96A9DD82CBC83DE6AAA8F31B801",
  REACH_INVITE_REFERRALS_BID: "26304",
  REACH_INVITE_REFERRALS_T: "420",
  REACH_INVITE_REFERRALS_CAMPAIGNID: "22443", //22851 for Dev // 22443 for QA // 0 for production
  encryption: "WPA",
  hiddenSSID: false,
  bestFitPlans: [
    "Best Fit 15GB",
    "Best Fit 10GB",
    "Best Fit 5GB",
    "Best Fit 2GB",
  ],
  reachUrl: "https://www.reachmobile.com",
  reachBestFitVideoUrl: "https://youtu.be/91Kpfx24-2I",
  blogURL: "https://connect.reachmobile.com/",
  unlockPhoneBlogURL:
    "https://learn.reachmobile.com/how-do-i-know-if-my-device-is-unlocked-how-can-i-unlock-my-device",
  IOSURL: "https://reachmobileqa.page.link/imXbios", // for prod https://reachmobile.page.link/NeL7
  androidUrl: "https://reachmobileqa.page.link/imXbgoogle", // for prod https://reachmobile.page.link/dnxH
  shopPhone: "https://shop.wow.reachmobile.com/",
  helpCenter: "https://learn.reachmobile.com/",
  homeInternet: "http://wowway.com/",
  facebook: "https://www.facebook.com/Reach4Good/",
  twitter: "https://twitter.com/reach4good",
  instagram: "https://www.instagram.com/reach4good/",
  youtube: "https://www.youtube.com/channel/UC2DGt_hGmh0365jamPCSRxA",
  findIMEIUrl:
    "https://learn.reachmobile.com/how-do-i-find-my-imei-what-is-an-imei",
  imeiTwitterUrl: "https://twitter.com/intent/tweet?",
  imeiFacebookUrl:
    "https://www.facebook.com/dialog/share?app_id=2511315725603016&display=popup",
  discountOfferPlanCards:
    "Pricing shown includes discount available to eligible and verified WOW! residential internet customers",
  hostUrl: "https://wow.reachmobile.com",
};
