import * as types from "./actionTypes";

import { ActivationState, ActivationActions } from "./types";

const initialState: ActivationState = {
  imei: [],
  errorImei: null,
  port: [],
  errorPort: null,
  portResult: [],
  errorPortResult: null,
  getCC: [],
  errorGetCC: null,
  updateCCAutoPay: [],
  errorUpdateCCAutoPay: null,
  removeCC: [],
  errorRemoveCC: null,
  ccHold: [],
  errorCCHold: null,
  checkIcc: [],
  errorCheckIcc: null,
  updateCCExpiry: [],
  errorUpdateCCExpiry: null,
  addCard: [],
  errorAddCard: null,
  activation: [],
  errorActivation: null,
  activationStatus: [],
  errorActivationStatus: null,
  dataUsage: [],
  errorDataUsage: null,
  reachCredit: [],
  errorReachCredit: null,
  overAll: [],
  errorOverAll: null,
  monthInAdvance: {},
  errorMonthInAdvance: null,
  monthInAdvancePost: {},
  errorMonthInAdvancePost: null,
  currentDataUsage: [],
  errorCurrentDataUsage: null,
};

export default (state = initialState, action: ActivationActions) => {
  switch (action.type) {
    case types.IMEI:
      return {
        ...state,
      };
    case types.IMEI_SUCCESS:
      return {
        ...state,
        imei: action.payload.imei,
        errorImei: null,
      };
    case types.IMEI_FAILURE:
      return {
        ...state,
        imei: [],
        errorImei: action.payload.errorImei,
      };

    case types.PORT:
      return {
        ...state,
      };
    case types.PORT_SUCCESS:
      return {
        ...state,
        port: action.payload.port,
        errorPort: null,
      };
    case types.PORT_FAILURE:
      return {
        ...state,
        port: [],
        errorPort: action.payload.errorPort,
      };

    case types.PORT_RESULT:
      return {
        ...state,
      };
    case types.PORT_RESULT_SUCCESS:
      return {
        ...state,
        portResult: action.payload.portResult,
        errorPortResult: null,
      };
    case types.PORT_RESULT_FAILURE:
      return {
        ...state,
        portResult: [],
        errorPortResult: action.payload.errorPortResult,
      };

    case types.GET_CC:
      return {
        ...state,
      };
    case types.GET_CC_SUCCESS:
      return {
        ...state,
        getCC: action.payload.getCC,
        errorGetCC: null,
      };
    case types.GET_CC_FAILURE:
      return {
        ...state,
        getCC: [],
        errorGetCC: action.payload.errorGetCC,
      };

    case types.UPDATE_CC_AUTOPAY:
      return {
        ...state,
      };
    case types.UPDATE_CC_AUTOPAY_SUCCESS:
      return {
        ...state,
        updateCCAutoPay: action.payload.updateCCAutoPay,
        errorUpdateCCAutoPay: null,
      };
    case types.UPDATE_CC_AUTOPAY_FAILURE:
      return {
        ...state,
        updateCCAutoPay: [],
        errorUpdateCCAutoPay: action.payload.errorUpdateCCAutoPay,
      };

    case types.REMOVE_CC:
      return {
        ...state,
      };
    case types.REMOVE_CC_SUCCESS:
      return {
        ...state,
        removeCC: action.payload.removeCC,
        errorRemoveCC: null,
      };
    case types.REMOVE_CC_FAILURE:
      return {
        ...state,
        removeCC: [],
        errorRemoveCC: action.payload.errorRemoveCC,
      };

    case types.CC_HOLD:
      return {
        ...state,
      };
    case types.CC_HOLD_SUCCESS:
      return {
        ...state,
        ccHold: action.payload.ccHold,
        errorCCHold: null,
      };
    case types.CC_HOLD_FAILURE:
      return {
        ...state,
        ccHold: [],
        errorCCHold: action.payload.errorCCHold,
      };

    case types.CHECK_ICC:
      return {
        ...state,
      };
    case types.CHECK_ICC_SUCCESS:
      return {
        ...state,
        checkIcc: action.payload.checkIcc,
        errorCheckIcc: null,
      };
    case types.CHECK_ICC_FAILURE:
      return {
        ...state,
        checkIcc: [],
        errorCheckIcc: action.payload.errorCheckIcc,
      };

    case types.UPDATE_CC_EXPIRY:
      return {
        ...state,
      };
    case types.UPDATE_CC_EXPIRY_SUCCESS:
      return {
        ...state,
        updateCCExpiry: action.payload.updateCCExpiry,
        errorUpdateCCExpiry: null,
      };
    case types.UPDATE_CC_EXPIRY_FAILURE:
      return {
        ...state,
        updateCCExpiry: [],
        errorUpdateCCExpiry: action.payload.errorUpdateCCExpiry,
      };

    case types.ADD_CARD:
      return {
        ...state,
      };
    case types.ADD_CARD_SUCCESS:
      return {
        ...state,
        addCard: action.payload.addCard,
        errorAddCard: null,
      };
    case types.ADD_CARD_FAILURE:
      return {
        ...state,
        addCard: [],
        errorAddCard: action.payload.errorAddCard,
      };

    case types.ACTIVATION:
      return {
        ...state,
        activation: [],
      };
    case types.ACTIVATION_SUCCESS:
      return {
        ...state,
        activation: action.payload.activation,
        errorActivation: null,
      };
    case types.ACTIVATION_FAILURE:
      return {
        ...state,
        activation: [],
        errorActivation: action.payload.errorActivation,
      };

    case types.ACTIVATION_STATUS:
      return {
        ...state,
        activationStatus: [],
        errorActivationStatus: null,
      };
    case types.ACTIVATION_STATUS_SUCCESS:
      return {
        ...state,
        activationStatus: action.payload.activationStatus,
        errorActivationStatus: null,
      };
    case types.ACTIVATION_STATUS_FAILURE:
      return {
        ...state,
        activationStatus: [],
        errorActivationStatus: action.payload.errorActivationStatus,
      };

    case types.DATA_USAGE:
      return {
        ...state,
      };
    case types.DATA_USAGE_SUCCESS:
      return {
        ...state,
        dataUsage: action.payload.dataUsage,
        errorDataUsage: null,
      };
    case types.DATA_USAGE_FAILURE:
      return {
        ...state,
        dataUsage: [],
        errorDataUsage: action.payload.errorDataUsage,
      };

    case types.CURRENT_DATA_USAGE:
      return {
        ...state,
      };
    case types.CURRENT_DATA_USAGE_SUCCESS:
      return {
        ...state,
        currentDataUsage: action.payload.currentDataUsage,
        errorCurrentDataUsage: null,
      };
    case types.CURRENT_DATA_USAGE_FAILURE:
      return {
        ...state,
        currentDataUsage: [],
        errorCurrentDataUsage: action.payload.errorCurrentDataUsage,
      };

    case types.REACH_CREDIT:
      return {
        ...state,
      };
    case types.REACH_CREDIT_SUCCESS:
      return {
        ...state,
        reachCredit: action.payload.reachCredit,
        errorReachCredit: null,
      };
    case types.REACH_CREDIT_FAILURE:
      return {
        ...state,
        reachCredit: [],
        errorReachCredit: action.payload.errorReachCredit,
      };

    case types.OVER_ALL:
      return {
        ...state,
      };
    case types.OVER_ALL_SUCCESS:
      return {
        ...state,
        overAll: action.payload.overAll,
        errorOverAll: null,
      };
    case types.OVER_ALL_FAILURE:
      return {
        ...state,
        overAll: [],
        errorOverAll: action.payload.errorOverAll,
      };
    case types.MONTH_IN_ADVANCE_SUCCESS:
      return {
        ...state,
        monthInAdvance: action.payload.monthInAdvance,
        errorMonthInAdvance: null,
      };
    case types.MONTH_IN_ADVANCE_POST_SUCCESS:
      return {
        ...state,
        monthInAdvancePost: action.payload.monthInAdvancePost,
        errorMonthInAdvancePost: null,
      };
    case types.MONTH_IN_ADVANCE_POST_FAILURE:
      return {
        ...state,
        monthInAdvancePost: {},
        errorMonthInAdvancePost: action.payload.errorMonthInAdvancePost
          ? action.payload.errorMonthInAdvancePost
          : "error",
      };
    case types.MONTH_IN_ADVANCE_FAILURE:
      return {
        ...state,
        monthInAdvance: {},
        errorMonthInAdvance: action.payload.errorMonthInAdvance,
      };

    default:
      return {
        ...state,
      };
  }
};
