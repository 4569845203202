import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, CircularProgress } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import closeIcon from '../../../assets/images/Home/closeIcon.svg';
import tickIcon from '../../../assets/images/Home/tickIcon.svg';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import { pushTrackingEventsToGAAndFB } from '../../../global/commonFunctions/GaAndFb';
import { HideLoader, checkCoverage } from '../../../redux/HomePage/actions';
import { HomeService } from '../../../services/home.service';
import './CheckCoverage.scss';

type Props = {};

const CheckCoverage = (props: Props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { coverageStatus, coverageLoading, errorCoverageStatus } = useSelector((state: any) => state.home);
    const [submitClick, setsubmitClick] = useState(false);
    const [showCoverage, setshowCoverage] = useState(false);
    const [coverageError, setcoverageError] = useState(false);
    const [checkAddress, setcheckAddress] = useState('');
    const [coverageAddress, setcoverageAddress] = useState({
        address1: '',
        city: '',
        state: '',
        country: '',
        zip: '',
    });
    const [signal4G, setsignal4G] = useState('');
    const [signal5G, setsignal5G] = useState('');

    useEffect(() => {
        return () => {
            setcheckAddress('');
            setcoverageAddress({
                address1: '',
                city: '',
                state: '',
                country: '',
                zip: '',
            });
            setshowCoverage(false);
            dispatch(checkCoverage(coverageAddress));
            dispatch(HideLoader({}));
        };
    }, []);

    useEffect(() => {
        if (!_.isEmpty(coverageStatus)) {
            setcheckAddress('');
            setcoverageAddress({
                address1: '',
                city: '',
                state: '',
                country: '',
                zip: '',
            });
            setsignal4G(coverageStatus.signal4g);
            setsignal5G(coverageStatus.signal5g);
            setshowCoverage(true);
        }
    }, [coverageStatus]);

    useEffect(() => {
        if (!_.isEmpty(errorCoverageStatus)) {
            setcheckAddress('');
            setcoverageError(true);
        }
    }, [errorCoverageStatus]);

    const checkAddressHandler = (e: any) => {
        let checkAddArray = e.split(',');
        let coverageAddressNew = {
            address1: '',
            city: '',
            country: '',
            state: '',
            zip: '',
        };
        if (e.includes(',')) {
            coverageAddressNew = {
                address1: checkAddArray[0],
                city: checkAddArray[1],
                country: checkAddArray[2],
                state: checkAddArray[3],
                zip: checkAddArray[4],
            };
        }

        setcheckAddress(e);
        setcoverageAddress(!_.isEmpty(coverageAddress) ? coverageAddressNew : coverageAddress);
    };

    const handleSelect = (address: any) => {
        geocodeByAddress(address)
            .then(async (results: any) => {
                let city: string = '',
                    state: string = '',
                    zip: string = '',
                    country: string = '',
                    route: string = '',
                    streetNumber: string = '';
                results &&
                    results[0] &&
                    results[0].address_components &&
                    results[0].address_components.map((el: any) => {
                        state = el.types.includes('administrative_area_level_1') ? el.short_name : state;
                        zip = el.types.includes('postal_code') ? el.short_name : zip;
                        city = el.types.includes("locality")
                          ? el.long_name
                          : el.types.includes("sublocality")
                          ? el.long_name
                          : el.types.includes("neighborhood")
                          ? el.long_name
                          : city;
                        streetNumber = el.types.includes('street_number') ? el.short_name : streetNumber;
                        route = el.types.includes('route') ? el.long_name : route;
                        country = el.types.includes('country') ? el.short_name : country;
                        return null;
                    });
                if (streetNumber && route) {
                    setcoverageAddress({
                        address1: streetNumber + ' ' + route,
                        city,
                        state,
                        country,
                        zip,
                    });
                    setcheckAddress(
                        streetNumber + ' ' + route + ', ' + city + ', ' + state + ', ' + country + ', ' + zip,
                    );
                    setcoverageError(false);
                } else {
                    setcoverageAddress({
                        address1: '',
                        city,
                        state,
                        country,
                        zip,
                    });
                    setcheckAddress(city + ', ' + state + ', ' + country + ', ' + zip);
                    setcoverageError(true);
                }
            })
            .catch((error) => console.error('error', error));
    };

    const checkDisableHandler = () => {
        return (
            checkAddress === '' ||
            !coverageAddress.address1 ||
            !coverageAddress.city ||
            !coverageAddress.state ||
            !coverageAddress.country ||
            !coverageAddress.zip ||
            coverageLoading
        );
    };

    const getStarted = (e: any) => {
        pushTrackingEventsToGAAndFB(
            {
                category: 'Home',
                actionType: 'View Plans',
            },
            e,
        );
        scroller.scrollTo('bestCellPhonePlan', {
            duration: 1000,
            delay: 0,
            // smooth: 'easeInOutQuart',
            // smooh: 'easeOutQuart',
            offset: -100,
        });
    };
    const checkCoverageHandler = (address: any) => {
        setcoverageError(false);
        setsignal4G('');
        setsignal5G('');
        dispatch(HomeService.checkCoverageApi(address));
        pushTrackingEventsToGAAndFB({
            category: 'Home',
            actionType: 'Check Coverage',
        });
    };

    return (
        <div className="checkCoverageOuter">
            <div className="coverageCheckHeading">Confirm your coverage, then select your plan</div>
            <div className="coverageCheckSubHeading">
                Tap into the nation's largest and fastest 5G network, coast-to-coast
            </div>
            {!showCoverage && (
                <div className="addressHolder">
                    <AutoComplete
                        value={checkAddress}
                        onChange={checkAddressHandler}
                        onSelect={(e: any) => {
                            handleSelect(e);
                        }}
                        disabled={coverageLoading}
                        className="mb-4"
                        placeholder="Enter your address"
                        autoComplete={'off'}
                    />

                    <Button
                        variant="outlined"
                        className="submitBtn"
                        startIcon={coverageLoading ? <CircularProgress color="secondary" /> : <ArrowForwardIcon />}
                        onClick={() => checkCoverageHandler(coverageAddress)}
                        disabled={checkAddress === '' ? true : false}
                    ></Button>
                </div>
            )}
            {showCoverage && (
                <div>
                    {signal4G !== 'no service' || signal5G !== 'no service' ? (
                        <div className="coverageFinalText">
                            <img src={tickIcon} />
                            <span className="m-0">
                                4G coverage{' '}
                                {signal4G === 'fair'
                                    ? 'looks okay'
                                    : signal4G === 'good'
                                    ? 'looks good!'
                                    : signal4G === 'great'
                                    ? 'looks great!'
                                    : "isn't promising"}
                            </span>
                            <span className="m-0">
                                {' '}
                                5G coverage{' '}
                                {signal5G === 'fair'
                                    ? 'looks okay'
                                    : signal5G === 'good'
                                    ? 'looks good!'
                                    : signal5G === 'great'
                                    ? ' looks great!'
                                    : 'is limited'}
                            </span>
                            {/* <p className="signalNote">
                5G is available on Android devices. 5G is coming soon to
                iPhones.
              </p> */}
                        </div>
                    ) : (
                        <div className="coverageFinalText">
                            <img src={closeIcon} />
                            <span>Your address is not in our service area</span>
                        </div>
                    )}
                    <div className="coverageButtons">
                        <Button onClick={() => setshowCoverage(false)}>Try another address</Button>
                        <Button onClick={getStarted}> Explore Plans</Button>
                        <Button
                            onClick={() => {
                                setshowCoverage(false);
                                navigate('/coverage-check');
                            }}
                        >
                            View detailed coverage
                        </Button>
                    </div>
                </div>
            )}

            {coverageError && (
                <div className="coverageError">
                    Please enter your full address, including street, city, state and ZIP Code​.
                </div>
            )}

            {/* <ButtonNew
        className="InfoCardDetailBtn2"
        primary={false}
        variant="outlined"
        onClick={() => {
          //   this.setState({
          //     coverageError: false,
          //     signal4G: "",
          //     signal5G: "",
          //   });
          //   this.props.homeAction.checkCoverage(coverageAddress);
        }}
        disabled={true}
      >
        Icon
      </ButtonNew> */}
        </div>
    );
};

export default CheckCoverage;
