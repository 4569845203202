import * as React from "react";
// import discoverImage from "../../assets/images/Home/wow-discover-section-image.webp";
import discoverImage from "../../assets/images/Home/wow-discover-section-image1.webp";
import discoverImageiPad from "../../assets/images/Home/wow-discover-section-image1.webp";
import discoverImageiPadPro from "../../assets/images/Home/wow-discover-section-image1.webp";
import discoverMobileImage from "../../assets/images/Home/wow-discover-section-image1.webp";
import { homePageWow } from "../../global/texts/Homepage";
import "./DiscoverSectionWow.scss";
import step1Icon from "../../assets/images/Home/wowhome-discover-progress-step1.svg";
import step2Icon from "../../assets/images/Home/wowhome-discover-progress-step2.svg";
import step3Icon from "../../assets/images/Home/wowhome-discover-progress-step3.svg";
import step4Icon from "../../assets/images/Home/wowhome-discover-progress-step4.svg";
import { Link, useNavigate } from "react-router-dom";
import { pushTrackingEventsToGAAndFB } from "../../global/commonFunctions/GaAndFb";

type Props = {};

const DiscoverSectionWow: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  return (
    <div className="row mx-3 discoverSection">
      <div className="col-12 col-md-12 col-lg-6 ml-3 top">
        <div className="discoverTitle">{homePageWow.discoverSection.title}</div>
        <div className="HomePageLine"></div>
        <h6 className="my-3 topsubTitle ">
          {homePageWow.discoverSection.subtitle}
        </h6>

        <section className="mt-5">
          <div className="rt-container">
            <div className="col-rt-12">
              <div className="Scriptcontent">
                <div className="step">
                  <div>
                    <div className="circle">
                      <img src={step1Icon} alt="" />
                    </div>
                  </div>
                  <div className="notFirst">
                    <h3 className="stepTitle">
                      {homePageWow.discoverSection.progressSteps.first.title}
                    </h3>
                    <p className="stepDesc">
                      {" "}
                      {homePageWow.discoverSection.progressSteps.first.desc}
                    </p>
                  </div>
                </div>
                <div className="step step-active">
                  <div>
                    <div className="circle">
                      <img src={step2Icon} alt="" />
                    </div>
                  </div>
                  <div className="notFirst">
                    <h3 className="stepTitle">
                      {homePageWow.discoverSection.progressSteps.second.title}
                    </h3>
                    <p className="stepDesc">
                      {" "}
                      "Bring your own phone"{" "}
                      <Link
                        to={"/imei-compatibility-check"}
                        className="checkLink"
                        onClick={() => {
                          pushTrackingEventsToGAAndFB({
                            category: "Plans",
                            actionType: "Check Compatibility",
                          });
                        }}
                      >
                        (check compatibility)
                      </Link>{" "}
                      or shop the latest devices.
                    </p>
                  </div>
                </div>
                <div className="step">
                  <div>
                    <div className="circle">
                      <img src={step3Icon} alt="" />
                    </div>
                  </div>
                  <div className="notFirst">
                    <h3 className="stepTitle">
                      {homePageWow.discoverSection.progressSteps.third.title}
                    </h3>
                    <p className="stepDesc">
                      {" "}
                      {homePageWow.discoverSection.progressSteps.third.desc}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="col-12 col-md-6 ">
        <div className="discoverImage">
          <img
            className="web"
            src={discoverImage}
            alt="discover-section"
            height={553}
          />
          <img
            className="iPadPro "
            src={discoverImageiPad}
            alt="discover-section"
            height={553}
          />
          <img
            className="iPad"
            src={discoverImageiPad}
            alt="discover-section"
            height={400}
          />
          <img
            className="mobile"
            src={discoverMobileImage}
            alt="discover-section"
          />
        </div>
      </div>
    </div>
  );
};

export default DiscoverSectionWow;
