import axios from "axios";
// import serverAddress from '../config';
// import firebase from '../utils/firebase';

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_ADDRESS,
  timeout: 30000,

  // transformRequest: [function (data, headers) {
  //     firebase.auth().onAuthStateChanged(user => {
  //         if (user) {
  //             user.getIdToken()
  //                 .then(function(idToken) {
  //                     localStorage.setItem('accessToken', idToken);

  //                     headers.common['authorization'] = `Bearer ${idToken}`
  //                     headers.common['Content-Type'] = `application/json`
  //                 })
  //                 .catch(function(error) {
  //                     console.log(error);
  //                 });
  //         }
  //     });
  //     return data;
  //   }],
});

export { instance as default };
