import Modal from "../../Modal/Modal";
import CrossIconwithBg from "../../../assets/images/Home/crossIconWithBg.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { CircularProgress, Popover, Typography } from "@mui/material";
import ButtonNew from "../../Button/Button";
import { Link } from "react-router-dom";
import "./WowDiscount.scss";
import { useEffect } from "react";
import { pushTrackingEventsToGAAndFB } from "../../../global/commonFunctions/GaAndFb";

type Props = {
  isShowModal: boolean;
  onModalClose: any;
  wowEligibilityCheckCounter: number;
  tryAgainHandler: any;
  continueHandler: any;
  handleModalMenu: any;
  open: boolean;
  popoverId: any;
  anchorEl: any;
  handleCloseModelMenu: any;
  isPostActive?: any;
  wowCustomerData?: any;
  isCheckEligibilityClicked?: boolean;
  hideContinue?: boolean;
};

const WowDiscountUnsuccess = (props: Props) => {
  const {
    isShowModal,
    onModalClose,
    wowEligibilityCheckCounter,
    tryAgainHandler,
    continueHandler,
    handleModalMenu,
    open,
    popoverId,
    anchorEl,
    handleCloseModelMenu,
    isPostActive,
    wowCustomerData,
    isCheckEligibilityClicked,
  } = props;
  const popOverStyle = { p: 1, fontSize: "14px", color: "#9c9c9c" };

  useEffect(() => {
    pushTrackingEventsToGAAndFB({
      category: "EligibilityCheckPopUp",
      actionType: "CheckEligibilityFailed",
    });
  }, []);
  return (
    <>
      <Modal
        isShowModal={isShowModal}
        onCloseModal={onModalClose}
        showClose={true}
        className="compatibilityPlanBring1"
      >
        <div className="p-3 text-center">
          <h5 className="SorryMsg">Sorry we couldn't find your account.</h5>
          <div className="HomePageLine mx-auto mt-2 mb-3"></div>
          <img src={CrossIconwithBg} alt="success_tick_mark" width={30} />
          {wowEligibilityCheckCounter && wowEligibilityCheckCounter < 3 ? (
            <>
              <h6 className="text-secondary mt-3 mb-3">
                If you believe this is an error, please contact WOW! customer
                support at 1-866-496-9669.
              </h6>
              <span className="my-3">
                <ButtonNew
                  primary={true}
                  className={"eligibility-check-btn"}
                  onClick={tryAgainHandler}
                >
                  {`Try Again (${wowEligibilityCheckCounter}/3)`}
                </ButtonNew>
              </span>
              {isPostActive ? null : (
                <>
                {
                  !props.hideContinue && (
                    <div className="mt-3">
                  <Link
                    to={"/checkout"}
                    state={{
                      wowCustomerData: wowCustomerData,
                    }}
                    style={{
                      color: "var(--primary_color)",
                      textDecoration: "none",
                    }}
                  >
                    <span onClick={continueHandler}>Continue</span>
                  </Link>
                </div>
                  )
                }
                
                </>
              ) }
            </>
          ) : (
            <>
              <h6 className="text-secondary my-3">
                The information provided does not match an active residential
                WOW! Internet account.
              </h6>
              {isPostActive ? (
                <span className="my-4">
                  <ButtonNew
                    primary={true}
                    className={"eligibility-check-btn"}
                    onClick={continueHandler}
                  >
                    {isCheckEligibilityClicked ? (
                      <CircularProgress
                        className="loaderCheck"
                        color="secondary"
                      />
                    ) : (
                      "Continue"
                    )}
                  </ButtonNew>{" "}
                </span>
              ) : (
                <> { !props.hideContinue && (
                  <span className="my-4">
                  <Link
                    to={"/checkout"}
                    state={{
                      wowCustomerData: wowCustomerData,
                    }}
                    style={{
                      color: "var(--white)",
                      textDecoration: "none",
                    }}
                  >
                    <ButtonNew
                      primary={true}
                      className={"eligibility-check-btn"}
                      onClick={continueHandler}
                    >
                      Continue
                    </ButtonNew>
                  </Link>
                  </span>
                )}
                </>
              )}
              <div className="my-3">
                <span
                  style={{ color: "#0072ab" }}
                  className="fw-bold"
                >{`Limit exceeded ${wowEligibilityCheckCounter}/3`}</span>
              </div>
              <small className="form-text text-muted">
                If you believe this is an error, please review your<br></br>
                <span style={{ color: "var(--primary_color)" }}>
                  WOW! account information here
                  <span className="arrowMenuToggle" onClick={handleModalMenu}>
                    {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </span>
                </span>
              </small>
              <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseModelMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{ minWidth: 560 }}
              >
                <Typography sx={popOverStyle}>
                  1. Your WOW! billing statement
                </Typography>
                <Typography sx={popOverStyle}>
                  2. The WOW! online account management website
                </Typography>
                <Typography sx={popOverStyle}>
                  3. The WOW! order confirmation email or
                </Typography>
                <Typography sx={popOverStyle}>
                  4. The WOW! online account management
                </Typography>
              </Popover>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default WowDiscountUnsuccess;
