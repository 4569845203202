import apiJunction from "../utils/api";
import { domainMap, domainName, version } from "../config/config";
import {
  checkEmailAvailabilityFailure,
  checkEmailAvailabilitySuccess,
  CouponFailure,
  CouponReserveFailure,
  CouponReserveSuccess,
  CouponSuccess,
  CreateCustomerFailure,
  CreateCustomerSuccess,
  EligibilityFailure,
  EligibilitySuccess,
  GetCustomerFailure,
  GetCustomerSuccess,
  PaymentGetFailure,
  PaymentGetSuccess,
  SetTaxCalculationByAddressType2,
  StateCityByZipcodeFailure,
  StateCityByZipcodeSuccess,
  TaxCalculationByAddressType1Failure,
  TaxCalculationByAddressType1Success,
  TaxCalculationByAddressType2Failure,
  TaxCalculationByAddressType2Success,
  UpdateCustomerFailure,
  UpdateCustomerSourceFailure,
  UpdateCustomerSourceSuccess,
  UpdateCustomerSuccess,
  UpdateSecondaryCustomerFailure,
  UpdateSecondaryCustomerSuccess,
  LoginNumberSuccess,
  LoginNumberFailure,
  ShippingSuccess,
  ShippingFailure,
  GetShipmentOrderByIdSuccess,
  GetShipmentOrderByIdFailure,
  HsdAddSuccess,
  HsdAddFailure,
} from "../redux/Checkout/actions";
import { Store } from "react-notifications-component";
import { NotificationComponent } from "../components/NotificationComponent/NotificationComponent";

export const getCustomerApi = (payload: any) => {
  // get customer by username

  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "get",
        url:
          domainName !== domainMap.T4
            ? `/custsvc/${version}/customers/username?id=${payload.username}`
            : `/custsvc/${version}/customers/username?id=${payload.username}`,
      });

      if (res.status === 200) {
        domainName !== domainMap.T4
          ? localStorage.setItem(
              "earthlinkCustomerDetail",
              JSON.stringify(res.data.data)
            )
          : localStorage.setItem(
              "customerDetail",
              JSON.stringify(res.data.data)
            );
        dispatch(
          GetCustomerSuccess({
            getCustomer: res.data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        GetCustomerFailure({
          errorGetCustomer: err.response && err.response.data,
        })
      );
    }
  };
};

// Update customer source

export const updateCustomerSourceApi = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "put",
        url: `/custsvc/${version}/update/fields/customer/${payload.customerId}`,
        body: payload.data,
      });

      if (res.status === 200) {
        domainName !== domainMap.T4
          ? localStorage.setItem(
              "earthlinkCustomerDetail",
              JSON.stringify(res.data.data)
            )
          : localStorage.setItem(
              "customerDetail",
              JSON.stringify(res.data.data)
            );
        dispatch(
          UpdateCustomerSourceSuccess({
            updateCustomerSource: res.data,
          })
        );
      }
      if (res && res.data && res.data.data) {
        domainName === domainMap.T2
          ? localStorage.setItem(
              "earthlinkCustomerDetail",
              JSON.stringify(res.data.data)
            )
          : res.data.data.isPrimary
          ? localStorage.setItem(
              "customerDetail",
              JSON.stringify(res.data.data)
            )
          : localStorage.setItem(
              "secondaryCustomerDetail",
              JSON.stringify(res.data.data)
            );
      }
    } catch (err: any) {
      dispatch(
        UpdateCustomerSourceFailure({
          errorUpdateCustomerSource: err.response && err.response.data,
        })
      );
    }
  };
};

//payment get api
export const paymentGetApi = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "get",
        url: `${process.env.REACT_APP_PAYMENT_ADDRESS}/account/redirect/page/${payload.id}`,
        auth: true,
      });

      if (res.status === 200) {
        dispatch(
          PaymentGetSuccess({
            paymentGet: res.data,
          })
        );
      }
    } catch (err: any) {
      PaymentGetFailure({
        errorPaymentGet: err.response && err.response.data,
      });
    }
  };
};

export const eligibilityApi = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "get",
        url: `/catalogsvc/${version}/coupon/eligibility?emailId=${payload.emailId}`,
      });

      if (res.status === 200) {
        dispatch(
          EligibilitySuccess({
            eligibility: res.data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        EligibilityFailure({
          errorEligibility: err.response && err.response.data,
        })
      );
    }
  };
};

export const Coupon = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "get",
        url: `/catalogsvc/${version}/coupon/${payload.couponCode}`,
      });

      if (res.status === 200) {
        dispatch(
          CouponSuccess({
            coupon: res.data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        CouponFailure({
          errorCoupon: err.response && err.response.data,
        })
      );
    }
  };
};

export const CreateCustomer = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "post",
        url: `/custsvc/${version}/${payload.countryCode}/customers`,
        body: payload.data,
      });

      if (res.status === 200) {
        dispatch(
          CreateCustomerSuccess({
            createCustomer: res.data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        CreateCustomerFailure({
          errorCreateCustomer: err.response
            ? err.response.data
            : { status: "FAILURE", message: "Some error occurred!" },
        })
      );
      NotificationComponent({
        message: err.response && err.response.data && err.response.message,
        duration: 3000,
        type: "danger",
      });
    }
  };
};

// Get shipment order By ID

export const GetShipmentOrderById = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "get",
        url: `/shipsvc/${version}/shipment/order/customer/${payload.id}`,
      });

      if (res.status === 200) {
        dispatch(
          GetShipmentOrderByIdSuccess({
            getShipmentOrderById: res.data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        GetShipmentOrderByIdFailure({
          errorGetShipmentOrderById: err.response && err.response.data,
        })
      );
    }
  };
};

// Shipping

export const ShippingData = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "post",
        url: `/shipsvc/${version}/shipment/order/`,
        body: payload.data,
      });

      if (res.status === 200) {
        dispatch(
          ShippingSuccess({
            shippingData: res.data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        ShippingFailure({
          errorShippingData: err.response
            ? err.response.data
            : { status: "FAILURE", message: "Some error occurred!" },
        })
      );
    }
  };
};

export const UpdateCustomer = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "put",
        url: `/custsvc/${version}/customers/${payload.customerId}`,
        body: payload.data,
      });

      if (res.status === 200) {
        dispatch(
          UpdateCustomerSuccess({
            updateCustomer: res.data,
          })
        );
      }
      if (res && res.data && res.data.data) {
        domainName === domainMap.T2
          ? localStorage.setItem(
              "earthlinkCustomerDetail",
              JSON.stringify(res.data.data)
            )
          : localStorage.setItem(
              "customerDetail",
              JSON.stringify(res.data.data)
            );
      }
    } catch (err: any) {
      dispatch(
        UpdateCustomerFailure({
          errorUpdateCustomer: err.response && err.response.data,
        })
      );
    }
  };
};

export const TaxCalculationByAddressType1 = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "post",
        url: `/taxsvc/${version}/taxes/${payload.countryCode}`,
        body: payload.data,
      });

      if (res.status === 200) {
        dispatch(
          TaxCalculationByAddressType1Success({
            taxCalculationByAddressType1: res.data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        TaxCalculationByAddressType1Failure({
          errorTaxCalculationByAddressType1: err.response && err.response.data,
        })
      );
    }
  };
};

export const StateCityByZipcode = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "get",
        url: `/provsvc/${version}/mdn/zipcode/availability/${payload.zipcode}`,
      });

      if (res.status === 200) {
        dispatch(
          StateCityByZipcodeSuccess({
            stateCityByZipcode: res.data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        StateCityByZipcodeFailure({
          errorStateCityByZipcode: err.response && err.response.data,
        })
      );
      NotificationComponent({
        message: "Please enter a valid zipcode",
        duration: 3000,
        type: "danger",
      });
    }
  };
};

export const TaxCalculationByAddressType2 = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(SetTaxCalculationByAddressType2({}));
    try {
      let res = await apiJunction.makeRequest({
        method: "post",
        url: `/taxsvc/${version}/taxes/${payload.countryCode}`,
        body: payload.data,
      });

      if (res.status === 200) {
        dispatch(
          TaxCalculationByAddressType2Success({
            taxCalculationByAddressType2: res.data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        TaxCalculationByAddressType2Failure({
          errorTaxCalculationByAddressType2: err.response && err.response.data,
        })
      );
    }
  };
};

export const UpdateCustomerSource = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "put",
        url: `/custsvc/${version}/update/fields/customer/${payload.customerId}`,
        body: payload.data,
      });

      if (res.status === 200) {
        dispatch(
          UpdateCustomerSourceSuccess({
            updateCustomerSource: res.data,
          })
        );
      }
      if (res && res.data && res.data.data) {
        domainName === domainMap.T2
          ? localStorage.setItem(
              "earthlinkCustomerDetail",
              JSON.stringify(res.data.data)
            )
          : res.data.data.isPrimary
          ? localStorage.setItem(
              "customerDetail",
              JSON.stringify(res.data.data)
            )
          : localStorage.setItem(
              "secondaryCustomerDetail",
              JSON.stringify(res.data.data)
            );
      }
    } catch (err: any) {
      dispatch(
        UpdateCustomerSourceFailure({
          errorUpdateCustomerSource: err.response && err.response.data,
        })
      );
    }
  };
};

export const CouponReserve = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "post",
        url: `/catalogsvc/${version}/coupon/reserve/promo`,
        body: payload,
      });

      if (res.status === 200) {
        dispatch(
          CouponReserveSuccess({
            couponReserve: res.data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        CouponReserveFailure({
          errorCouponReserve: err.response && err.response.data,
        })
      );
    }
  };
};

// checkEmailAvailability

export const checkEmailAvailabilityApi = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "get",
        url: `/custsvc/${version}/customers/availability/${payload.emailId}`,
      });

      if (res.status === 200) {
        dispatch(
          checkEmailAvailabilitySuccess({
            checkEmailAvailability: res.data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        checkEmailAvailabilityFailure({
          errorCheckEmailAvailability: err.response && err.response.data,
        })
      );
    }
  };
};

// Update secondary customer email

export const updateSecondaryCustomerApi = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "put",
        url: `/custsvc/${version}/customers/secondary/update`,
        body: payload,
      });

      if (res.status === 200) {
        dispatch(
          UpdateSecondaryCustomerSuccess({
            updateSecondaryCustomer: res.data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        UpdateSecondaryCustomerFailure({
          errorUpdateSecondaryCustomer: err.response && err.response.data,
        })
      );
    }
  };
};

export const loginWithNumber = (payload: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "get",
        url: `/custsvc/${version}/customers/reachnumber/${payload.loginNumber}`,
      });

      if (res.status === 200) {
        dispatch(
          LoginNumberSuccess({
            loginNumberSuccess: res.data,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        LoginNumberFailure({
          errorLoginNumber: err.response && err.response.data,
        })
      );
    }
  };
};

export const hsdAddApi = (action: any) => {
  return async (dispatch: any) => {
    try {
      let res = await apiJunction.makeRequest({
        method: "post",
        url: `/wowutilssvc/v0/hsd/discount`,
        body: action.data,
        contentType: "application/json",
      });

      if (res.status === 200) {
        dispatch(HsdAddSuccess({ hsdAdd: res.data }));
      }
    } catch (err: any) {
      dispatch(
        HsdAddFailure({
          hsdAddError: {
            data: err.response && err.response.data,
            status: err.response && err.response.status,
          },
        })
      );
    }
  };
};

export const CheckoutService = {
  getCustomerApi,
  updateCustomerSourceApi,
  paymentGetApi,
  eligibilityApi,
  Coupon,
  CreateCustomer,
  UpdateCustomer,
  TaxCalculationByAddressType1,
  StateCityByZipcode,
  TaxCalculationByAddressType2,
  UpdateCustomerSource,
  CouponReserve,
  checkEmailAvailabilityApi,
  updateSecondaryCustomerApi,
  loginWithNumber,
  ShippingData,
  hsdAddApi,
  GetShipmentOrderById,
};
