import './tab.scss';
import One from '../../assets/images/PlanPage/One.svg';
import Two from '../../assets/images/PlanPage/Two.svg';
import Three from '../../assets/images/PlanPage/Three.svg';
import Four from '../../assets/images/PlanPage/Four.png';
import Five from '../../assets/images/PlanPage/Five.png';
import Six from '../../assets/images/PlanPage/Six.png';
import { altText } from '../../global/altText';
type initialProps = {
    value: any;
    handleChange: any;
    totalCount: number;
};

const LineTab = (props: initialProps) => {
    return (
        <div className="TabComponent">
            {props.totalCount >= 1 && (
                <div className="TabDiv" onClick={() => props.handleChange(1)}>
                     <img
                            src={One}
                            className="tabImg"
                            alt={altText.planPage.line1}
                            style={{ display: props.value !== 1 ? 'none' : '' }}
                    />
                    <div className={props.value === 1 ? 'tabDetailActive' : 'tabDetail'}>1</div>
                </div>
            )}
            {props.totalCount >= 2 && (
                <div className="TabDiv" onClick={() => props.handleChange(2)}>
                     <img
                            src={Two}
                            className="tabImg"
                            alt={altText.planPage.line1}
                            style={{ display: props.value !== 2 ? 'none' : '' }}
                    />
                    <div className={props.value === 2 ? 'tabDetailActive' : 'tabDetail'}>2</div>
                </div>
            )}
            {props.totalCount >= 3 && (
                <div className="TabDiv" onClick={() => props.handleChange(3)}>
                     <img
                            src={Three}
                            className="tabImg"
                            alt={altText.planPage.line1}
                            style={{ display: props.value !== 3 ? 'none' : '' }}
                    />
                    <div className={props.value === 3 ? 'tabDetailActive' : 'tabDetail'}>3</div>
                </div>
            )}
            {props.totalCount >= 4 && (
                <div className="TabDiv" onClick={() => props.handleChange(4)}>
                     <img
                            src={Four}
                            className="tabImg"
                            alt={altText.planPage.line1}
                            style={{ display: props.value !== 4 ? 'none' : '' }}
                    />
                    <div className={props.value === 4 ? 'tabDetailActive' : 'tabDetail'}>4</div>
                </div>
            )}
            {props.totalCount >= 5 && (
                <div className="TabDiv" onClick={() => props.handleChange(5)}>
                     <img
                            src={Five}
                            className="tabImg"
                            alt={altText.planPage.line1}
                            style={{ display: props.value !== 5 ? 'none' : '' }}
                    />
                    <div className={props.value === 5 ? 'tabDetailActive' : 'tabDetail'}>5</div>
                </div>
            )}
            {props.totalCount >= 6 && (
                <div className="TabDiv">
                     <img
                            src={Six}
                            className="tabImg"
                            alt={altText.planPage.line1}
                            style={{ display: props.value !== 6 ? 'none' : '' }}
                    />
                    <div
                        className={props.value === 6 ? 'tabDetailActive' : 'tabDetail'}
                        onClick={() => props.handleChange(6)}
                    > 6 </div>
                </div>
            )}
        </div>
    );

}
export default LineTab;
