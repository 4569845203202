import React from "react";
import { globalVal } from "../../global/globalvalues";
import ButtonNew from "../Button/Button";
import "./HomeInternetSection.scss";
const HomeInternetSection = () => {
  return (
    <div className="homeInternetOuter">
      <div className="row">
        <div className="col-lg-8 col-md-7">
          <div className="homeInternetHeading">
            Get fast and reliable Internet with WOW! mobile
          </div>
          <div className="homeInternetSubHeading">
            WOW! mobile customers enjoy exclusive savings on WOW! Internet.
            Visit wowway.com
          </div>
        </div>
        <div className="col-lg-4 col-md-5">
          <div className="explore-plan-btn">
            <ButtonNew
              primary={true}
              sx={{
                border: "1px solid var(--white) !important",
                fontFamily: "var(--font_family_Semibold) !important",
                fontSize: "16px",
                fontWeight: "var(--font_weight_2)",
              }}
              className="InfoCardDetailBtn1"
              onClick={() => window.open(`${globalVal.homeInternet}`, "_blank")}
            >
              Explore Plans
            </ButtonNew>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeInternetSection;
