import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { scroller } from "react-scroll";
import { pushTrackingEventsToGAAndFB } from "../../global/commonFunctions/GaAndFb";
import homePageBanner from "../../assets/images/Home/wow-homepage-banner.png";
import homebannermobile from "../../assets/images/Home/wow-home-mobile.png";
import homebannertablet from "../../assets/images/Home/wow-home-tab.png";
import homebannertablet2 from "../../assets/images/Home/wow-home-tab2.png";
import { altText } from "../../global/altText";
import { homePageWow } from "../../global/texts/Homepage";
import Button from "../../components/Button/Button";
import "./HomePageBannerWow.scss";

type Props = {};

const HomePageBannerWow: React.FC<Props> = (props) => {
  const [isLoader, setIsLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false);
    }, 2000);
    window.scroll(0, 0);
  }, []);

  const getStarted = (e: any) => {
    pushTrackingEventsToGAAndFB(
      {
        category: "Home",
        actionType: "View Plans",
      },
      e
    );
    scroller.scrollTo("bestCellPhonePlan", {
      duration: 1000,
      delay: 0,
      // smooth: 'easeInOutQuart',
      // smooh: 'easeOutQuart',
      offset: -100,
    });
  };

  return (
    <div className="OverlayPlan">
      <div className="carouseImage">
        <img
          className="web"
          width="100%"
          src={homePageBanner}
          alt={altText.planPage.mainImage}
        />
        <img
          className="iPadPro"
          width="100%"
          src={homebannertablet2}
          alt={altText.planPage.mainImage}
        />
        <img
          className="iPad"
          width="100%"
          src={homebannertablet}
          alt={altText.planPage.mainImage}
        />
        <img
          className="mobile"
          width="100%"
          src={homebannermobile}
          alt={altText.planPage.mainImage}
        />

        <div className="contentCarousel">
          <h1 className="Goodbye-mobile-store">
            {/* {isLoader ? (
              <Skeleton className="skeletonRadius" />
            ) : ( */}
            {homePageWow.overlayImage.heading} {/* )} */}
          </h1>
          <div className="ticksMainDiv">
            {homePageWow.overlayImage.subheading}
          </div>
          <div className="getStartedBtn">
            {/* {isLoader ? (
              <Skeleton className="skeletonRadius skeletonLoaderBtn" />
            ) : ( */}
            <Button
              className="explore-plan-btn"
              primary={true}
              onClick={getStarted}
            >
              Explore Plans
            </Button>
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageBannerWow;
