export const ACTIVE_PLANS = 'ACTIVE_PLANS';
export const ACTIVE_PLANS_SUCCESS = 'ACTIVE_PLANS_SUCCESS';
export const ACTIVE_PLANS_FAILURE = 'ACTIVE_PLANS_FAILURE';

export const COMPATIBILITY = 'COMPATIBILITY';
export const COMPATIBILITY_SUCCESS = 'COMPATIBILITY_SUCCESS';
export const COMPATIBILITY_FAILURE = 'COMPATIBILITY_FAILURE';

export const RESERVE_PROMO_COUPON = 'RESERVE_PROMO_COUPON';
export const RESERVE_PROMO_COUPON_SUCCESS = 'RESERVE_PROMO_COUPON_SUCCESS';
export const RESERVE_PROMO_COUPON_FAILURE = 'RESERVE_PROMO_COUPON_FAILURE';
export const GET_ALL_PLANS = 'GET_ALL_PLANS';
export const GET_ALL_PLANS_SUCCESS = 'GET_ALL_PLANS_SUCCESS';
export const GET_ALL_PLANS_FAILURE = 'GET_ALL_PLANS_FAILURE';