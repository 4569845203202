import {
  REACT_PLANS,
  REACT_PLANS_FAILURE,
  REACT_PLANS_SUCCESS,
  COVERAGE_PLANS,
  COVERAGE_PLANS_FAILURE,
  COVERAGE_PLANS_SUCCESS,
  GET_IN_TOUCH,
  GET_IN_TOUCH_FAILURE,
  GET_IN_TOUCH_SUCCESS,
  EARTHLINK_REACH_PLAN,
  EARTHLINK_REACH_PLAN_SUCCESS,
  EARTHLINK_REACH_PLAN_FAILURE,
  CHECK_COVERAGE_SUCCESS,
  CHECK_COVERAGE_FAILURE,
  CHECK_COVERAGE,
  HIDE_LOADER,
  INVITE_REFEREE_COUPON,
  INVITE_REFEREE_COUPON_SUCCESS,
  INVITE_REFEREE_COUPON_FAILURE,
  RESET_GET_IN_TOUCH,
  WOW_ELIGIBILITY_SUCCESS,
  WOW_ELIGIBILITY_FAILURE,
  DISCOUNT_APPLIED_SUCCESS,
  DISCOUNT_APPLIED_FAILURE,
} from "./actionTypes";

import { HomeActions, HomeState } from "./types";

const initialState: HomeState = {
  plans: [],
  error: null,
  coveragePlans: [],
  coverageError: null,
  getInTouch: [],
  getInTouchError: null,
  plansEarthlink: [],
  errorPlansEarthlink: null,
  coverageLoading: false,
  coverageStatus: {},
  errorCoverageStatus: null,
  inviteRefereeStatus: null,
  errorInviteRefereeStatus: null,
  wowEligibility: {},
  wowEligibilityError: {},
  discountApplied: {},
  discountAppliedError: null,
};
export default (state = initialState, action: HomeActions) => {
  switch (action.type) {
    case REACT_PLANS:
      return {
        ...state,
      };
    case REACT_PLANS_SUCCESS:
      return {
        ...state,
        plans: action.payload.plans,
        error: null,
      };
    case REACT_PLANS_FAILURE:
      return {
        ...state,
        plans: [],
        error: action.payload.error,
      };

    case COVERAGE_PLANS:
      return {
        ...state,
      };
    case COVERAGE_PLANS_SUCCESS:
      return {
        ...state,
        coveragePlans: action.payload.coveragePlans,
        coverageError: null,
      };
    case COVERAGE_PLANS_FAILURE:
      return {
        ...state,
        coveragePlans: [],
        coverageError: action.payload.coverageError,
      };

    case RESET_GET_IN_TOUCH:
      return {
        ...state,
        getInTouch: [],
      };
    case GET_IN_TOUCH:
      return {
        ...state,
      };
    case GET_IN_TOUCH_SUCCESS:
      return {
        ...state,
        getInTouch: action.payload.getInTouch,
        getInTouchError: null,
      };
    case GET_IN_TOUCH_FAILURE:
      return {
        ...state,
        getInTouch: [],
        getInTouchError: action.payload.getInTouchError,
      };
    case EARTHLINK_REACH_PLAN:
      return {
        ...state,
      };
    case EARTHLINK_REACH_PLAN_SUCCESS:
      return {
        ...state,
        plansEarthlink: action.payload.plansEarthlink,
        errorPlansEarthlink: null,
      };
    case EARTHLINK_REACH_PLAN_FAILURE:
      return {
        ...state,
        billCustomize: [],
        errorPlansEarthlink: action.payload.errorPlansEarthlink,
      };
    case CHECK_COVERAGE:
      return {
        ...state,
        coverageLoading: true,
        coverageStatus: null,
        errorCoverageStatus: null,
      };
    case HIDE_LOADER:
      return {
        ...state,
        coverageLoading: false,
      };
    case CHECK_COVERAGE_SUCCESS:
      return {
        ...state,
        coverageStatus: action.payload.coverageStatus,
        errorCoverageStatus: null,
        coverageLoading: false,
      };
    case CHECK_COVERAGE_FAILURE:
      return {
        ...state,
        errorCoverageStatus: action.payload.errorCoverageStatus,
        coverageLoading: false,
      };
    case INVITE_REFEREE_COUPON:
      return {
        ...state,
        inviteRefereeStatus: null,
        errorInviteRefereeStatus: null,
      };
    case INVITE_REFEREE_COUPON_SUCCESS:
      return {
        ...state,
        inviteRefereeStatus: action.payload.inviteRefereeStatus,
        errorInviteRefereeStatus: null,
      };
    case INVITE_REFEREE_COUPON_FAILURE:
      return {
        ...state,
        errorInviteRefereeStatus: action.payload.errorInviteRefereeStatus,
      };

    case WOW_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        wowEligibility: action.payload.wowEligibility,
      };

    case WOW_ELIGIBILITY_FAILURE:
      return {
        ...state,
        wowEligibilityError: action.payload.wowEligibilityError,
      };

    case DISCOUNT_APPLIED_SUCCESS:
      return {
        ...state,
        discountApplied: action.payload.discountApplied,
        discountAppliedError: null,
      };

    case DISCOUNT_APPLIED_FAILURE:
      return {
        ...state,
        discountAppliedError: action.payload.discountAppliedError,
        discountApplied: null,
      };

    default:
      return {
        ...state,
      };
  }
};
