//get bill
export const GET_BILL = 'GET_BILL';
export const GET_BILL_SUCCESS = 'GET_BILL_SUCCESS';
export const GET_BILL_FAILURE = 'GET_BILL_FAILURE';

//bill customize
export const BILL_CUSTOMIZE = 'BILL_CUSTOMIZE';
export const BILL_CUSTOMIZE_SUCCESS = 'BILL_CUSTOMIZE_SUCCESS';
export const BILL_CUSTOMIZE_FAILURE = 'BILL_CUSTOMIZE_FAILURE';

//order history
export const ORDER_HISTORY = 'ORDER_HISTORY';
export const ORDER_HISTORY_SUCCESS = 'ORDER_HISTORY_SUCCESS';
export const ORDER_HISTORY_FAILURE = 'ORDER_HISTORY_FAILURE';

//get user plans
export const USER_PLANS = 'USER_PLANS';
export const USER_PLANS_SUCCESS = 'USER_PLANS_SUCCESS';
export const USER_PLANS_FAILURE = 'USER_PLANS_FAILURE';

//bill forecast
export const BILL_FORECAST = 'BILL_FORECAST';
export const BILL_FORECAST_SUCCESS = 'BILL_FORECAST_SUCCESS';
export const BILL_FORECAST_FAILURE = 'BILL_FORECAST_FAILURE';

// eligible for pay later
export const ELIGIBLE_PAY_LATER = 'ELIGIBLE_PAY_LATER';
export const ELIGIBLE_PAY_LATER_SUCCESS = 'ELIGIBLE_PAY_LATER_SUCCESS';
export const ELIGIBLE_PAY_LATER_FAILURE = 'ELIGIBLE_PAY_LATER_FAILURE';

// bill pay
export const BILL_PAY = 'BILL_PAY';
export const BILL_PAY_SUCCESS = 'BILL_PAY_SUCCESS';
export const BILL_PAY_FAILURE = 'BILL_PAY_FAILURE';

// email bill
export const EMAIL_BILL = 'EMAIL_BILL';
export const EMAIL_BILL_SUCCESS = 'EMAIL_BILL_SUCCESS';
export const EMAIL_BILL_FAILURE = 'EMAIL_BILL_FAILURE';

// email bill
export const PROMISE_PAY = 'PROMISE_PAY';
export const PROMISE_PAY_SUCCESS = 'PROMISE_PAY_SUCCESS';
export const PROMISE_PAY_FAILURE = 'PROMISE_PAY_FAILURE';

// get advance
export const GET_ADVANCE = 'GET_ADVANCE';
export const GET_ADVANCE_SUCCESS = 'GET_ADVANCE_SUCCESS';
export const GET_ADVANCE_FAILURE = 'GET_ADVANCE_FAILURE';

// data usage
export const DATA_USAGE = 'DATA_USAGE';
export const DATA_USAGE_SUCCESS = 'DATA_USAGE_SUCCESS';
export const DATA_USAGE_FAILURE = 'DATA_USAGE_FAILURE';

// pay advance
export const PAY_ADVANCE = 'PAY_ADVANCE';
export const PAY_ADVANCE_SUCCESS = 'PAY_ADVANCE_SUCCESS';
export const PAY_ADVANCE_FAILURE = 'PAY_ADVANCE_FAILURE';

// get config api
export const CONFIG = 'CONFIG';
export const CONFIG_SUCCESS = 'CONFIG_SUCCESS';
export const CONFIG_FAILURE = 'CONFIG_FAILURE';

// reference code and detail
export const REFERAL_DETAIL = 'REFERAL_DETAIL';
export const REFERAL_DETAIL_SUCCESS = 'REFERAL_DETAIL_SUCCESS';
export const REFERAL_DETAIL_FAILURE = 'REFERAL_DETAIL_FAILURE';
