import React from "react";
import "./TextField.scss";
import TextField from "@mui/material/TextField";
import { domainName, domainMap } from "../../config/config";

type initialProps = {
  value: any;
  onChange: any;
  label?: string;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  helperText?: string;
  type?: string;
  maxlength?: number;
  minLength?: number;
  onBlur?: any;
  className?: string;
  multiline?: boolean;
  rows?: number;
  maxRows?: number;
  readOnly?: boolean;
  endAdornment?: any;
  name?: any;
  autoComplete?: string;
  id?: any;
};

const TextFieldComponent: React.FC<initialProps> = (props) => {
  return (
    <TextField
      onBlur={props.onBlur}
      className={
        domainName === domainMap.T2
          ? `TextFieldEarthlink ${props.className ? props.className : ""}`
          : `TextField ${props.className ? props.className : ""}`
      }
      label={props.label}
      type={props.type}
      placeholder={props.placeholder}
      {...(props.id && { id: props.id })}
      onChange={props.onChange}
      variant="filled"
      value={props.value}
      error={props.error}
      helperText={props.helperText}
      disabled={props.disabled}
      inputProps={{
        autoComplete: "new-password",
        form: {
          autoComplete: "off",
        },
        maxLength: props.maxlength,
        minLength: props.minLength,
        readOnly: props.readOnly,
      }}
      InputProps={{ endAdornment: props.endAdornment }}
      multiline={props.multiline}
      rows={props.rows}
      maxRows={props.maxRows}
      name={props.name}
      autoComplete="new-password"
    />
  );
};

export default TextFieldComponent;
