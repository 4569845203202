import {
  REACT_PLANS,
  REACT_PLANS_FAILURE,
  REACT_PLANS_SUCCESS,
  COVERAGE_PLANS,
  COVERAGE_PLANS_FAILURE,
  COVERAGE_PLANS_SUCCESS,
  GET_IN_TOUCH,
  GET_IN_TOUCH_FAILURE,
  GET_IN_TOUCH_SUCCESS,
  EARTHLINK_REACH_PLAN,
  EARTHLINK_REACH_PLAN_SUCCESS,
  EARTHLINK_REACH_PLAN_FAILURE,
  CHECK_COVERAGE,
  CHECK_COVERAGE_SUCCESS,
  CHECK_COVERAGE_FAILURE,
  INVITE_REFEREE_COUPON,
  INVITE_REFEREE_COUPON_SUCCESS,
  INVITE_REFEREE_COUPON_FAILURE,
  HIDE_LOADER,
  RESET_GET_IN_TOUCH,
  WOW_ELIGIBILITY_SUCCESS,
  WOW_ELIGIBILITY_FAILURE,
  DISCOUNT_APPLIED_SUCCESS,
  DISCOUNT_APPLIED_FAILURE,
} from "./actionTypes";
import * as types from "./types";

// react plan
export const reachPlans = (): types.ReachPlanRequest => ({
  type: REACT_PLANS,
});

export const reachPlansSuccess = (
  payload: types.ReachPlanSuccessPayload
): types.ReachPlanSuccess => ({
  type: REACT_PLANS_SUCCESS,
  payload,
});

export const reachPlansFailure = (
  payload: types.ReachPlanFailurePayload
): types.ReachPlanFailure => ({
  type: REACT_PLANS_FAILURE,
  payload,
});

// coverage plan
export const coveragePlans = (
  payload: types.CoveragePlanRequestPayload
): types.CoveragePlanRequest => ({
  type: COVERAGE_PLANS,
  payload,
});

export const coveragePlansSuccess = (
  payload: types.CoveragePlanSuccessPayload
): types.CoveragePlanSuccess => ({
  type: COVERAGE_PLANS_SUCCESS,
  payload,
});

export const coveragePlansFailure = (
  payload: types.CoveragePlanFailurePayload
): types.CoveragePlanFailure => ({
  type: COVERAGE_PLANS_FAILURE,
  payload,
});

// get in touch
export const getInTouch = (
  payload: types.GetInTouchRequestPayload
): types.GetInTouchRequest => ({
  type: GET_IN_TOUCH,
  payload,
});

export const getInTouchSuccess = (
  payload: types.GetInTouchSuccessPayload
): types.GetInTouchSuccess => ({
  type: GET_IN_TOUCH_SUCCESS,
  payload,
});

export const getInTouchFailure = (
  payload: types.GetInTouchFailurePayload
): types.GetInTouchFailure => ({
  type: GET_IN_TOUCH_FAILURE,
  payload,
});

// get in touch
export const earthlinkReachPlan = (
  payload: types.EarthlinkReachPlanRequestPayload
): types.EarthlinkReachPlanRequest => ({
  type: EARTHLINK_REACH_PLAN,
  payload,
});

export const earthlinkReachPlanSuccess = (
  payload: types.EarthlinkReachPlanSuccessPayload
): types.EarthlinkReachPlanSuccess => ({
  type: EARTHLINK_REACH_PLAN_SUCCESS,
  payload,
});

export const earthlinkReachPlanFailure = (
  payload: types.EarthlinkReachPlanFailurePayload
): types.EarthlinkReachPlanFailure => ({
  type: EARTHLINK_REACH_PLAN_FAILURE,
  payload,
});

export const checkCoverage = (payload: any) => ({
  type: CHECK_COVERAGE,
  payload,
});

export const HideLoader = (payload: any) => ({
  type: HIDE_LOADER,
  payload,
});

export const resetGetInTouch = (payload: any) => ({
  type: RESET_GET_IN_TOUCH,
  payload,
});

export const checkCoverageSuccess = (payload: any) => ({
  type: CHECK_COVERAGE_SUCCESS,
  payload,
});

export const checkCoverageFailure = (payload: any) => ({
  type: CHECK_COVERAGE_FAILURE,
  payload,
});

export const inviteRefereeCoupon = (payload: any) => ({
  type: INVITE_REFEREE_COUPON,
  payload,
});

export const inviteRefereeCouponSuccess = (payload: any) => ({
  type: INVITE_REFEREE_COUPON_SUCCESS,
  payload,
});

export const inviteRefereeCouponFailure = (payload: any) => ({
  type: INVITE_REFEREE_COUPON_FAILURE,
  payload,
});

export const wowEligibilitySuccess = (payload: any) => ({
  type: WOW_ELIGIBILITY_SUCCESS,
  payload,
});

export const wowEligibilityFailure = (payload: any) => ({
  type: WOW_ELIGIBILITY_FAILURE,
  payload,
});

export const discountAppliedSuccess = (payload: any) => ({
  type: DISCOUNT_APPLIED_SUCCESS,
  payload,
});

export const discountAppliedFailure = (payload: any) => ({
  type: DISCOUNT_APPLIED_FAILURE,
  payload,
});
