import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import PostHeader from "./PostHeader";
import SimpleHeader from "./SimpleHeader";

type Props = {};

const MainHeader = (_props: Props) => {
  const { getCustomer } = useSelector((state: any) => state.checkout);

  const location = useLocation();

  const [isPostActive, setisPostActive] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [myAccount, setmyAccount] = useState(false);
  const [userData, setUserData] = useState<any>("");
  const [custDetail, setCustDetail] = useState<any>("");

  useEffect(() => {
    let customerDetail: any = localStorage.getItem("customerDetail");
    const currentUser: any = localStorage.getItem("currentuser");
    setUserData(JSON.parse(currentUser));
    customerDetail = JSON.parse(customerDetail);
    setCustDetail(customerDetail);
    let isPostActivated: any = localStorage.getItem("isPostActivated");

    if (
      (customerDetail &&
        customerDetail?.simPaid &&
        customerDetail?.status === 2) ||
      (customerDetail && !customerDetail.isPrimary) ||
      (customerDetail &&
        customerDetail?.simPaid &&
        customerDetail?.status === 4) ||
      (customerDetail &&
        customerDetail?.simPaid &&
        customerDetail?.status === 3) ||
      (customerDetail &&
        customerDetail?.simPaid &&
        customerDetail?.status === 5)
    ) {
      setisPostActive(true);
    } else {
      setisPostActive(false);
    }

    if (isPostActivated && isPostActivated === "true") {
      localStorage.removeItem("isPostActivated");
      setisPostActive(true);
    }

    if (
      location.pathname === "/my-account" ||
      location.pathname === "/settings"
    ) {
      setmyAccount(true);
    } else {
      setmyAccount(false);
    }
    if (location.pathname === "/checkout") {
      setisActive(true);
    } else {
      setisActive(false);
    }
  }, [location]);

  useEffect(() => {
    if (getCustomer && getCustomer?.simPaid && getCustomer?.status === 2) {
      setisPostActive(true);
    }
    if (location.pathname === "/my-account") {
      setmyAccount(true);
    }
  }, [getCustomer]);

  return isPostActive ? (
    custDetail && <PostHeader isPrimary={custDetail && custDetail?.isPrimary} />
  ) : isActive ? (
    <SimpleHeader user={userData} middleContent={"Checkout"} />
  ) : (
    <Header isMyAccount={myAccount} />
  );
};

export default MainHeader;
