// track order api
export const TRACK_ORDER = 'TRACK_ORDER';
export const TRACK_ORDER_SUCCESS = 'TRACK_ORDER_SUCCESS';
export const TRACK_ORDER_FAILURE = 'TRACK_ORDER_FAILURE';

// reach plan api
export const REACH_PLAN = 'REACH_PLAN';
export const REACH_PLAN_SUCCESS = 'REACH_PLAN_SUCCESS';
export const REACH_PLAN_FAILURE = 'REACH_PLAN_FAILURE';

// referal api
export const REFERAL = 'REFERAL';
export const REFERAL_SUCCESS = 'REFERAL_SUCCESS';
export const REFERAL_FAILURE = 'REFERAL_FAILURE';

// referel get by email id
export const REFERAL_GET = 'REFERAL_GET';
export const REFERAL_GET_SUCCESS = 'REFERAL_GET_SUCCESS';
export const REFERAL_GET_FAILURE = 'REFERAL_GET_FAILURE';
