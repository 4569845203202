import apiJunction from "../utils/api";
import { version } from "../config/config";
import {
  compatibilityFailure,
  compatibilitySuccess,
  reservePromoCouponFailure,
  reservePromoCouponSuccess,
  getAllPlansSuccess,
  getAllPlansFailure,
} from "../redux/PlanPage/actions";


export const getAllPlansApi = ()=> {
  return async (dispatch: any) =>{
    try {
      const res = await apiJunction.makeRequest({
        method: 'get',
        url: `catalogsvc/${version}/reachplans/active`
      })
      if(res.status === 200) {
        dispatch(
          getAllPlansSuccess({
            allPlans : res.data,
          })
        )
      }
    } catch (err:any) {
      if (err.response.status === 400)
        dispatch(
          getAllPlansFailure({
            errorAllPlans: err.message,
          })
        );
    } 
  }
}
export const compatibilityApi = (action: any) => {
  return async (dispatch: any) => {
    try {
      const res = await apiJunction.makeRequest({
        method: "get",
        url: `/provsvc/${version}/imei/info/${action.imeiNumber}`,
      });

      if (res.status === 200) {
        dispatch(
          compatibilitySuccess({
            compat: res.data,
          })
        );
      }
    } catch (err: any) {
      if (err.response.status === 400)
        dispatch(
          compatibilityFailure({
            errorCompat: err.message,
          })
        );
    }
  };
};

export const reservePromoCouponApi = (action: any) => {
  return async (dispatch: any) => {
    try {
      const res = await apiJunction.makeRequest({
        method: "post",
        url: `/catalogsvc/${version}/coupon/email/mapping`,
        body: action.payload.data,
      });

      if (res.status === 200) {
        dispatch(
          reservePromoCouponSuccess({
            data: res.data,
          })
        );
      }
    } catch (err: any) {
      if (err.response.status === 400)
        dispatch(
          reservePromoCouponFailure({
            errorData: err.message,
          })
        );
    }
  };
};

export const PlanService = {
  compatibilityApi,
  reservePromoCouponApi,
  getAllPlansApi,
};
