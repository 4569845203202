//data cut off
export const DATA_CUTOFF = "DATA_CUTOFF";
export const DATA_CUTOFF_SUCCESS = "DATA_CUTOFF_SUCCESS";
export const DATA_CUTOFF_FAILURE = "DATA_CUTOFF_FAILURE";

//top up
export const TOP_UP = "TOP_UP";
export const TOP_UP_SUCCESS = "TOP_UP_SUCCESS";
export const TOP_UP_FAILURE = "TOP_UP_FAILURE";

//top up pulse
export const TOP_UP_PULSE = "TOP_UP_PULSE";
export const TOP_UP_PULSE_SUCCESS = "TOP_UP_PULSE_SUCCESS";
export const TOP_UP_PULSE_FAILURE = "TOP_UP_PULSE_FAILURE";

//customer groups
export const GROUPS = "GROUPS";
export const GROUPS_SUCCESS = "GROUPS_SUCCESS";
export const GROUPS_FAILURE = "GROUPS_FAILURE";

//top groups
export const TOP_UP_GROUPS = "TOP_UP_GROUPS";
export const TOP_UP_GROUPS_SUCCESS = "TOP_UP_GROUPS_SUCCESS";
export const TOP_UP_GROUPS_FAILURE = "TOP_UP_GROUPS_FAILURE";

// get credit in referall
export const GET_CREDIT = "GET_CREDIT";
export const GET_CREDIT_SUCCESS = "GET_CREDIT_SUCCESS";
export const GET_CREDIT_FAILURE = "GET_CREDIT_FAILURE";

// type4 tax

export const RESET_TAX_4_TYPE = "RESET_TAX_4_TYPE";

export const TAX_4_TYPE = "TAX_4_TYPE";
export const TAX_4_TYPE_SUCCESS = "TAX_4_TYPE_SUCCESS";
export const TAX_4_TYPE_FAILURE = "TAX_4_TYPE_FAILURE";

// top up customer
export const TOP_UP_CUSTOMER = "TOP_UP_CUSTOMER";
export const TOP_UP_CUSTOMER_SUCCESS = "TOP_UP_CUSTOMER_SUCCESS";
export const TOP_UP_CUSTOMER_FAILURE = "TOP_UP_CUSTOMER_FAILURE";

// top up customer status
export const TOP_UP_CUSTOMER_STATUS = 'TOP_UP_CUSTOMER_STATUS';
export const TOP_UP_CUSTOMER_STATUS_SUCCESS = 'TOP_UP_CUSTOMER_STATUS_SUCCESS';
export const TOP_UP_CUSTOMER_STATUS_FAILURE = 'TOP_UP_CUSTOMER_STATUS_FAILURE';

// buy data customer status
export const PLAN_CHANGE_CUSTOMER_STATUS = 'PLAN_CHANGE_CUSTOMER_STATUS';
export const PLAN_CHANGE_CUSTOMER_STATUS_SUCCESS = 'PLAN_CHANGE_CUSTOMER_STATUS_SUCCESS';
export const PLAN_CHANGE_CUSTOMER_STATUS_FAILURE = 'PLAN_CHANGE_CUSTOMER_STATUS_FAILURE';

// referal credit
export const REFERAL_CREDIT = "REFERAL_CREDIT";
export const REFERAL_CREDIT_SUCCESS = "REFERAL_CREDIT_SUCCESS";
export const REFERAL_CREDIT_FAILURE = "REFERAL_CREDIT_FAILURE";

// change plan
export const CHANGE_PLAN = "CHANGE_PLAN";
export const CHANGE_PLAN_SUCCESS = "CHANGE_PLAN_SUCCESS";
export const CHANGE_PLAN_FAILURE = "CHANGE_PLAN_FAILURE";
