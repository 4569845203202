import { globalVal } from "../globalvalues";
export const homePage: any = {
  overlayImage: {
    heading: "Switch to the better 5G",
    subheading: "Better coverage. Better speeds. Better value.",
  },
  carousel: {
    carouselMainText: "Goodbye mobile store. Hello convenience.",
    text1: "No contracts",
    text2: "Unlimited talk and text",
    text3: "Hotspot enabled",
  },
  coverageCheck: {
    heading: "Check your coverage",
    subHeading:
      "Get the speeds and coverage you expect, with the nation's largest and fastest 5G network.",
  },
  spotlight: {
    first: {
      a: "Reach Mobile stands out in offering a simple, human-centric and common sense mobile service.",
      b: "One of the best things about Reach Mobile is its excellent customer service (as confirmed by hundreds of happy customers).",
      c: "Reach Mobile is a service designed to provide high-quality phone and data plans at affordable rates.",
      d: "Ranked No. 1 for customer service, the carrier invests 10% of its data costs into granting free mobile access to women and students in need.",
    },
    second: {
      a: "Mobile carrier offers premium coverage with unique cost-effective pricing model.",
      b: "Setting up with Reach Mobile was straightforward… I was up and running within—no joke—5 minutes.",
      c: "….is a great choice for those with modest data needs who also enjoy supporting a company that does good in the community.",
      d: "",
    },
    third: {
      a: "A cellphone carrier that charges you less if you don’t use all of your data.",
      b: "Reach gives back to you, too. They have a Best Fit policy, which means… you’ll never pay more than you need to.",
      c: "New mobile service providing consumers with a socially responsible, cost-effective wireless carrier.",
      d: "",
    },
  },
  title: {
    title1: "How many lines do you need?",
    title2: "We care about our customers. And they noticed.",
    title3: "In the spotlight",
  },
  info_area_1: {
    title: "The best mobile network. Period.",
    info: "Network reliability is everything. That's why we use the nation's largest and most reliable network to deliver the speeds and coverage you expect. It's big-carrier coverage without the big-carrier hassles.",
  },
  info_area_2: {
    info: "Most carriers pocket the profit when you have leftover data. With Best Fit™, we’ll lower your bill automatically if a smaller plan would have suited you best. How’s that for honest savings?",
    title: "The best network costs less with Best Fit™",
  },
  info_area_3: {
    info: "Our team goes the extra mile to make sure each and every customer is happy with their service. You'll reach a helpful human whenever you need us - 24/7.",
    title: "Amazing customer service",
  },
  info_area_4: {
    info: `Only 54% of women in low- and middle-income countries have access to mobile internet (1) Yet even in the U.S., there are many who cannot afford a monthly smartphone bill. With every  ${
      globalVal && globalVal.long_name
    } plan purchased, we're helping extend the benefits of mobile connectivity to women and students in need, both close to home and around the world.`,
    title: "Giving back with Get a plan, Give a plan",
    subInfo: "(1) GSMA (2020), The Mobile Gender Gap Report 2020",
  },
  info_area_5: {
    info: "Your number and phone can make the switch to Reach. Let's make sure it’s unlocked and network-compatible.",
    title: "Love your phone & digits? Bring them with you!",
  },
  notification1:
    "New customers save 50% for 3 months! Use promo code REACH503 at checkout.",
  homeSpotlight: {
    heading: {
      heading1: "Unlimited Talk & Text",
      heading2: "No Contract",
      heading3: "24/7 Helpful humans",
      heading4: "Hotspot enabled",
      heading5: "International Services",
      heading6: "Up to 6 lines",
      heading7: "HD streaming",
      heading8: "Keep your number",
    },
    subHeading: {
      subHeading1: "Stay connected to the ones you love most.",
      subHeading2: "No restrictions on how you use your data.",
      subHeading3:
        "Great rates on long-distance calling & roaming in 135+ countries.",
    },
  },
  planOfferText: {
    text1:
      "Activate 3+ lines to enjoy an <strong> extra +5GB of free data</strong> every month",
    text2:
      "Congrats! <strong>Enjoy an extra +5GB of free data</strong> every month.​",
  },
  inviterefferal: {
    headerText: "Some things are better with friends!",
    subText1: "Your friend",
    subText2:
      "just gave you a credit. Enter your info below & we`ll apply discount code",
    subText3: "at check out.",
  },
  accordion: [
    {
      label: `How it works`,
      description: [
        "1. Dial #P-O-R-T, enter in your 4 digit PIN.",
        "2. You will receive an SMS after you authenticate your account.",
        "3. Select the link provided, and your Number Transfer PIN is generated with an expiration date. Your account number will also be provided.",
      ],
    },
    {
      label: `FAQ`,
      description: [
        "1. Visit your Verizon account and view your profile.",
        '2. Click on "Number transfer PIN" and it will prompt you to click on "Generate PIN".',
        "*If you are a group member and someone within your group already generated a PIN, use the same code.",
      ],
    },
    {
      label: `Terms & Conditions`,
    },
  ],
};

export const homePageWow: any = {
  overlayImage: {
    heading: "Doggone reliable. Doggone affordable.",
    subheading: "WOW! Internet customers get $10 off per line, each month.",
  },
  planSelections: {
    title: "Choose the plan that is right for you",
  },
  // discoverActivatedSection: {
  //   title: "Get so much more with WOW!",
  //   progressSteps: {
  //     first: {
  //       title: "Manage your plan",
  //       desc: "Add lines, view data usage and more.",
  //     },
  //     second: {
  //       title: "Great deals and discounts",
  //       desc:"Discover exciting offers on curated products and services."
  //     },
  //     third: {
  //       title: "Stay connected with International Services",
  //       desc: "Simple and affordable plans for international calling, texting and roaming.",
  //     },
  //   },
  // },
  discoverASection: {
    title: "Get so much more with WOW!",
    subtitle: "Discover new mobile services, right from your phone",
    progressSteps: {
      first: {
        title: "Manage your plan",
        desc: "Add lines, view data usage and more.",
      },
      second: {
        title: "Great deals and discounts",
        desc: "Discover exciting offers on curated products and services.",
      },

      third: {
        title: "Stay connected with International Services",
        desc: "Simple and affordable plans for international calling, texting and roaming.",
      },
      fourth: {
        title: "Make an impact",
        desc: "Every month, you can see the personal impact on the Gigs that Give initiative to help to connect  people.",
      },
    },
  },
  discoverSection: {
    title: "A better way to do mobile",
    subtitle: "Discover new mobile services, right from your phone",
    progressSteps: {
      first: {
        title: "Sign up for your plan",
        desc: "Unlimited or by the gig, every plan comes with unlimited talk and text, plus other perks.",
      },
      second: {
        title: "Select your device",
      },
      third: {
        title: "Activate your service",
        desc: "On the couch or on the go, you can activate your WOW! mobile SIM right from our app.",
      },
      fourth: {
        title: "Make an impact",
        desc: "Every month, you can see the personal impact on the Gigs that Give initiative to help to connect  people.",
      },
    },
  },

  trustPilotSection: {
    title: "We care about our customers. And they noticed.",
    subtitle:
      "Our goal is to create a product and service that you’re satisfied with and use it every day.",
  },

  stillExploring: {
    title:
      "No spam. Just notifications only about new products, updates and​ freebies. You can always unsubscribe.​",
    keepintouchText:
      "We really look forward to reaching out to you with plans and offers that meet your needs",
  },
};
