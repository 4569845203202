import {
    ACTIVE_PLANS,
    ACTIVE_PLANS_FAILURE,
    ACTIVE_PLANS_SUCCESS,
    COMPATIBILITY,
    COMPATIBILITY_SUCCESS,
    COMPATIBILITY_FAILURE,
    GET_ALL_PLANS,
    GET_ALL_PLANS_SUCCESS,
    GET_ALL_PLANS_FAILURE,
} from './actionTypes';

import { PlanActions, PlanState } from './types';

const initialState: PlanState = {
    plans: [],
    error: null,
    isData: false,
    compat: [],
    errorCompat: null,
    allPlans: [],
    errorAllPlans: null,
};

export default (state = initialState, action: PlanActions) => {
    switch (action.type) {
        case ACTIVE_PLANS:
            return {
                ...state,
            };
        case ACTIVE_PLANS_SUCCESS:
            return {
                ...state,
                plans: action.payload.plans,
                error: null,
            };
        case ACTIVE_PLANS_FAILURE:
            return {
                ...state,
                plans: [],
                error: action.payload.error,
            };
        case COMPATIBILITY:
            return {
                ...state,
                isData: false,
            };
        case COMPATIBILITY_SUCCESS:
            return {
                ...state,
                compat: action.payload.compat,
                errorCompat: null,
                isData: true,
            };
        case COMPATIBILITY_FAILURE:
            return {
                ...state,
                compat: [],
                errorCompat: action.payload.errorCompat,
                isData: false,
            };
        case GET_ALL_PLANS:
            return {
                ...state,
            };
        case GET_ALL_PLANS_SUCCESS:
            return {
                ...state,
                allPlans: action.payload.allPlans,
                errorAllPlans: null,
            };
        case GET_ALL_PLANS_FAILURE:
            return {
                ...state,
                allPlans: [],
                errorAllPlans: action.payload.errorAllPlans,
            }
        default:
            return {
                ...state,
            };
    }
};
