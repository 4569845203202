import { createTheme, ThemeProvider } from '@mui/material';
import * as React from 'react';
import { lazy, Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ReactNotifications } from 'react-notifications-component';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import MainHeader from '../components/Header/MainHeader';
import BouncingDotsLoader from '../components/Loader/BouncingDotsLoader';
import jsonData from '../data.json';
import { seoData } from '../global/seoData';
import BringYourPhone from '../pages/BringYourPhone/BringYourPhone';
import HomePage from '../pages/HomePage/HomePage';
import ProtectedRoutes from './ProtectedRoutes';
import './routes.scss';

const LazyPostHome = lazy(() => import('../pages/PostActivationPages/Home/Home'));
const LazyRecoverPassword = lazy(() => import('../pages/RecoverPassword/RecoverPassword'));
const LazyLoaderPage = lazy(() => import('../pages/Loader/LoaderPage'));
// const LazyAboutUs = lazy(() => import("../pages/AboutUs/AboutUs"));
const LazyTrackOrder = lazy(() => import('../pages/TrackOrder/TrackOrder'));
const LazyActivation = lazy(() => import('../pages/PostActivationPages/ActivationPage/Activation'));
const LazyBilling = lazy(() => import('../pages/PostActivationPages/Billing/Billing'));
const LazyServices = lazy(() => import('../pages/PostActivationPages/ServicesPage/Services'));
const LazyAccount = lazy(() => import('../pages/PostActivationPages/Account/Account'));
const LazyProfile = lazy(() => import('../pages/Profile/Profile'));
const LazySettings = lazy(() => import('../pages/SettingsPage/SettingsPage'));

const LazyMyAccount = lazy(() => import('../pages/MyAccount/MyAccount'));
const LazyCheckOut = lazy(() => import('../pages/CheckOutPage/Checkout'));
const LazyCoverageCheck = lazy(() => import('../pages/CoverageCheck/CoverageCheck'));
const LazyInternationalServices = lazy(() => import('../pages/InternationalServices/InternationalServices'));
const LazyContactUs = lazy(() => import('../pages/ContactUs/ContactUs'));
const LazyHelp = lazy(() => import('../pages/Help/Help'));
const LazyBillCalculator = lazy(() => import('../pages/BillCalculator/BillCalculator'));
const LazyLogin = lazy(() => import('../pages/Login/Login'));
const LazySignUp = lazy(() => import('../pages/SignUp/SignUp'));

const LazyTerms = lazy(() => import('../pages/Terms/Terms'));
const LazyTermsUnlimited = lazy(() => import('../pages/TermsUnlimited/TermsUnlimited'));

const LazyPrivacy = lazy(() => import('../pages/Privacy/Privacy'));
const LazyLandingNew = lazy(() => import('../pages/LandingPage/LandingNew'));
const LazyLandingCrossFit = lazy(() => import('../pages/LandingPageCrossFit/LandingPageCrossFit'));
const LazyLandingGiveBack = lazy(() => import('../pages/LandingPageGiveBack/LandingPageGiveBack'));
const LazyLandingIndividual = lazy(() => import('../pages/LandingPageIndividual/LandingPageIndividual'));
const LazyLandingBestFit = lazy(() => import('../pages/LandingPageBestFit/LandingPageBestFit'));

const LazyLandingUnlimitedPromo = lazy(() => import('../pages/LandingPageUnlimitedPromo/LandingPageUnlimitedPromo'));
const theme = createTheme({
    palette: {
        primary: {
            main: '#10ACE3',
        },
        secondary: {
            main: '#fff',
        },
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    fontSize: '14px',
                    color: '#fff',
                },
            },
        },
    },
});

let arr: any = [];
(function nameLandingPageRoutes() {
    jsonData.data.map((plan) => {
        arr.push(plan.name.substring(0, plan.name.indexOf(' ')).replaceAll(`'`, '').concat('-', 'plan').toLowerCase());
    });
})();

const AppRoutes: React.FC = () => (
    <>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                {/* <Header /> */}
                <MainHeader />
                <ReactNotifications />
                <div className="mainRoutes">
                    <HelmetProvider>
                        <Helmet>
                            <title>{seoData.home.title}</title>
                            <meta name="description" content={seoData.home.description} />
                            <meta name="keywords" content={seoData.home.keywords} />
                        </Helmet>
                    </HelmetProvider>
                    <Routes>
                        {/* Prelogin routes */}
                        <Route path="/byod" element={<Navigate to="/imei-compatibility-check" replace />} />
                        <Route path="/chat" element={<Navigate to="/#hs-chat-open" replace />} />

                        <Route path="*" element={<Navigate to="/" replace />} />
                        <Route path="/" element={<HomePage />} />
                        <Route path="/home" element={<HomePage />} />
                        <Route
                            path="/coverage-check"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    {' '}
                                    <LazyCoverageCheck />
                                </Suspense>
                            }
                        />
                        <Route
                            path="/help"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    {' '}
                                    <LazyHelp />{' '}
                                </Suspense>
                            }
                        />
                        <Route
                            path="/contact-us"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    <LazyContactUs />
                                </Suspense>
                            }
                        />
                        <Route path="/imei-compatibility-check" element={<BringYourPhone />} />
                        <Route
                            path="/bill-calculator"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    <LazyBillCalculator />
                                </Suspense>
                            }
                        />
                        <Route
                            path="/international_services"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    {' '}
                                    <LazyInternationalServices />{' '}
                                </Suspense>
                            }
                        />
                        <Route
                            path="/login"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    {' '}
                                    <LazyLogin />{' '}
                                </Suspense>
                            }
                        />
                        <Route
                            path="/recover"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    {' '}
                                    <LazyRecoverPassword />{' '}
                                </Suspense>
                            }
                        />
                        <Route
                            path="/signup"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    {' '}
                                    <LazySignUp />{' '}
                                </Suspense>
                            }
                        />
                        <Route
                            path="/terms"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    {' '}
                                    <LazyTerms />{' '}
                                </Suspense>
                            }
                        />
                        <Route
                            path="/terms-ul-bundle"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    {' '}
                                    <LazyTermsUnlimited />{' '}
                                </Suspense>
                            }
                        />
                        <Route
                            path="/privacy-policy"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    {' '}
                                    <LazyPrivacy />{' '}
                                </Suspense>
                            }
                        />
                        <Route
                            path="/checkout"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    {' '}
                                    <LazyCheckOut />{' '}
                                </Suspense>
                            }
                        />
                        {/* <Route path="/activation" element={<Activation />} /> */}
                        <Route element={<ProtectedRoutes />}>
                            <Route
                                path="/post-activation-home"
                                element={
                                    <Suspense fallback={<BouncingDotsLoader />}>
                                        {' '}
                                        <LazyPostHome />{' '}
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/my-account"
                                element={
                                    <Suspense fallback={<BouncingDotsLoader />}>
                                        {' '}
                                        <LazyMyAccount />{' '}
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/track-order"
                                element={
                                    <Suspense fallback={<BouncingDotsLoader />}>
                                        {' '}
                                        <LazyTrackOrder />{' '}
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/activation"
                                element={
                                    <Suspense fallback={<BouncingDotsLoader />}>
                                        {' '}
                                        <LazyActivation />{' '}
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/account"
                                element={
                                    <Suspense fallback={<BouncingDotsLoader />}>
                                        {' '}
                                        <LazyAccount />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/billing"
                                element={
                                    <Suspense fallback={<BouncingDotsLoader />}>
                                        <LazyBilling />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/services"
                                element={
                                    <Suspense fallback={<BouncingDotsLoader />}>
                                        <LazyServices />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/loader"
                                element={
                                    <Suspense fallback={<BouncingDotsLoader />}>
                                        <LazyLoaderPage />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/profile"
                                element={
                                    <Suspense fallback={<BouncingDotsLoader />}>
                                        <LazyProfile />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="/settings"
                                element={
                                    <Suspense fallback={<BouncingDotsLoader />}>
                                        <LazySettings />
                                    </Suspense>
                                }
                            />
                        </Route>
                        {arr.map((plan: any) => (
                            <Route
                                path={`/${plan}`}
                                key={plan}
                                element={
                                    <Suspense fallback={<BouncingDotsLoader />}>
                                        {' '}
                                        <LazyLandingNew history={' '} />{' '}
                                    </Suspense>
                                }
                            />
                        ))}
                        {/* <Route
              path="/moderate-plan"
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  {" "}
                  <LazyLandingNew history={" "} />{" "}
                </Suspense>
              }
            />
            <Route
              path="/people-choice-plan"
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  {" "}
                  <LazyLandingNew history={" "} />{" "}
                </Suspense>
              }
            />
            <Route
              path="/all-in-plan"
              element={
                <Suspense fallback={<BouncingDotsLoader />}>
                  {" "}
                  <LazyLandingNew history={" "} />{" "}
                </Suspense>
              }
            /> */}
                        <Route
                            path="/lp01-allplans"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    {' '}
                                    <LazyLandingNew history={' '} />{' '}
                                </Suspense>
                            }
                        />
                        <Route
                            path="/lp01-giveback"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    {' '}
                                    <LazyLandingGiveBack history={' '} />{' '}
                                </Suspense>
                            }
                        />
                        <Route
                            path="/lp01-individual"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    {' '}
                                    <LazyLandingIndividual history={' '} />{' '}
                                </Suspense>
                            }
                        />

                        <Route
                            path="/ul-bundle-promo"
                            element={
                                <Suspense fallback={<BouncingDotsLoader />}>
                                    {' '}
                                    <LazyLandingUnlimitedPromo history={' '} />{' '}
                                </Suspense>
                            }
                        />
                    </Routes>
                </div>
                <Footer isFixed={true} />
            </BrowserRouter>
        </ThemeProvider>
    </>
);
export default AppRoutes;
