import { Store } from "react-notifications-component";

type Props = {
  message: any;
  type?: any;
  duration?: number;
  showIcon?: boolean;
  id?: string;
};

export function NotificationComponent(props: Props) {
  return Store.addNotification({
    message: props.message,
    type: props.type || "success",
    insert: "top",
    container: "top-full",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: props.duration || 5000,
      showIcon: props.showIcon || true,
    },
    id: props.id,
  });
}
