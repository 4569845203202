export const max_refresh_time = 60000;
// export const paymentAddress = process.env.REACT_APP_PAYMENT_ADDRESS;
// export const csrAddress = process.env.REACT_APP_CSR_ADDRESS;
export const version = "v0";
// const serverAddress = domain ? 'https://api-dev.reachmobileplatform.com' : 'https://api-dev.reachmobile.com/v0';
// const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
//export const domain = process.env.DOMAIN_BOOLEAN; // true=== earthlink amd false=== reach mobile
// export const serverAddressDev = 'https://api-dev.reachmobile.com/v0';
export const domain = false;

//domainName should be same as one of the key-value from domainMap.
// in domainMap T1 corresponds to REACH (sim) while T2 to corresponds to EL (fixed-wireless)
// naming can be any to make it not associated with a particular brand. (just that domainName should be present in domainMap)
const fetchDomainName = () => {
  switch (process.env.REACT_APP_SERVER_NAME) {
    case "reach-dev":
    case "reach-qa":
    case "reach-prod":
      return "TEMP1";
    case "wow-dev":
    case "wow-qa":
    case "wow-prod":
      return "TEMP4";
    default:
      break;
  }
};
export const domainName = fetchDomainName();
export const domainMap = {
  T1: "TEMP1",
  T2: "TEMP2",
  T3: "TEMP3",
  T4: "TEMP4",
};
// export default serverAddress;
