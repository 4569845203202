import { AppBar, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { globalVal } from "../../global/globalvalues";
import firebase from "../../utils/firebase";
import ButtonNew from "../Button/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { Close } from "@mui/icons-material";
import "./SimpleHeader.scss";
import { Modal as HeaderModal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ResetCustomer } from "../../redux/Checkout/actions";
import { Store } from "react-notifications-component";

type Props = { middleContent?: string; user?: any; isHelp?: boolean };

const SimpleHeader = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setanchorEl] = useState<any>(null);
  const [isModal, setisModal] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>("");
  let currentUser = localStorage.getItem("currentuser");

  useEffect(() => {
    if (currentUser) {
      setUserData(JSON.parse(currentUser));
    }
  }, [currentUser]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setanchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setanchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    Store.removeAllNotifications();

    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("currentuser");
        localStorage.removeItem("accessToken");
        localStorage.clear();
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(ResetCustomer());
    navigate("/home");
  };

  const openModal = () => {
    setisModal(true);
  };
  const onHide = () => {
    setisModal(false);
  };

  return (
    <div className="simpleHeaderMain">
      <AppBar position="fixed" className="headerContent">
        <div className="headerInnerDiv">
          <div onClick={() => navigate("/")}>
            <img
              className="headerLogo"
              src={globalVal && globalVal.logo ? globalVal.logo : ""}
              alt="Logo"
            />
          </div>
          <div className="middleDiv">{props.middleContent}</div>

          <div className="lastDiv">
            {props.isHelp && (
              <div className="help" onClick={() => navigate("/help")}>
                HELP
              </div>
            )}
            {/* {props.user && props.user.displayName && ( */}
            {((props.user && props.user.displayName) ||
              (userData && userData.displayName)) && (
              <div>
                <ButtonNew
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  className={
                    anchorEl
                      ? "clickedHeaderTab fontStyleHeader"
                      : "fontStyleHeader"
                  }
                  // onMouseOver={this.handleClick}
                  onClick={handleClick}
                >
                  <div className="innerLast">
                    <AccountCircleIcon />
                    <div className="name">
                      {(props &&
                        props.user &&
                        props.user.displayName &&
                        props.user.displayName.split(" ")[0]) ||
                        (userData && userData.displayName.split(" ")[0])}
                    </div>
                  </div>
                </ButtonNew>
                <Menu
                  id="simple-menu"
                  className="SimpleHeaderDropdown"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  // MenuListProps={{ onMouseLeave: this.handleClose }}
                  // MenuListProps={{ onMouseClick: this.handleClose }}
                  onClose={handleClose}
                >
                  {props.isHelp ? null : (
                    <MenuItem
                      className="menuitemHeader"
                      onClick={() => navigate("/help")}
                    >
                      HELP
                    </MenuItem>
                  )}

                  {props.user && props.user.simPaid && props.user.status === 0 && (
                    <MenuItem
                      className="menuitemHeader"
                      onClick={() => navigate("/profile")}
                    >
                      PROFILE
                    </MenuItem>
                  )}
                  <MenuItem
                    className="menuitemHeader"
                    onClick={() => handleLogout()}
                  >
                    LOGOUT
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        </div>
      </AppBar>

      <AppBar position="fixed" className="headerContentMobile">
        <div className="headerContentMobileinner">
          <div>
            <MenuIcon
              style={{
                color: "var(--primary_color)",
                width: "50px",
                height: "51px",
                padding: "0px 0px 0px 15px",
              }}
              onClick={openModal}
            />
          </div>

          <div className="logoMobile" onClick={() => navigate("/")}>
            <img
              className="headerLogoMobile"
              src={globalVal && globalVal.logo}
              alt="header logo"
            />
          </div>
        </div>
      </AppBar>
      <HeaderModal
        show={isModal}
        onHide={() => onHide()}
        size={"xl"}
        className="modalMainSimpleHeader"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Close className="closeIcon" onClick={() => onHide()} />
        <div className="bottomContent">
          <div className="innerLast">
            {props.user && props.user.displayName && (
              <div>
                <AccountCircleIcon />
                <div className="name">
                  {props.user.displayName.split(" ")[0]}
                </div>
              </div>
            )}
            <div
              className="name otherHead"
              onClick={() => {
                navigate("/");
              }}
            >
              WOW!
            </div>
            <div
              className="subtext"
              onClick={
                () => navigate("/help")
                // : window.open("https://learn.reachmobile.com/en", "_blank")
              }
            >
              HELP
            </div>
            {props.user && props.user.simPaid && props.user.status === 0 && (
              <div className="subtext" onClick={() => navigate("/profile")}>
                PROFILE
              </div>
            )}
            {((props.user && props.user.displayName) ||
              (userData && userData.displayName)) && (
              <div className="subtext" onClick={() => handleLogout()}>
                LOGOUT
              </div>
            )}
          </div>
        </div>
      </HeaderModal>
    </div>
  );
};

export default SimpleHeader;
