import { globalVal } from "./globalvalues";
export const altText: any = {
  home: {
    carouselImage: `Woman placing an order for a ${
      globalVal && globalVal.long_name
    } SIM`,
    image1: `Get the largest and best mobile network with ${
      globalVal && globalVal.long_name
    }`,
    image2: "Best network that charges you less",
    image3: `Amazing 24/7 customer service with ${
      globalVal && globalVal.long_name
    }`,
    image4: "Recipients of free data with Get a plan, Give a plan",
    image5: "Mobile service simplified",
    shippingIcon: "Free shipping",
  },
  bestFit: {
    mainImage: "Best network that charges you less",
    image1: "Save money with Best Fit plans",
    image2: "Never overpay for unused data ever again",
    image3: "Best Fit saves you money automatically",
    imageLast:
      "Calculate how much money you will save with Easy bill calculator ",
  },
  giveBack: {
    mainImage: "Every plan helps give a plan",
    image1: "Every plan helps kids education",
    image2: "Free data helps families in need",
    image3: "Free data helps families make better informed decision",
    image4: "Free data helps families realize their dreams ",
    longImage: "10% of every plan enables free connectivity to women in need ",
    iconImage1: "Our connectivity partner The/Nudge",
    iconImage2: "Our connectivity partner LIFT",
    iconImage3: "Our connectivity partner Ripples",
  },
  billCalculator: {
    mainImage: "Calculate your savings with easy bill calculator ",
  },
  contactUs: {
    sendEmail: `Send an email to ${
      globalVal && globalVal.long_name
    } Customer Service `,
  },
  discover: {
    mainImage: `Discover great deals and amazing offers on ${
      globalVal && globalVal.long_name
    }`,
  },
  planPage: {
    mainImage: "The best mobile network",
    line1: "Selected 1 line",
    line2: "Selected 2 line",
    line3: "Selected 3 line",
    line4: "Selected 4 line",
    line5: "Selected 5 line",
    line6: "Selected 6 line",
    image1: `Get the largest and best mobile network with ${
      globalVal && globalVal.long_name
    }`,
    image2: "Bring your old or new phone with you",
    tabImage1: `${globalVal && globalVal.long_name} plans that save you money`,
    tabImage2: `${globalVal && globalVal.long_name} plans that save you money`,
    tabImage3: `Getting started with ${
      globalVal && globalVal.long_name
    } is simple`,
    tabImage4: "Every plan helps give a plan",
  },
};
