import { AppBar, Button, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { globalVal } from "../../global/globalvalues";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./Header.scss";
import logo from "../../assets/images/commonImages/wow_logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import firebase from "../../utils/firebase";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Modal as HeaderModal } from "react-bootstrap";
import { Close } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch } from "react-redux";
import { ResetCustomer } from "../../redux/Checkout/actions";
import { Store } from "react-notifications-component";
import { pushTrackingEventsToGAAndFB } from "../../global/commonFunctions/GaAndFb";
type Props = { isMyAccount?: any };

const Header = (props: Props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [anchorEl, setanchorEl] = useState<any>(false);
  const [anchorEl1, setanchorEl1] = useState<any>(false);
  const [servicesOptionSelected, setservicesOptionSelected] = useState("");
  const [anchorEl3, setanchorEl3] = useState<any>(false);
  const [user, setuser] = useState({ displayName: "" });
  const [isShowPlan, setisShowPlan] = useState(true);
  const [isModal, setisModal] = useState(false);
  const [isServiceEnable, setisServiceEnable] = useState(false);
  const [isPhoneEnable, setisPhoneEnable] = useState(false);
  const [customer, setcustomer] = useState({ simPaid: false, status: 0 });

  const handleLogout = () => {
    setanchorEl3(null);
    Store.removeAllNotifications();

    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("currentuser");
        localStorage.removeItem("accessToken");
        setuser({ displayName: "" });
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(ResetCustomer());
    navigate("/home");
    localStorage.clear();
    sendGAEventsHandler("Logout");
  };

  const handleClose = () => {
    setanchorEl(null);
  };

  const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setanchorEl3(event.currentTarget);
  };

  const handleClose1 = () => {
    setanchorEl1(null);
  };
  const handleClose3 = () => {
    setanchorEl3(null);
  };

  const openModal = () => {
    setisModal(true);
  };
  const onHide = () => {
    setisModal(false);
  };

  const openMenu = (event: any) => {
    setanchorEl(event.currentTarget);
  };

  const openMenu1 = (event: any) => {
    setanchorEl1(event.currentTarget);
  };

  const sendGAEventsHandler = (event: any) => {
    pushTrackingEventsToGAAndFB({
      category: "Header",
      actionType: `${event}`,
    });
  };

  const handleClickedMiddleButtons = (page: any) => {
    if (page === "Plans") {
      navigate("/home");
      setTimeout(function () {
        scroller.scrollTo("bestCellPhonePlan", {
          duration: 1000,
          delay: 0,
          smooth: "easeInOutQuart",
          smooh: "easeOutQuart",
          offset: -100,
        });
      }, 1000);
      sendGAEventsHandler("Plans");
    } else if (page === "Bill Calculator") {
      navigate("/bill-calculator");
      sendGAEventsHandler("Bill Calculator");
    }
    // } else if (e === 'Give Back') {
    //     props.history.push('/giveback');
    // } else if (e === 'Blog') {
    //     window.open('https://connect.reachmobile.com/', '_blank');
    else if (page === "Sign In") {
      navigate("/login", { state: { isForgotPassword: false } });
      sendGAEventsHandler("Signin");
    } else if (page === "Help") {
      navigate("/help");
      sendGAEventsHandler("Help");
    }
  };

  const handleServicesOptions = (page: any) => {
    setservicesOptionSelected(page);
    if (page === "International Services") {
      navigate("/international_services");
      sendGAEventsHandler("International Services");
    }
    handleClose();
  };

  useEffect(() => {
    let user1: any = localStorage.getItem("currentuser");
    user1 = JSON.parse(user1);
    user1 && !user1.isAnonymous && setuser(user1);
    let customer: any = localStorage.getItem("customerDetail");
    customer = JSON.parse(customer);
    if (customer && customer.simPaid) {
      setcustomer({
        simPaid: customer.simPaid,
        status: customer.status,
      });
    }
    customer &&
      customer &&
      customer.shipmentInfo &&
      customer.reachPlanId &&
      Object.keys(customer.shipmentInfo).length > 0 &&
      Object.keys(customer.shipmentInfo)[0] &&
      setisShowPlan(false);
    return () => {
      setisModal(false);
      setisServiceEnable(false);
      setisPhoneEnable(false);
    };
  }, [location]);

  return (
    <div className="headerMain">
      <AppBar position="fixed" className="headerContent">
        <div className="headerInnerDiv">
          <div onClick={() => navigate("/home")}>
            <img className="headerLogo" src={logo} alt="" />
          </div>
          <div className="arrayDivHeader">
            {/* {isShowPlan && ( */}
            <div
              className={
                window.location.pathname === "/home"
                  ? "buttonMiddleHeader activeRouteHeader"
                  : "buttonMiddleHeader"
              }
            >
              <Button
                aria-controls="simple-menu"
                className="fontStyleheader"
                aria-haspopup="true"
                onClick={async () => {
                  // if (props?.history?.location?.pathname !== "/") {
                  //   await props.history.push("/");
                  //   // handleClickedMiddleButtons('Plans');
                  // }
                  handleClickedMiddleButtons("Plans");
                }}
              >
                Plans
              </Button>
            </div>
            {/* )} */}
            <div
              className={
                window.location.pathname === "/bill-calculator"
                  ? "buttonMiddleHeader activeRouteHeader"
                  : "buttonMiddleHeader"
              }
            >
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="fontStyleheader"
                onClick={() => handleClickedMiddleButtons("Bill Calculator")}
              >
                {/* BEST FIT™ SAVINGS */}
                Bill Calculator
              </Button>
            </div>
            <div
              className={
                window.location.pathname === "/international_services"
                  ? "buttonMiddleHeader activeRouteHeader"
                  : "buttonMiddleHeader"
              }
            >
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className={
                  anchorEl
                    ? "clickedHeaderTab fontStyleHeader"
                    : "fontStyleHeader"
                }
                onClick={openMenu}
              >
                Services{" "}
                {anchorEl ? (
                  <KeyboardArrowUpIcon className="ArrowUpKey" />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Button>
              <Menu
                id="simple-menu"
                className="HeaderDropdown"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl) || false}
                onClose={handleClose}
              >
                <MenuItem
                  className="menuitemHeader"
                  onClick={() =>
                    handleServicesOptions("International Services")
                  }
                >
                  International Services
                </MenuItem>
              </Menu>
            </div>
            <div
              className={
                window.location.pathname === "/buy_a_phone" ||
                window.location.pathname === "/imei-compatibility-check"
                  ? "buttonMiddleHeader activeRouteHeader"
                  : "buttonMiddleHeader"
              }
            >
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className={
                  anchorEl1
                    ? "clickedHeaderTab fontStyleHeader"
                    : "fontStyleHeader"
                }
                // onMouseOver={handleClick1}
                onClick={openMenu1}
              >
                phones{" "}
                {anchorEl1 ? (
                  <KeyboardArrowUpIcon className="ArrowUpKey" />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Button>
              <Menu
                id="simple-menu"
                className="HeaderDropdown"
                anchorEl={anchorEl1}
                keepMounted
                open={Boolean(anchorEl1)}
                MenuListProps={{ onMouseLeave: handleClose1 }}
                onClose={handleClose1}
              >
                <MenuItem
                  className="menuitemHeader"
                  onClick={() => {
                    window.open(
                      `${globalVal && globalVal.shopPhone}`,
                      "_blank"
                    );

                    sendGAEventsHandler("Buy Phone");
                  }}
                >
                  Buy a phone
                </MenuItem>
                <MenuItem
                  className="menuitemHeader"
                  onClick={() => {
                    window.open("/imei-compatibility-check");
                    sendGAEventsHandler("Bring Your Own");
                  }}
                >
                  Bring your own
                </MenuItem>
              </Menu>
            </div>

            {/*<div
                className={
                  window.location.pathname === "/giveback"
                    ? "buttonMiddleHeader activeRouteHeader"
                    : "buttonMiddleHeader"
                }
              >
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  className="fontStyleHeader"
                  // onClick={() => handleClickedMiddleButtons("Give Back")}
                >
                  Give Back
                </Button>
              </div> */}
          </div>
          <div className="arrayDivHeader otherButtons">
            {props.isMyAccount && (
              <div className={"buttonMiddleHeader"}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => navigate("/activation")}
                >
                  Activate
                </Button>
              </div>
            )}
            {/* {!props.isMyAccount && (
              <div className={"buttonMiddleHeader"}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => handleClickedMiddleButtons("Blog")}
                >
                  Blog
                </Button>
              </div>
            )} */}

            <div className="buttonMiddleHeader">
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className="fontStyleHeader"
              >
                <a
                  href={globalVal.homeInternet}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="homeinternetlink"
                >
                  HOME INTERNET
                </a>
              </Button>
            </div>

            {!props.isMyAccount && (
              <div className={"buttonMiddleHeader"}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  className="fontStyleHeader"
                  onClick={() => handleClickedMiddleButtons("Help")}
                >
                  Help
                </Button>
              </div>
            )}
            <div className={"buttonMiddleHeader1"}>
              {user && user.displayName !== "" ? (
                <div className="lastDiv1">
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    className={
                      anchorEl
                        ? "clickedHeaderTab fontStyleHeader"
                        : "fontStyleHeader"
                    }
                    // onMouseOver={handleClick3}
                    onClick={handleClick3}
                  >
                    <div className="innerLast">
                      <AccountCircleIcon />
                      <div className="name">
                        {user.displayName &&
                          user.displayName !== "" &&
                          user.displayName.split(" ")[0]}
                      </div>
                    </div>
                  </Button>
                  <Menu
                    id="simple-menu"
                    className="SimpleHeaderDropdown"
                    anchorEl={anchorEl3}
                    keepMounted
                    open={anchorEl3}
                    // MenuListProps={{ onMouseLeave: handleClose3 }}
                    onClose={handleClose3}
                  >
                    {!isShowPlan && customer.simPaid && (
                      <span>
                        <MenuItem
                          className="menuitemHeader"
                          onClick={() => {
                            setanchorEl3(null);
                            navigate("/profile");
                          }}
                          // onClick={() => navigate("/profile")}
                        >
                          PROFILE
                        </MenuItem>
                        <MenuItem
                          className="menuitemHeader"
                          // onClick={() => navigate("/settings")}
                          onClick={() => {
                            setanchorEl3(null);
                            navigate("/settings");
                          }}
                        >
                          SETTINGS
                        </MenuItem>
                      </span>
                    )}
                    <MenuItem
                      className="menuitemHeader"
                      onClick={() => handleLogout()}
                    >
                      LOGOUT
                    </MenuItem>
                  </Menu>
                </div>
              ) : (
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  className="fontStyleHeader paddingAdded"
                  onClick={() => handleClickedMiddleButtons("Sign In")}
                >
                  SIGN IN | ACTIVATE
                </Button>
              )}
            </div>
          </div>
        </div>
      </AppBar>

      <AppBar position="fixed" className="headerContentMobile">
        <div className="headerContentMobileinner">
          <div>
            <MenuIcon
              style={{
                color: "var(--primary_color)",
                width: "50px",
                height: "51px",
                padding: "0px 0px 0px 15px",
              }}
              onClick={openModal}
            />
          </div>

          <div className="logoMobile" onClick={() => navigate("/home")}>
            <img
              className="headerLogoMobile"
              src={globalVal && globalVal.logo}
              alt="header-logo"
            />
          </div>
        </div>
      </AppBar>

      <HeaderModal
        show={isModal}
        onHide={() => onHide()}
        size={"xl"}
        className="modalMainHeader"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Close className="closeIcon" onClick={() => onHide()} />
        <div className="bottomContent">
          {isShowPlan && (
            <div
              className={
                window.location.pathname === "/cell-phone-plans"
                  ? "menuItems activeRouteHeader"
                  : "menuItems"
              }
              onClick={() => handleClickedMiddleButtons("Plans")}
            >
              PLANS
            </div>
          )}
          <div
            className={
              window.location.pathname === "/bill-calculator"
                ? "menuItems activeRouteHeader"
                : "menuItems"
            }
            onClick={() => handleClickedMiddleButtons("Bill Calculator")}
          >
            BILL CALCULATOR
          </div>
          <div
            className={
              window.location.pathname === "/international_services"
                ? "menuItems dropdownL  activeRouteHeader"
                : "menuItems dropdownL"
            }
            onClick={() => setisServiceEnable(!isServiceEnable)}
          >
            SERVICES
            {isServiceEnable ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </div>
          {isServiceEnable && (
            <div
              className={
                window.location.pathname === "/international_services"
                  ? "subMenuItems  activeRouteHeader"
                  : "subMenuItems"
              }
              onClick={() => handleServicesOptions("International Services")}
            >
              International Services
            </div>
          )}
          <div
            className={
              window.location.pathname === "/buy_a_phone" ||
              window.location.pathname === "/imei-compatibility-check"
                ? "menuItems dropdownL  activeRouteHeader"
                : "menuItems dropdownL"
            }
            onClick={() => setisPhoneEnable(!isPhoneEnable)}
          >
            PHONES
            {isPhoneEnable ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </div>
          {isPhoneEnable && (
            <div>
              <div
                className={
                  window.location.pathname === "/buy_a_phone"
                    ? "subMenuItems  activeRouteHeader"
                    : "subMenuItems"
                }
                onClick={() =>
                  window.open(`${globalVal && globalVal.shopPhone}`, "_blank")
                }
              >
                Buy a phone
              </div>
              <div
                className={
                  window.location.pathname === "/imei-compatibility-check"
                    ? "subMenuItems  activeRouteHeader"
                    : "subMenuItems"
                }
                onClick={() => window.open("/imei-compatibility-check")}
              >
                Bring your own
              </div>
            </div>
          )}
          {/* <div
            className={
              window.location.pathname === "/giveback"
                ? "menuItems  activeRouteHeader"
                : "menuItems"
            }
            onClick={() => handleClickedMiddleButtons("Give Back")}
          >
            GIVE BACK
          </div> */}
          {/* {!props.isMyAccount && (
            <div
              className="menuItems greyColor"
              onClick={() => handleClickedMiddleButtons("Blog")}
            >
              BLOG
            </div>
          )} */}
          <div className="menuItems">
            <a
              href={globalVal.homeInternet}
              target="_blank"
              rel="noopener noreferrer"
              className="homeinternetlink"
            >
              HOME INTERNET
            </a>
          </div>
          {!props.isMyAccount && (
            <div
              className="menuItems greyColor"
              onClick={() => handleClickedMiddleButtons("Help")}
            >
              HELP
            </div>
          )}
          {props.isMyAccount && (
            <div
              className="menuItems greyColor"
              onClick={() => navigate("/activation")}
            >
              ACTIVATE
            </div>
          )}
          {user && user.displayName !== "" ? (
            <div>
              {!isShowPlan && customer.simPaid && (
                <>
                  <div
                    className="menuItems greyColor"
                    onClick={() => navigate("/profile")}
                  >
                    PROFILE
                  </div>
                  <div
                    className="menuItems greyColor"
                    onClick={() => navigate("/settings")}
                  >
                    SETTINGS
                  </div>
                </>
              )}
              <div
                className="menuItems greyColor"
                onClick={() => handleLogout()}
              >
                LOGOUT
              </div>
            </div>
          ) : (
            <div
              className="menuItems greyColor"
              onClick={() => handleClickedMiddleButtons("Sign In")}
            >
              SIGN IN | ACTIVATE
            </div>
          )}
        </div>
      </HeaderModal>
    </div>
  );
};

export default Header;
