import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

export function pushTrackingEventsToGAAndFB(eventInfo: { category: any; actionType: any; label?: any }, e?: any) {
    ReactGA.event({
        category: eventInfo.category,
        action: eventInfo.actionType,
        label: eventInfo.label || '',
    });

    ReactPixel.trackCustom(e, {
        category: eventInfo.category,
        eventAction: eventInfo.actionType,
        eventLabel: eventInfo.label || '',
    });
}
