import {
    TRACK_ORDER,
    TRACK_ORDER_SUCCESS,
    TRACK_ORDER_FAILURE,
    REACH_PLAN,
    REACH_PLAN_SUCCESS,
    REACH_PLAN_FAILURE,
    REFERAL,
    REFERAL_SUCCESS,
    REFERAL_FAILURE,
    REFERAL_GET,
    REFERAL_GET_SUCCESS,
    REFERAL_GET_FAILURE,
} from './actionTypes';

import { TrackOrderState, TrackOrderActions } from './types';

const initialState: TrackOrderState = {
    TrackOrder: [],
    errorTrackOrder: null,
    ReachPlan: [],
    errorReachPlan: null,
    Referal: [],
    errorReferal: null,
    ReferalGet: [],
    errorReferalGet: null,
};

export default (state = initialState, action: TrackOrderActions) => {
    switch (action.type) {
        case TRACK_ORDER:
            return {
                ...state,
            };
        case TRACK_ORDER_SUCCESS:
            return {
                ...state,
                TrackOrder: action.payload.TrackOrder,
                errorTrackOrder: null,
            };
        case TRACK_ORDER_FAILURE:
            return {
                ...state,
                TrackOrder: [],
                errorTrackOrder: action.payload.errorTrackOrder,
            };
        case REACH_PLAN:
            return {
                ...state,
            };
        case REACH_PLAN_SUCCESS:
            return {
                ...state,
                ReachPlan: action.payload.ReachPlan,
                errorReachPlan: null,
            };
        case REACH_PLAN_FAILURE:
            return {
                ...state,
                ReachPlan: [],
                errorReachPlan: action.payload.errorReachPlan,
            };
        case REFERAL:
            return {
                ...state,
            };
        case REFERAL_SUCCESS:
            return {
                ...state,
                Referal: action.payload.Referal,
                errorReferal: null,
            };
        case REFERAL_FAILURE:
            return {
                ...state,
                Referal: [],
                errorReferal: action.payload.errorReferal,
            };
        case REFERAL_GET:
            return {
                ...state,
            };
        case REFERAL_GET_SUCCESS:
            return {
                ...state,
                ReferalGet: action.payload.ReferalGet,
                errorReferalGet: null,
            };
        case REFERAL_GET_FAILURE:
            return {
                ...state,
                ReferalGet: [],
                errorReferalGet: action.payload.errorReferalGet,
            };

        default:
            return {
                ...state,
            };
    }
};
