import PlacesAutocomplete from "react-places-autocomplete";
import "./AutoComplete.scss";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TextFieldComponent from "../Textfield/TextField";
type iprops = {
  value: string;
  onChange?: (val: any) => void;
  onSelect: (val: any) => void;
  onChangeVal?: () => void;
  placeholder?: string;
  inputTitle?: string;
  className?: string;
  InputLabelProps?: any;
  disabled?: any;
  error?: boolean;
  helperText?: string;
  onBlurAuto?: any;
  autoComplete?: string;
};

const AutoComplete = (props: iprops) => {
  // const searchOptions = {
  //     location: new google.maps.LatLng(37.09024, -95.712891),
  //     radius: 2000,
  //     types: ['address'],
  // };
  return (
    <PlacesAutocomplete
      value={props.value}
      onChange={props.onChange}
      onSelect={props.onSelect}
      // searchOptions={searchOptions}
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        //  loading
      }) => (
        <div style={{ position: "relative" }} onBlur={props.onBlurAuto}>
          <TextFieldComponent
            label={props.placeholder}
            // value={props.value}
            // id="outlined-start-adornment"
            // variant="filled"
            {...getInputProps({
              // placeholder: props.placeholder,
              className: props.className ? props.className : "input1",
            })}
            error={props.error}
            helperText={props.helperText}
            disabled={props.disabled}
            autoComplete={props.autoComplete}
          />

          <div className="autocomplete-dropdown-container">
            {/* {loading && <div>Loading...</div>} */}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active
                ? "suggestion-item--active autoCompleteDropdown"
                : "suggestion-item autoCompleteDropdown";
              // inline style for demonstration purpose

              if (
                suggestion &&
                suggestion.terms &&
                suggestion.terms.length > 0 &&
                suggestion.terms
                  .map((el: any) => {
                    if (
                      el.value.toUpperCase() === "USA" ||
                      el.value.toUpperCase() === "US"
                    )
                      return true;
                    else return false;
                  })
                  .filter((item: any) => item)
                  .includes(true)
              ) {
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      // style,
                    })}
                    key={index}
                  >
                    <span>
                      <LocationOnIcon className="locationIcon" />
                    </span>
                    <span className="MainText">
                      {suggestion.formattedSuggestion &&
                        suggestion.formattedSuggestion.mainText}
                      {/* {suggestion.description} */}
                    </span>
                    <span className="SecondaryText">
                      {suggestion.formattedSuggestion &&
                        suggestion.formattedSuggestion.secondaryText}
                    </span>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AutoComplete;
