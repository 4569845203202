import { Close } from "@mui/icons-material";
import { Modal } from "@mui/material";

import "./Modal.scss";

type iprops = {
  isShowModal: boolean;
  onCloseModal: any;
  showClose: boolean;
  children: any;
  style?: any;
  preview?: boolean;
  borderRadius?: string;
  width?: string;
  padding?: string;
  left?: string;
  top?: string;
  className?: any;
  styleIcon?: any;
  icon?: any;
};

export default function SimpleModal(props: iprops) {
  return (
    <div>
      <Modal
        open={props.isShowModal}
        onClose={props.onCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onBackdropClick={props.onCloseModal}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableRestoreFocus={true}
        className="Modal"
      >
        <div
          style={props.style ? props.style : { position: "absolute" }}
          className={props.className ? props.className : "ModalData"}
        >
          {props.showClose && (
            <div
              className="close"
              onClick={props.onCloseModal}
              style={props.styleIcon ? props.styleIcon : {}}
            >
              {props.icon ? (
                <img src={props.icon} width="12px" alt={""} />
              ) : (
                <Close
                  style={{ color: "#000000", opacity: "0.8", fontSize: "20px" }}
                />
              )}
            </div>
          )}
          {props.children}
        </div>
      </Modal>
    </div>
  );
}
