import Modal from "../../Modal/Modal";
import SuccessTickMark from "../../../assets/images/Home/successTickmark.png";
import ButtonNew from "../../Button/Button";
import { Link } from "react-router-dom";
import "./WowDiscount.scss";
import { CircularProgress } from "@mui/material";

type Props = {
  isShowModal: boolean;
  onModalClose: any;
  wowEligibility: any;
  proceedToCheckoutHandler?: any;
  isPostActive?: any;
  continueHandler?: any;
  wowCustomerData?: any;
  isCheckEligibilityClicked?: any;
};

const WowDiscountSuccess = (props: Props) => {
  const {
    isShowModal,
    onModalClose,
    wowEligibility,
    proceedToCheckoutHandler,
    isPostActive,
    continueHandler,
    wowCustomerData,
    isCheckEligibilityClicked,
  } = props;
  return (
    <>
      <Modal
        isShowModal={isShowModal}
        onCloseModal={onModalClose}
        showClose={false}
        className="compatibilityPlanBring1"
      >
        <div className="p-3 text-center">
          <img src={SuccessTickMark} alt="success_tick_mark" width={30} />
          <h5>Congrats!</h5>
          <h6 className="text-secondary mt-3">
            {`${
              isPostActive
                ? "You’re eligible for plan discounts as an active, residential WOW! Internet customer."
                : "You're eligible for plan discounts as an active, residential WOW! Internet customer."
            }`}
          </h6>
          {isPostActive ? (
            <span className="my-3">
              <ButtonNew
                primary={true}
                className={"eligibility-check-btn"}
                onClick={() => continueHandler()}
              >
                {isCheckEligibilityClicked ? (
                  <CircularProgress className="loaderCheck" color="secondary" />
                ) : (
                  "Continue"
                )}
              </ButtonNew>
            </span>
          ) : (
            <span className="my-3">
              {/* <Link
                to={"/checkout"}
                state={{
                  ecid: wowEligibility.ecid,
                  wowCustomerData: wowCustomerData,
                }}
                style={{
                  color: "var(--white)",
                  textDecoration: "none",
                }}
              > */}
              <ButtonNew
                primary={true}
                className={"eligibility-check-btn"}
                onClick={() => {
                  proceedToCheckoutHandler("success", {
                    ecid: wowEligibility.ecid,
                    wowCustomerData: wowCustomerData,
                  });
                }}
              >
                {isCheckEligibilityClicked ? (
                  <CircularProgress className="loaderCheck" color="secondary" />
                ) : (
                  "Continue"
                )}
              </ButtonNew>
              {/* </Link> */}
            </span>
          )}
        </div>
      </Modal>
    </>
  );
};

export default WowDiscountSuccess;
