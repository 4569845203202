import {
  GET_STATE_CITY_BY_ZIPCODE,
  GET_STATE_CITY_BY_ZIPCODE_SUCCESS,
  GET_STATE_CITY_BY_ZIPCODE_FAILURE,
  TAX_CALCULATION_BY_ADDRESS_TYPE1,
  TAX_CALCULATION_BY_ADDRESS_TYPE1_SUCCESS,
  TAX_CALCULATION_BY_ADDRESS_TYPE1_FAILURE,
  TAX_CALCULATION_BY_ADDRESS_TYPE2,
  TAX_CALCULATION_BY_ADDRESS_TYPE2_SUCCESS,
  TAX_CALCULATION_BY_ADDRESS_TYPE2_FAILURE,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAILURE,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  RESET_CUSTOMER,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_SOURCE,
  UPDATE_CUSTOMER_SOURCE_SUCCESS,
  UPDATE_CUSTOMER_SOURCE_FAILURE,
  ELIGIBILITY,
  ELIGIBILITY_SUCCESS,
  ELIGIBILITY_FAILURE,
  COUPON,
  COUPON_SUCCESS,
  COUPON_FAILURE,
  PAYMENT,
  PAYMENT_SUCCESS,
  PAYMENT_FAILURE,
  PAYMENT_GET,
  PAYMENT_GET_SUCCESS,
  PAYMENT_GET_FAILURE,
  COUPON_RESERVE,
  COUPON_RESERVE_SUCCESS,
  COUPON_RESERVE_FAILURE,
  UPDATE_SECONDARY_CUSTOMER_SUCCESS,
  UPDATE_SECONDARY_CUSTOMER_FAILURE,
  UPDATE_SECONDARY_CUSTOMER,
  CHECK_EMAIL_AVAILBLITY,
  CHECK_EMAIL_AVAILBLITY_SUCCESS,
  CHECK_EMAIL_AVAILBLITY_FAILURE,
  LOGIN_NUMBER,
  LOGIN_NUMBER_SUCCESS,
  LOGIN_NUMBER_FAILURE,
  SHIPPING_GET_DATA,
  SHIPPING_SUCCESS,
  SHIPPING_FAILURE,
  GET_SHIPMENT_ORDER_BY_ID,
  GET_SHIPMENT_ORDER_BY_ID_SUCCESS,
  GET_SHIPMENT_ORDER_BY_ID_FAILURE,
  HSD_ADD,
  HSD_ADD_SUCCESS,
  HSD_ADD_FAILURE,
} from "./actionTypes";
import * as types from "./types";

// get city.state by zipcode
export const StateCityByZipcode = (
  payload: types.StateCityByZipcodeRequestPayload
): types.StateCityByZipcodeRequest => ({
  type: GET_STATE_CITY_BY_ZIPCODE,
  payload,
});

export const StateCityByZipcodeSuccess = (
  payload: types.StateCityByZipcodeSuccessPayload
): types.StateCityByZipcodeSuccess => ({
  type: GET_STATE_CITY_BY_ZIPCODE_SUCCESS,
  payload,
});

export const StateCityByZipcodeFailure = (
  payload: types.StateCityByZipcodeFailurePayload
): types.StateCityByZipcodeFailure => ({
  type: GET_STATE_CITY_BY_ZIPCODE_FAILURE,
  payload,
});

// Calculate tax by address type 1
export const TaxCalculationByAddressType1 = (
  payload: types.TaxCalculationByAddressType1RequestPayload
): types.TaxCalculationByAddressType1Request => ({
  type: TAX_CALCULATION_BY_ADDRESS_TYPE1,
  payload,
});

export const TaxCalculationByAddressType1Success = (
  payload: types.TaxCalculationByAddressType1SuccessPayload
): types.TaxCalculationByAddressType1Success => ({
  type: TAX_CALCULATION_BY_ADDRESS_TYPE1_SUCCESS,
  payload,
});

export const TaxCalculationByAddressType1Failure = (
  payload: types.TaxCalculationByAddressType1FailurePayload
): types.TaxCalculationByAddressType1Failure => ({
  type: TAX_CALCULATION_BY_ADDRESS_TYPE1_FAILURE,
  payload,
});

// Calculate tax by address type 2
export const SetTaxCalculationByAddressType2 = (payload: any) => ({
  type: TAX_CALCULATION_BY_ADDRESS_TYPE2,
  payload,
});

export const TaxCalculationByAddressType2Success = (
  payload: types.TaxCalculationByAddressType2SuccessPayload
): types.TaxCalculationByAddressType2Success => ({
  type: TAX_CALCULATION_BY_ADDRESS_TYPE2_SUCCESS,
  payload,
});

export const TaxCalculationByAddressType2Failure = (
  payload: types.TaxCalculationByAddressType2FailurePayload
): types.TaxCalculationByAddressType2Failure => ({
  type: TAX_CALCULATION_BY_ADDRESS_TYPE2_FAILURE,
  payload,
});

// Create Customer
export const CreateCustomer = (
  payload: types.CreateCustomerRequestPayload
): types.CreateCustomerRequest => ({
  type: CREATE_CUSTOMER,
  payload,
});

export const CreateCustomerSuccess = (
  payload: types.CreateCustomerSuccessPayload
): types.CreateCustomerSuccess => ({
  type: CREATE_CUSTOMER_SUCCESS,
  payload,
});

export const CreateCustomerFailure = (
  payload: types.CreateCustomerFailurePayload
): types.CreateCustomerFailure => ({
  type: CREATE_CUSTOMER_FAILURE,
  payload,
});

// get Customer by username
export const GetCustomer = (
  payload: types.GetCustomerRequestPayload
): types.GetCustomerRequest => ({
  type: GET_CUSTOMER,
  payload,
});

// get Customer by username
export const ResetCustomer = () => ({
  type: RESET_CUSTOMER,
});

export const GetCustomerSuccess = (
  payload: types.GetCustomerSuccessPayload
): types.GetCustomerSuccess => ({
  type: GET_CUSTOMER_SUCCESS,
  payload,
});

export const GetCustomerFailure = (
  payload: types.GetCustomerFailurePayload
): types.GetCustomerFailure => ({
  type: GET_CUSTOMER_FAILURE,
  payload,
});

// get shipment order by id
export const GetShipmentOrderById = (
  payload: types.GetShipmentOrderByIdRequestPayload
): types.GetShipmentOrderByIdRequest => ({
  type: GET_SHIPMENT_ORDER_BY_ID,
  payload,
});

export const GetShipmentOrderByIdSuccess = (
  payload: types.GetShipmentOrderByIdSuccessPayload
): types.GetShipmentOrderByIdSuccess => ({
  type: GET_SHIPMENT_ORDER_BY_ID_SUCCESS,
  payload,
});

export const GetShipmentOrderByIdFailure = (
  payload: types.GetShipmentOrderByIdFailurePayload
): types.GetShipmentOrderByIdFailure => ({
  type: GET_SHIPMENT_ORDER_BY_ID_FAILURE,
  payload,
});

// check email availbilty
export const checkEmailAvailability = (
  payload: types.CheckEmailAvailabilityRequestPayload
): types.CheckEmailAvailabilityRequest => ({
  type: CHECK_EMAIL_AVAILBLITY,
  payload,
});

export const checkEmailAvailabilitySuccess = (
  payload: types.CheckEmailAvailabilitySuccessPayload
): types.CheckEmailAvailabilitySuccess => ({
  type: CHECK_EMAIL_AVAILBLITY_SUCCESS,
  payload,
});

export const checkEmailAvailabilityFailure = (
  payload: types.CheckEmailAvailabilityFailurePayload
): types.CheckEmailAvailabilityFailure => ({
  type: CHECK_EMAIL_AVAILBLITY_FAILURE,
  payload,
});

// Update Customer
export const UpdateCustomer = (
  payload: types.UpdateCustomerRequestPayload
): types.UpdateCustomerRequest => ({
  type: UPDATE_CUSTOMER,
  payload,
});

export const UpdateSecondaryCustomer = (
  payload: types.UpdateSecondaryCustomerRequestPayload
): types.UpdateSecondaryCustomerRequest => ({
  type: UPDATE_SECONDARY_CUSTOMER,
  payload,
});

export const UpdateCustomerSuccess = (
  payload: types.UpdateCustomerSuccessPayload
): types.UpdateCustomerSuccess => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload,
});

export const UpdateCustomerFailure = (
  payload: types.UpdateCustomerFailurePayload
): types.UpdateCustomerFailure => ({
  type: UPDATE_CUSTOMER_FAILURE,
  payload,
});

export const UpdateSecondaryCustomerSuccess = (
  payload: types.UpdateSecondaryCustomerSuccessPayload
): types.UpdateSecondaryCustomerSuccess => ({
  type: UPDATE_SECONDARY_CUSTOMER_SUCCESS,
  payload,
});

export const UpdateSecondaryCustomerFailure = (
  payload: types.UpdateSecondaryCustomerFailurePayload
): types.UpdateSecondaryCustomerFailure => ({
  type: UPDATE_SECONDARY_CUSTOMER_FAILURE,
  payload,
});

// Update Customer source
export const UpdateCustomerSource = (
  payload: types.UpdateCustomerSourceRequestPayload
): types.UpdateCustomerSourceRequest => ({
  type: UPDATE_CUSTOMER_SOURCE,
  payload,
});

export const UpdateCustomerSourceSuccess = (
  payload: types.UpdateCustomerSourceSuccessPayload
): types.UpdateCustomerSourceSuccess => ({
  type: UPDATE_CUSTOMER_SOURCE_SUCCESS,
  payload,
});

export const UpdateCustomerSourceFailure = (
  payload: types.UpdateCustomerSourceFailurePayload
): types.UpdateCustomerSourceFailure => ({
  type: UPDATE_CUSTOMER_SOURCE_FAILURE,
  payload,
});

// get coupon eligibility
export const Eligibility = (
  payload: types.EligibilityRequestPayload
): types.EligibilityRequest => ({
  type: ELIGIBILITY,
  payload,
});

export const EligibilitySuccess = (
  payload: types.EligibilitySuccessPayload
): types.EligibilitySuccess => ({
  type: ELIGIBILITY_SUCCESS,
  payload,
});

export const EligibilityFailure = (
  payload: types.EligibilityFailurePayload
): types.EligibilityFailure => ({
  type: ELIGIBILITY_FAILURE,
  payload,
});

// check coupon code
export const Coupon = (
  payload: types.CouponRequestPayload
): types.CouponRequest => ({
  type: COUPON,
  payload,
});

export const CouponSuccess = (
  payload: types.CouponSuccessPayload
): types.CouponSuccess => ({
  type: COUPON_SUCCESS,
  payload,
});

export const CouponFailure = (
  payload: types.CouponFailurePayload
): types.CouponFailure => ({
  type: COUPON_FAILURE,
  payload,
});
// payment post api
export const Payment = (
  payload: types.PaymentRequestPayload
): types.PaymentRequest => ({
  type: PAYMENT,
  payload,
});

export const PaymentSuccess = (
  payload: types.PaymentSuccessPayload
): types.PaymentSuccess => ({
  type: PAYMENT_SUCCESS,
  payload,
});

export const PaymentFailure = (
  payload: types.PaymentFailurePayload
): types.PaymentFailure => ({
  type: PAYMENT_FAILURE,
  payload,
});
// payment get api
export const PaymentGet = (
  payload: types.PaymentGetRequestPayload
): types.PaymentGetRequest => ({
  type: PAYMENT_GET,
  payload,
});

export const PaymentGetSuccess = (
  payload: types.PaymentGetSuccessPayload
): types.PaymentGetSuccess => ({
  type: PAYMENT_GET_SUCCESS,
  payload,
});

export const PaymentGetFailure = (
  payload: types.PaymentGetFailurePayload
): types.PaymentGetFailure => ({
  type: PAYMENT_GET_FAILURE,
  payload,
});
// coupon reserve
export const CouponReserve = (
  payload: types.CouponReservePayload
): types.CouponReserve => ({
  type: COUPON_RESERVE,
  payload,
});

export const CouponReserveSuccess = (
  payload: types.CouponReserveSuccessPayload
): types.CouponReserveSuccess => ({
  type: COUPON_RESERVE_SUCCESS,
  payload,
});

export const CouponReserveFailure = (
  payload: types.CouponReserveFailurePayload
): types.CouponReserveFailure => ({
  type: COUPON_RESERVE_FAILURE,
  payload,
});

// login with number
export const LoginNumber = (
  payload: types.LoginNumberPayload
): types.LoginNumber => ({
  type: LOGIN_NUMBER,
  payload,
});

export const LoginNumberSuccess = (
  payload: types.LoginNumberSuccessPayload
): types.LoginNumberSuccess => ({
  type: LOGIN_NUMBER_SUCCESS,
  payload,
});

export const LoginNumberFailure = (
  payload: types.LoginNumberFailurePayload
): types.LoginNumberFailure => ({
  type: LOGIN_NUMBER_FAILURE,
  payload,
});

// Shipping Data
export const ShippingGetData = (
  payload: types.ShippingDataRequestPayload
): types.ShippingDataRequest => ({
  type: SHIPPING_GET_DATA,
  payload,
});

export const ShippingSuccess = (
  payload: types.ShippingSuccessPayload
): types.ShippingSuccess => ({
  type: SHIPPING_SUCCESS,
  payload,
});

export const ShippingFailure = (
  payload: types.ShippingFailurePayload
): types.ShippingFailure => ({
  type: SHIPPING_FAILURE,
  payload,
});

// HSD add

export const HsdAddSuccess = (payload: any) => ({
  type: HSD_ADD_SUCCESS,
  payload,
});

export const HsdAddFailure = (payload: any) => ({
  type: HSD_ADD_FAILURE,
  payload,
});
