export const REACT_PLANS = "REACT_PLANS";
export const REACT_PLANS_SUCCESS = "REACT_PLANS_SUCCESS";
export const REACT_PLANS_FAILURE = "REACT_PLANS_FAILURE";

export const COVERAGE_PLANS = "COVERAGE_PLANS";
export const COVERAGE_PLANS_SUCCESS = "COVERAGE_PLANS_SUCCESS";
export const COVERAGE_PLANS_FAILURE = "COVERAGE_PLANS_FAILURE";

export const GET_IN_TOUCH = "GET_IN_TOUCH";
export const GET_IN_TOUCH_SUCCESS = "GET_IN_TOUCH_SUCCESS";
export const GET_IN_TOUCH_FAILURE = "GET_IN_TOUCH_FAILURE";

export const EARTHLINK_REACH_PLAN = "EARTHLINK_REACH_PLAN";
export const EARTHLINK_REACH_PLAN_SUCCESS = "EARTHLINK_REACH_PLAN_SUCCESS";
export const EARTHLINK_REACH_PLAN_FAILURE = "EARTHLINK_REACH_PLAN_FAILURE";

export const HIDE_LOADER = "HIDE_LOADER";
export const RESET_GET_IN_TOUCH = "RESET_GET_IN_TOUCH";

export const CHECK_COVERAGE = "CHECK_COVERAGE";
export const CHECK_COVERAGE_SUCCESS = "CHECK_COVERAGE_SUCCESS";
export const CHECK_COVERAGE_FAILURE = "CHECK_COVERAGE_FAILURE";

export const INVITE_REFEREE_COUPON = "INVITE_REFEREE_COUPON";
export const INVITE_REFEREE_COUPON_SUCCESS = "INVITE_REFEREE_COUPON_SUCCESS";
export const INVITE_REFEREE_COUPON_FAILURE = "INVITE_REFEREE_COUPON_FAILURE";
export const WOW_ELIGIBILITY_SUCCESS = "WOW_ELIGIBILITY_SUCCESS";
export const WOW_ELIGIBILITY_FAILURE = "WOW_ELIGIBILITY_FAILURE";
export const DISCOUNT_APPLIED_SUCCESS = "DISCOUNT_APPLIED_SUCCESS";
export const DISCOUNT_APPLIED_FAILURE = "DISCOUNT_APPLIED_FAILURE";
