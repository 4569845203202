import { globalVal } from "../../globalvalues";

export const bringYourPhone: any = {
  OverLay: "Check Phone Compatibility",
  compatibilityPlanTitle: "Check your device compatibility",
  accordionTitle:
    "Have questions about device compatibility? Check our FAQs below:",
  accordion: [
    {
      label: `What is an IMEI number? What is an MEID number?`,
      description: `To find your device IMEI (a 15-digit identifying code), you can dial *#06# from the call app. For iPhones, go to Settings, General, About, then scroll to find IMEI. For Android devices, go to Settings, then About Phone, then Status. IMEI is International Mobile
             Equipment Identity, which is like a fingerprint for your phone. It helps us identify your device make and model.`,
    },
    // {
    //     label: 'How do I find out if my new phone is compatible with a carrier?',
    //     description: `To see if your device is compatible, check out our <a style="color:#3a8580" href='https://learn.reachmobile.com/is-my-device-compatible' target="_blank">IMEI checker</a>. Need help finding your IMEI? <a style="color:#3a8580" href='https://learn.reachmobile.com/how-do-i-find-my-imei-what-is-an-imei'  target="_blank"> Click here!</a> <br>
    //     Device isn't compatible? We'd love to help you find a resolution, so here are some alternate options: <br>
    //     1) Visit <a style="color:#3a8580" href="http://shop.reachmobile.com/phones" target="_blank">shop.reachmobile.com</a> to shop for unlocked and compatible new or refurbished phones <br>
    //     2) Do you have another 4G device available to check? Don't we all have a phone or two (nevermind the cracked screen…) hanging out in a junk drawer somewhere? This could be a reasonable short-term fix. <br>
    //     3) Check out our <a style="color:#3a8580" href="https://reachconnection.reachmobile.com/device-purchasing-guide" target="_blank">Device Buying Guide</a> for helpful tips on buying a new or refurbished device from a reliable third party.`,
    // },
    // {
    //     label: 'Will there be any interruption in my service when I switch?',
    //     description: `Whenever technology is involved, there is a chance for things to get wonky. But in most cases, your service
    //     will not be interrupted when joining ${globalVal && globalVal.short_name}.`,
    // },
    // {
    //     label: 'What plans are available when I bring my own phone?',
    //     description: '',
    // },
    // {
    //     label: 'Why isn’t my phone compatible?',
    //     description: '',
    // },
    // {
    //     label: 'Why do you need my phone’s IMEI number?',
    //     description: '',
    // },
    {
      label: `My phone is compatible, but how do I know if my device is unlocked?`,
      description: `For your phone to work on <b> ${
        globalVal && globalVal.short_name
      }</b>, it needs to check three boxes:<br/>1) compatible with the network <br/> 2) paid in full <br/> 3) unlocked <br/><strong>Make sure your phone is paid in full:</strong><br/>After you've verified compatibility with our network, you need to determine if your device is paid off? If not, you'll need to finish paying off your phone before switching to  <b> ${
        globalVal && globalVal.short_name
      }</b>. <br/><strong>Ensure your device is unlocked:</strong><br/>You'll know your device is unlocked if you specifically purchased an unlocked device or if the phone that you're using was unlocked in the past (due to switching from another network). If you're unsure if your device is unlocked (or if you think it is but want to be 100% certain), you can always contact your carrier and ask them to unlock your phone. It can take up to 2-3 days for the unlock to be successful.`,
    },
  ],
  shareButtonText: {
    url: `https://reachmobile.com`,
    quote: `Activate, and use code REACH103 to get $10 off your service for the first three months when you join @reach4Good `,
    hashtag: `reach4good`,
    subject: `You’re invited to Reach Mobile`,
  },
};
