import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DiscoverSectionWow from "../../components/HomeComponents/DiscoverSectionWow";
import HomePageBannerWow from "../../components/HomeComponents/HomePageBannerWow";
import HomeSpotlightCards from "../../components/HomeComponents/HomeSpotlightCards";
import { homePageWow } from "../../global/texts/Homepage";
import { CheckoutService } from "../../services/checkout.service";
import CheckCoverage from "./CheckCoverage/CheckCoverage";
import "./HomePage.scss";
// import TrustPilot from "../../components/TrustPilot";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Store } from "react-notifications-component";
import HomeInternetSection from "../../components/HomeComponents/HomeInternetSection";
import LineSelect from "../../components/HomeComponents/LineSelect";
import { NotificationComponent } from "../../components/NotificationComponent/NotificationComponent";
import StillExporing from "../../components/StillExploring/StillExporing";
import planData from "../../data.json";
import { pushTrackingEventsToGAAndFB } from "../../global/commonFunctions/GaAndFb";
import { globalVal } from "../../global/globalvalues";
import { seoData } from "../../global/seoData";
import { discountAppliedFailure } from "../../redux/HomePage/actions";
import { HomeService } from "../../services/home.service";
import firebase from "../../utils/firebase";
import wowDiscountCoupons from "../../wowDiscountCoupons.json";

type Props = {};

// export function MessageComp() {
//   return (
//     <>
//       <div className="reach-notify">
//         Our payment partner is under maintenance.{" "}
//         <a
//           href="https://share.hsforms.com/1PA52eHJEQ8Ktb3frNlTKNwcyvh1"
//           target="_blank"
//           className="link-notify"
//         >
//           Click here
//         </a>{" "}
//         to share your contact information and our Customer Support team will
//         contact you.
//       </div>
//     </>
//   );
// }
const HomePage: React.FC = (props: Props) => {
  const location = useLocation();
  const { getCustomer } = useSelector((state: any) => state.checkout);
  const {
    wowEligibility,
    wowEligibilityError,
    discountApplied,
    discountAppliedError,
  } = useSelector((state: any) => state.home);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let currentuser: any = localStorage.getItem("currentuser");
  currentuser = JSON.parse(currentuser);
  const [wowDiscountModal, setWowDiscountModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;
  const initialWowCustomerData = {
    wowAccountNumber: "",
    wowAccountServiceZipcode: "",
    wowCustomerLastName: "",
    // wowCustomerEmail: "",
  };
  const [wowCustomerData, setWowCustomerData] = useState(
    initialWowCustomerData
  );
  const [isCheckEligibilityClicked, setIsCheckEligibilityClicked] =
    useState(false);

  const [wowDiscountSuccessModal, setWowDiscountSuccessModal] = useState(false);
  const [wowDiscountUnsuccessModal, setWowDiscountUnsuccessModal] =
    useState(false);
  const [
    wowDiscountUnsuccessModalWithMatchFound,
    setWowDiscountUnsuccessModalWithMatchFound,
  ] = useState(false);
  const [wowEligibilityCheckCounter, setWowEligibilityCheckCounter] =
    useState(0);
  const [formDataValidation, setformDataValidation] = useState(false);
  const [wowUnlimitedPlanResponse, setWowUnlimitedPlanResponse] =
    useState<any>(null);
  const [wowNormalPlanResponse, setWowNormalPlanResponse] = useState<any>(null);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [wowDataObject, setWowDataObject] = useState<any>(null);

  const initialRender1 = useRef(true);
  const initialRender2 = useRef(true);

  useEffect(() => {
    pushTrackingEventsToGAAndFB({
      category: "Home",
      actionType: "Home page",
    });
    if (
      !(
        localStorage.getItem("accessToken") &&
        localStorage.getItem("currentuser")
      )
    ) {
      firebase
        .auth()
        .signInAnonymously()
        .then(async (user) => {
          await localStorage.setItem(
            "currentuser",
            JSON.stringify(user && user.user)
          );
          let user1: any = await localStorage.getItem("currentuser");
          user1 = JSON.parse(user1);
          let token =
            user1 &&
            user1.uid &&
            user1.stsTokenManager &&
            user1.stsTokenManager.accessToken;
          await localStorage.setItem("accessToken", token);
        })
        .catch((error) => {
          console.error(error);
          // ...
        });
    }

    if (currentuser && !currentuser.isAnonymous && currentuser.email) {
      dispatch(
        CheckoutService.getCustomerApi({
          username: encodeURIComponent(currentuser && currentuser.email),
        })
      );
    }

    // Store.addNotification({
    //   content: MessageComp,
    //   type: "success",
    //   insert: "top",
    //   container: "top-full",
    //   animationIn: ["animate__animated", "animate__fadeIn"],
    //   animationOut: ["animate__animated", "animate__fadeOut"],
    //   dismiss: {
    //     duration: 0,
    //     showIcon: false,
    //     click: false,
    //     touch: false,
    //   },
    //   id: "HomeNotification222",
    // });
  }, []);

  useEffect(() => {
    setWowNormalPlanResponse(wowDiscountCoupons?.regular);
    setWowUnlimitedPlanResponse(wowDiscountCoupons?.unlimited);
    let currentuser: any = localStorage.getItem("currentuser");
    currentuser = JSON.parse(currentuser);
    if (!currentuser?.isAnonymous) {
      // let lastName = currentuser?.displayName?.split(" ")[1];
      let splittedNames = currentuser?.displayName?.split(" ");
      let lastName = splittedNames?.slice(1)?.join(" ");

      setWowCustomerData((prevProps) => ({
        ...prevProps,
        wowCustomerLastName: lastName,
        // wowCustomerEmail: currentuser?.email,
      }));
    } else {
      setWowCustomerData({
        ...initialWowCustomerData,
      });
    }
  }, []);

  useEffect(() => {
    if (getCustomer && getCustomer.length !== 0) {
      if (getCustomer.data.status === 0 && getCustomer.data.simPaid) {
        navigate("/my-account", { state: getCustomer.data });
      } else if (
        getCustomer.data.status === 0 &&
        !getCustomer.data.simPaid &&
        getCustomer.data?.isPrimary
      ) {
        setTimeout(() => {
          navigate("/checkout", { state: getCustomer.data });
        }, 2000);
      } else if (
        getCustomer.data.status == 1 ||
        getCustomer.data.status == 2 ||
        getCustomer.data.status == 3 ||
        getCustomer.data.status == 4 ||
        getCustomer.data.status == 5 ||
        getCustomer.data.status == 8 ||
        getCustomer.data.status == 9 ||
        getCustomer.data.status == 10
        // getCustomer.data.status !== 11
        // &&
        // getCustomer.data.shipmentInfo &&
        // Object.keys(getCustomer.data.shipmentInfo).length > 0
      ) {
        navigate("/post-activation-home");
      }
    }
  }, [getCustomer]);

  useEffect(() => {
    if (initialRender1.current) {
      initialRender1.current = false;
    } else {
      if (wowEligibility && wowEligibility.status === 200) {
        setIsCheckEligibilityClicked(false);
        setWowDiscountModal(false);
        setWowDiscountSuccessModal(true);
      }
      if (wowEligibility && wowEligibility.status === 206) {
        setIsCheckEligibilityClicked(false);
        setWowDiscountModal(false);
        setWowDiscountUnsuccessModalWithMatchFound(true);
      }
    }
  }, [wowEligibility]);

  useEffect(() => {
    if (initialRender2.current) {
      initialRender2.current = false;
    } else {
      if (
        (wowEligibilityError && wowEligibilityError.status === 404) ||
        (wowEligibilityError && wowEligibilityError.status === 504)
      ) {
        setIsCheckEligibilityClicked(false);
        setWowDiscountModal(false);
        // setIsWowExistingCustomer(false);
        setWowDiscountUnsuccessModal(true);
        setWowEligibilityCheckCounter(wowEligibilityCheckCounter + 1);
      } else {
        setIsCheckEligibilityClicked(false);
        setWowDiscountModal(false);
        NotificationComponent({
          message: "Something went wrong, please try again",
          type: "danger",
          duration: 2000,
          id: "EligibilityError",
        });
      }
    }
  }, [wowEligibilityError]);

  // const initialRender3 = useRef(true);
  // useEffect(() => {
  //   if (initialRender3.current) {
  //     initialRender3.current = false;
  //   } else {
  //     if ((discountApplied && discountApplied.status) === "SUCCESS") {
  //       localStorage.setItem("isWowDiscountEligible", "true");
  //       localStorage.setItem(
  //         "wowUserDetails",
  //         JSON.stringify({
  //           userInfo: wowCustomerData,
  //           isUnlimitedPlan: selectedPlan?.isUnlimited,
  //         })
  //       );
  //       localStorage.setItem("reachSelectedPlanLine", "1");
  //       localStorage.setItem(
  //         "reachSelectedPlanDetail",
  //         JSON.stringify({
  //           line: "1",
  //           plan: selectedPlan,
  //         })
  //       );
  //       setIsCheckEligibilityClicked(false);
  //       setWowDiscountSuccessModal(false);
  //       setWowDiscountUnsuccessModalWithMatchFound(false);
  //       dispatch(discountAppliedSuccess({ discountApplied: null }));
  //       navigate("/checkout", {
  //         state: { ...wowDataObject, discountApplied: true },
  //       });
  //     }
  //   }
  // }, [discountApplied]);

  const initialRender4 = useRef(true);

  useEffect(() => {
    if (initialRender4.current) {
      initialRender4.current = false;
    } else {
      if (
        (discountAppliedError && discountAppliedError.status) === 404 ||
        (discountAppliedError &&
          discountAppliedError.data &&
          discountAppliedError.data.status) === "FAILURE"
      ) {
        localStorage.setItem("isWowDiscountEligible", "false");
        localStorage.setItem(
          "wowUserDetails",
          JSON.stringify({
            userInfo: wowCustomerData,
            isUnlimitedPlan: selectedPlan?.isUnlimited,
          })
        );
        localStorage.setItem("reachSelectedPlanLine", "1");
        localStorage.setItem(
          "reachSelectedPlanDetail",
          JSON.stringify({
            line: "1",
            plan: selectedPlan,
          })
        );
        setIsCheckEligibilityClicked(false);
        setWowDiscountSuccessModal(false);
        setWowDiscountUnsuccessModalWithMatchFound(false);
        NotificationComponent({
          message:
            "You have already applied a discount coupon on your account. Only one discount coupon can be redeemed.",
          type: "danger",
          duration: 0,
          id: "EligibilityError1",
        });

        dispatch(
          discountAppliedFailure({
            discountAppliedError: null,
          })
        );
        navigate("/checkout", {
          state: { ...wowDataObject, discountApplied: false },
        });
      }
    }
  }, [discountAppliedError]);

  const handleInputChange = (e: any) => {
    setWowCustomerData((prevProps) => ({
      ...prevProps,
      [e.target.name]: e.target.value,
    }));
  };

  const validateData = (formData: any) => {
    if (
      formData.wowAccountNumber === "" ||
      formData.wowAccountServiceZipcode === "" ||
      formData.wowCustomerLastName === ""
      // formData.wowCustomerEmail === ""
    ) {
      return false;
    }
    if (
      formData.wowAccountNumber !== "" &&
      formData.wowAccountNumber.length !== 4
      // &&
      // !/^\d+$/.test(formData.wowAccountNumber)
    ) {
      return false;
    }
    if (
      formData.wowAccountServiceZipcode !== "" &&
      formData.wowAccountServiceZipcode.length !== 5
      // &&
      // !/^\d+$/.test(formData.wowAccountServiceZipcode)
    ) {
      return false;
    }

    // if (!validateEmail(formData.wowCustomerEmail)) {
    //   return false;
    // }

    return true;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setformDataValidation(true);

    if (validateData(wowCustomerData)) {
      setIsCheckEligibilityClicked(true);

      let data = {
        // emailId: wowCustomerData.wowCustomerEmail,
        zipCode: wowCustomerData.wowAccountServiceZipcode,
        accountNum: wowCustomerData.wowAccountNumber,
        lastName: wowCustomerData.wowCustomerLastName,
        isUnlimited: selectedPlan.isUnlimited,
      };
      dispatch(HomeService.wowEligibilityApi({ data }));
      setformDataValidation(false);
      pushTrackingEventsToGAAndFB({
        category: "EligibilityCheckPopUp",
        actionType: "CheckEligibility",
      });
    }
  };

  const handleModalMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    pushTrackingEventsToGAAndFB({
      category: "EligibilityCheckPopUp",
      actionType: "CheckEligibilityAccountNumberHelp",
    });
  };

  const handleCloseModelMenu = () => {
    setAnchorEl(null);
  };

  const handleOnPlanSelect = (data: any) => {
    setSelectedPlan(data);
  };

  // const proceedToCheckoutHandler = (data: string, wowObject?: any) => {
  //   if (data === "success") {
  //     // localStorage.setItem("isWowDiscountEligible", "true");
  //     // localStorage.setItem(
  //     //   "wowUserDetails",
  //     //   JSON.stringify({
  //     //     userInfo: wowCustomerData,
  //     //     isUnlimitedPlan: selectedPlan.isUnlimited,
  //     //   })
  //     // );
  //     // localStorage.setItem("reachSelectedPlanLine", "1");
  //     // localStorage.setItem(
  //     //   "reachSelectedPlanDetail",
  //     //   JSON.stringify({
  //     //     line: "1",
  //     //     plan: selectedPlan,
  //     //   })
  //     // );
  //     // setWowDiscountSuccessModal(false);
  //     setIsCheckEligibilityClicked(true);
  //     setWowDataObject(wowObject);
  //     let data = {
  //       wowCustomerId: wowObject.ecid,
  //     };
  //     dispatch(HomeService.discountAppliedApi(data));
  //     pushTrackingEventsToGAAndFB({
  //       category: "EligibilityCheckPopUp",
  //       actionType: "CheckEligibilitySuccess",
  //     });
  //   } else if (data === "skip") {
  //     localStorage.setItem("isWowDiscountEligible", "false");
  //     localStorage.removeItem("wowUserDetails");
  //     localStorage.setItem("reachSelectedPlanLine", "1");
  //     localStorage.setItem(
  //       "reachSelectedPlanDetail",
  //       JSON.stringify({
  //         line: "1",
  //         plan: selectedPlan,
  //       })
  //     );
  //     pushTrackingEventsToGAAndFB({
  //       category: "EligibilityCheckPopUp",
  //       actionType: "SkipEligibilityCheck",
  //     });
  //   } else if (data === "206-status") {
  //     // localStorage.setItem("isWowDiscountEligible", "false");
  //     // localStorage.setItem(
  //     //   "wowUserDetails",
  //     //   JSON.stringify({
  //     //     userInfo: wowCustomerData,
  //     //     isUnlimitedPlan: selectedPlan.isUnlimited,
  //     //   })
  //     // );
  //     // localStorage.setItem("reachSelectedPlanLine", "1");
  //     // localStorage.setItem(
  //     //   "reachSelectedPlanDetail",
  //     //   JSON.stringify({
  //     //     line: "1",
  //     //     plan: selectedPlan,
  //     //   })
  //     // );

  //     setIsCheckEligibilityClicked(true);
  //     setWowDataObject(wowObject);
  //     let data = {
  //       wowCustomerId: wowObject.ecid,
  //     };
  //     dispatch(HomeService.discountAppliedApi(data));
  //     pushTrackingEventsToGAAndFB({
  //       category: "EligibilityCheckPopUp",
  //       actionType: "CheckEligibilityFailedContinue",
  //     });
  //   }
  // };

  let isAuthenticatedUser = true;
  if (currentuser?.isAnonymous) {
    isAuthenticatedUser = false;
  } else if (currentuser?.isAnonymous === undefined) {
    isAuthenticatedUser = false;
  }
  useEffect(() => {
    if (!isAuthenticatedUser) {
      setWowCustomerData({
        ...initialWowCustomerData,
      });
    }
  }, [isAuthenticatedUser]);

  return (
    <div className="homepageMain">
      <HelmetProvider>
        {location.pathname === "/home" ? (
          <Helmet>
            <title>{seoData.home.title}</title>
            <meta
              property="og:site_name"
              content={globalVal && globalVal.long_name}
            />
            <meta property="og:title" content={seoData.home.title} />
            <meta
              property="og:description"
              content={seoData.home.description}
            />
            <meta name="keywords" content={seoData.home.keywords} />
            <meta name="description" content={seoData.home.description} />
            <meta
              name="robots"
              content={`${
                process.env.REACT_APP_SERVER_NAME === "wow-prod"
                  ? "index, follow"
                  : "noindex, nofollow"
              }`}
            />
          </Helmet>
        ) : (
          <Helmet>
            <title>{seoData.hometwo.title}</title>
            <meta
              property="og:site_name"
              content={globalVal && globalVal.long_name}
            />
            <meta property="og:title" content={seoData.hometwo.title} />
            <meta
              property="og:description"
              content={seoData.hometwo.description}
            />
            <meta name="keywords" content={seoData.hometwo.keywords} />
            <meta name="description" content={seoData.hometwo.description} />
            <meta
              name="robots"
              content={`${
                process.env.REACT_APP_SERVER_NAME === "wow-prod"
                  ? "index, follow"
                  : "noindex, nofollow"
              }`}
            />
          </Helmet>
        )}
      </HelmetProvider>
      {/* popup sectioin */}
      {/* {wowDiscountModal && wowEligibilityCheckCounter <= 3 && (
        <WowDiscount
          isShowModal={wowDiscountModal}
          onModalClose={() => {
            setWowDiscountModal(false);
            if (isAuthenticatedUser) {
              setWowCustomerData((prevState) => ({
                ...prevState,
                wowAccountNumber: "",
                wowAccountServiceZipcode: "",
              }));
            } else {
              setWowCustomerData({
                ...initialWowCustomerData,
              });
            }
          }}
          handleSubmit={handleSubmit}
          wowCustomerData={wowCustomerData}
          handleInputChange={handleInputChange}
          formDataValidation={formDataValidation}
          handleModalMenu={handleModalMenu}
          open={open}
          popoverId={popoverId}
          anchorEl={anchorEl}
          handleCloseModelMenu={handleCloseModelMenu}
          isAuthenticatedUser={isAuthenticatedUser}
          isCheckEligibilityClicked={isCheckEligibilityClicked}
          proceedToCheckoutHandler={proceedToCheckoutHandler}
        />
      )}

      {wowDiscountSuccessModal && (
        <WowDiscountSuccess
          isShowModal={wowDiscountSuccessModal}
          onModalClose={() => setWowDiscountSuccessModal(false)}
          wowEligibility={wowEligibility}
          proceedToCheckoutHandler={proceedToCheckoutHandler}
          wowCustomerData={wowCustomerData}
          isCheckEligibilityClicked={isCheckEligibilityClicked}
        />
      )}

      {wowDiscountUnsuccessModal && (
        <WowDiscountUnsuccess
          isShowModal={wowDiscountUnsuccessModal}
          onModalClose={() => setWowDiscountUnsuccessModal(false)}
          wowEligibilityCheckCounter={wowEligibilityCheckCounter}
          tryAgainHandler={() => {
            setWowDiscountUnsuccessModal(false);
            if (isAuthenticatedUser) {
              setWowCustomerData((prevState) => ({
                ...prevState,
                wowAccountNumber: "",
                wowAccountServiceZipcode: "",
              }));
            } else {
              setWowCustomerData({
                ...initialWowCustomerData,
              });
            }
            setWowDiscountModal(true);
            pushTrackingEventsToGAAndFB({
              category: "EligibilityCheckPopUp",
              actionType: "CheckEligibilityFailedRetry",
            });
          }}
          continueHandler={() => {
            proceedToCheckoutHandler("skip");
            setWowDiscountUnsuccessModal(false);
            pushTrackingEventsToGAAndFB({
              category: "EligibilityCheckPopUp",
              actionType: "CheckEligibilityFailedContinue",
            });
          }}
          handleModalMenu={handleModalMenu}
          open={open}
          popoverId={popoverId}
          anchorEl={anchorEl}
          handleCloseModelMenu={handleCloseModelMenu}
          wowCustomerData={wowCustomerData}
        />
      )}

      {wowDiscountUnsuccessModalWithMatchFound && (
        <WowDiscountUnsuccessMatchFound
          isShowModal={wowDiscountUnsuccessModalWithMatchFound}
          onModalClose={() => setWowDiscountUnsuccessModalWithMatchFound(false)}
          wowEligibility={wowEligibility}
          // continueHandler={() => {
          //   proceedToCheckoutHandler("206-status");
          //   setWowDiscountUnsuccessModalWithMatchFound(false);
          // }}
          continueHandler={proceedToCheckoutHandler}
          tryAgainHandler={() => {
            if (isAuthenticatedUser) {
              setWowCustomerData((prevState) => ({
                ...prevState,
                wowAccountNumber: "",
                wowAccountServiceZipcode: "",
              }));
            } else {
              setWowCustomerData({
                ...initialWowCustomerData,
              });
            }
            setWowDiscountUnsuccessModalWithMatchFound(false);
            setWowDiscountModal(true);
            pushTrackingEventsToGAAndFB({
              category: "EligibilityCheckPopUp",
              actionType: "CheckEligibilityFailedRetry",
            });
          }}
          wowCustomerData={wowCustomerData}
          isCheckEligibilityClicked={isCheckEligibilityClicked}
        />
      )} */}

      <HomePageBannerWow />
      <CheckCoverage />
      <div className="mediaQueriesHome">
        <div className="homePageContent">
          <div className="bestCellPhonePlan">
            <div className="HomePageTitle">
              {homePageWow.planSelections.title}
            </div>
            <div className="HomePageLine mx-auto"></div>
            {wowUnlimitedPlanResponse && wowNormalPlanResponse && (
              <div className="CardsPlanDiv">
                <div className="LandingTitle">
                  How many lines are on your plan
                </div>
                <div className="LandingLine"></div>
                <LineSelect
                  fiveGPlans={planData}
                  history={" "}
                  isAllowed={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <HomeInternetSection />

      <div className="features_highlight_btn">
        <h3>Features you'll love</h3>
      </div>

      <div className="spotlightDiv">
        <HomeSpotlightCards />
      </div>
      <div className="my-5">
        <DiscoverSectionWow />
      </div>

      {/* <div className="TrustPilotMainDiv">
        <div className="HomePageTrustTitle">
          {homePageWow.trustPilotSection.title}
        </div>
        <div className="HomePageTrustLine"></div>
        <div className="HomePageTrustSubTitle mt-3 mb-4">
          {homePageWow.trustPilotSection.subtitle}
        </div>
        <div className="TrustPilot">
          <TrustPilot />
        </div>
      </div> */}

      <StillExporing />
    </div>
  );
};

export default HomePage;
