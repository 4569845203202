import Modal from "../../Modal/Modal";
import ButtonNew from "../../Button/Button";
import CrossIconwithBg from "../../../assets/images/Home/crossIconWithBg.png";
import { Link } from "react-router-dom";
import "./WowDiscount.scss";
import { CircularProgress } from "@mui/material";

type Props = {
  isShowModal: boolean;
  onModalClose: any;
  wowEligibility: any;
  continueHandler: any;
  tryAgainHandler: any;
  isPostActive?: boolean;
  wowCustomerData?: any;
  isCheckEligibilityClicked?: boolean;
  hideContinue?:boolean;
};

const WowDiscountUnsuccessMatchFound = (props: Props) => {
  const {
    isShowModal,
    onModalClose,
    wowEligibility,
    continueHandler,
    tryAgainHandler,
    isPostActive,
    wowCustomerData,
    isCheckEligibilityClicked,
  } = props;
  return (
    <>
      <Modal
        isShowModal={isShowModal}
        onCloseModal={onModalClose}
        showClose={true}
        className="compatibilityPlanBring1"
      >
        <div className="p-3 text-center">
          <h5 className="SorryMsg">
            Sorry, you're not eligible for a discount.
          </h5>
          <div className="HomePageLine mx-auto mt-2 mb-3"></div>
          <img src={CrossIconwithBg} alt="success_tick_mark" width={30} />
          <>
            <h6 className="text-secondary mt-3">
              We're so glad you're a WOW! customer but eligibility requirements
              were not met.
            </h6>
            {isPostActive ? (
              <span className="my-3">
                <ButtonNew
                  primary={true}
                  className={"eligibility-check-btn"}
                  onClick={continueHandler}
                >
                  {isCheckEligibilityClicked ? (
                    <CircularProgress
                      className="loaderCheck"
                      color="secondary"
                    />
                  ) : (
                    "Continue"
                  )}
                </ButtonNew>
              </span>
            ) : (
              <span className="my-3">
                {/* <Link
                  to="/checkout"
                  state={{
                    ecid: wowEligibility.ecid,
                    wowCustomerData: wowCustomerData,
                    wowHsdState: "NOT_APPLICABLE",
                    isUnsuccessMatchFound: true,
                  }}
                  style={{
                    color: "var(--primary_color)",
                    textDecoration: "none",
                  }}
                > */}
                { !props.hideContinue && (
                  <ButtonNew
                    primary={true}
                    className={"eligibility-check-btn"}
                    onClick={() =>
                      continueHandler("206-status", {
                        ecid: wowEligibility.ecid,
                        wowCustomerData: wowCustomerData,
                        wowHsdState: "NOT_APPLICABLE",
                        isUnsuccessMatchFound: true,
                      })
                    }
                  >
                    {isCheckEligibilityClicked ? (
                      <CircularProgress
                        className="loaderCheck"
                        color="secondary"
                      />
                    ) : (
                      "Continue"
                    )}
                  </ButtonNew>
                )}
                {/* </Link> */}
              </span>
            )}
            <div className="mt-3">
              <span
                style={{
                  color: "var(--primary_color)",
                  cursor: "pointer",
                }}
                onClick={tryAgainHandler}
              >
                Try again with different account
              </span>
            </div>
          </>
        </div>
      </Modal>
    </>
  );
};

export default WowDiscountUnsuccessMatchFound;
