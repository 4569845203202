import { globalVal } from "./globalvalues";
export const seoData: any = {
  home: {
    title: `No-contract | Flexible plans | Fastest network | ${
      globalVal && globalVal.long_name
    }`,
    description: `Choose the plan that's perfect for your needs on the fastest 5G mobile network. Learn more about our flexible plans, unlimited data & ${
      globalVal && globalVal.short_name
    } customer service.`,
    keywords: `best 5G mobile network, best 5G phone network, best 5G cell phone carrier, 5G cell phone carrier, best 5G cell phone deals, no contract, no contract phone carriers, best 5G cell phone coverage, best budget cell phone plans, best budget phone plans, budget phone carriers`,
  },
  hometwo: {
    title: `${
      globalVal && globalVal.long_name
    } | Nation's fastest no-contract 5G mobile network `,
    description: `Experience the fastest 5G cell phone coverage with flexible plans on the nation's fastest no-contract network. You'll never overpay with ${
      globalVal && globalVal.long_name
    } plans.`,
    keywords: `${globalVal && globalVal.long_name_lowercase}, ${
      globalVal && globalVal.long_name_lowercase
    } plans, unlimited plans, flexible plans, Best 5G cell phone coverage, cell phone plans, no-contract 5G mobile network`,
  },
  bestFit: {
    title: `Money saving plans | Best 5G network coverage - ${
      globalVal && globalVal.long_name
    }`,
    description: `Overpaying for unused data? Save up to $300 a year with a Best Fit™ plan from ${
      globalVal && globalVal.long_name
    }. Unlimited talk & text. Best 5G mobile coverage.`,
    keywords: `best fit, best fit plans, money saving plans, Unlimited talk and text, save money on phone bill, annual savings, best 5g mobile coverage, save upto $300, ${
      globalVal && globalVal.long_name
    }.`,
  },
  gigsThatGive: {
    title: `No contract phone service with social impact - ${
      globalVal && globalVal.long_name
    }`,
    description: `Mobile plans starting @ just $20, where every plan helps connect someone in need. Unlimited talk and text on nation's largest 5G LTE network.`,
    keywords: `${
      globalVal && globalVal.short_name
    } wireless, no contract phone service, largest 5G network, mobile plans, connect someone in need, ${
      globalVal && globalVal.long_name
    }, social impact, women empowernment,`,
  },
  billCalculator: {
    title: `${
      globalVal && globalVal.long_name
    } | Phone bill calculator | Compare mobile plans `,
    description: `Time to switch your mobile phone carrier? Use our ${
      globalVal && globalVal.long_name
    } phone bill calculator to compare phone plans and know what you save by switching to ${
      globalVal && globalVal.long_name
    }`,
    keywords: `phone bill calculator, compare phone plans, ${
      globalVal && globalVal.long_name
    } bill calculator, cell phone plan calculator, compare mobile plans,`,
  },
  aboutUs: {
    title: `About US - ${globalVal && globalVal.long_name}`,
    description: `${
      globalVal && globalVal.long_name
    } is a socially conscious mobile service with the best mobile plans on the nation's most reliable 5G network. Great customer service 24/7.`,
    keywords: `${
      globalVal && globalVal.long_name
    }, best mobile plans, reliable 5g network, great customer service,`,
  },
  imeiCompatibility: {
    title: `Bring your own phone | Check device IMEI | ${
      globalVal && globalVal.long_name
    }`,
    description: `Own a mobile phone you love? Run a quick IMEI compatibility check and switch to ${
      globalVal && globalVal.long_name
    }. Experience the nation's fastest 5G mobile network!`,
    keywords: `${
      globalVal && globalVal.long_name
    } imei compatibility check, IMEI check, imei carrier check, device eligibility check, check phone carrier, imei carrier check free, check phone carrier by imei, carrier checker, carrier lookup by imei, ${
      globalVal && globalVal.long_name
    } imei check, device imei`,
  },
  cellPhonePlans: {
    title: `Best cell phone plans | No contract - ${
      globalVal && globalVal.long_name
    }`,
    description:
      "No-contract flexible cell phone plans starting @ $20. Unlimited talk and text. Fully digital mobile service on the largest 5G network.",
    keywords: `best cell phone plans, unlimited data plan, family mobile plans, check 5G coverage, no contract phone plans, international calling plans, reliable 5G network coverage, ${
      globalVal && globalVal.long_name
    }.`,
  },
  internationalServices: {
    title: `International Roaming | International Services | ${
      globalVal && globalVal.long_name
    }`,
    description: `Traveling abroad? ${
      globalVal && globalVal.long_name
    } offers stress-free International Roaming plans and International services in 135+ countries. No daily fees or per-country charges!`,
    keywords: `international roaming plans, international calling, ${
      globalVal && globalVal.long_name
    } international plans, international services, ${
      globalVal && globalVal.long_name
    } international roaming, international data plan, overseas roaming plans`,
  },
  contactUs: {
    title: `Contact Us | 24/7 support | ${globalVal && globalVal.long_name}`,
    description: `Reach best-in-class ${
      globalVal && globalVal.long_name
    } customer support 24/7. You can chat with our online support or call us. Bring your own phone and join ${
      globalVal && globalVal.long_name
    } today.`,
    keywords: `Contact ${
      globalVal && globalVal.long_name
    }, contact us, customer support, help center, contact information, customer service, online support, talk to someone`,
  },
  reviews: {
    title: `Customer Reviews - ${globalVal && globalVal.long_name}`,
    description:
      "Great support, reliable service, and affordable! Their Best Fit feature to bill you for a cheaper plan when you use less data is a nice money-saver, too.",
    keywords: `${
      globalVal && globalVal.long_name
    } reviews, customer reviews, reliable service,`,
  },
  terms: {
    title: `Terms and Conditions | Conditions of Service | ${
      globalVal && globalVal.long_name
    }`,
    description: `Review the terms and conditions of ${
      globalVal && globalVal.long_name
    } services, tariff, plan details, and network disclosures. Choose from flexible or unlimited plans today!`,
    keywords: `terms and conditions ${
      globalVal && globalVal.long_name
    }, terms of use, network disclosures, conditions of service`,
  },
  feedback: {
    title: `Feedback - ${globalVal && globalVal.long_name}`,
    description: `How easy was it to buy your ${
      globalVal && globalVal.long_name
    } plan? Your feedback means a lot to us and will help us improve the overall experience.`,
    keywords: `feedback, ${
      globalVal && globalVal.long_name
    }, customer experience.`,
  },
  discover: {
    title: `Discover best deals on devices and services - ${
      globalVal && globalVal.long_name
    }`,
    description: `Discover new and renewed unlocked devices, subscription services and other offers and deals in the Discover section of ${
      globalVal && globalVal.long_name
    }.`,
    keywords: `renewed phones, unlocked devices, subscription services, best deals on phones, ${
      globalVal && globalVal.long_name
    }.`,
  },
  help: {
    title: `How can we help you today? | 24X7 Support | ${
      globalVal && globalVal.long_name
    }`,
    description: `Need help with your mobile service or billing? Our customer support team is there 24X7 to provide you the information and tools you need to resolve all issues.`,
    keywords: `international calling, free international call, cheap international call, help center, support, customer service, customer support, 5G cell phone plans, best mobile coverage in US`,
  },
  login: {
    title: `Log in to your account | Create a new account | ${
      globalVal && globalVal.long_name
    }`,
    description: `Log in to your ${
      globalVal && globalVal.long_name
    } account or create a new account to check your data usage, pay your bills and manage your account anytime, anywhere.`,
    keywords: `Login, log in, sign in, signin, create new account, new email account, new account, create account, `,
  },
  signUp: {
    title: `Sign up | No-contract 5G mobile network | ${
      globalVal && globalVal.long_name
    }`,
    description: `Get unlimited talk & text with no contract phone service and 24x7 support on the nation's largest 5G network. Sign up for ${
      globalVal && globalVal.long_name
    }.`,
    keywords: `${
      globalVal && globalVal.short_name
    } sign up, best 5G mobile network, no contract phone service, new connection, largest 5G network, mobile plans, data plans`,
  },
  checkout: {
    title: `Checkout | Secured transactions | ${
      globalVal && globalVal.long_name
    }`,
    description: `Look into your billing and order details here. ${
      globalVal && globalVal.long_name
    } checkouts are easy, quick, and secure. Select from flexible plans and wide range of smartphones.`,
    keywords: `My cart, checkout, ${globalVal && globalVal.short_name} cart, ${
      globalVal && globalVal.short_name
    } checkout, secure checkout, easy checkout`,
  },
  services: {
    title: `International Calling | Roaming services | ${
      globalVal && globalVal.long_name
    }`,
    description: `Looking for reliable, affordable International Calling and Roaming services? Sign up to enjoy contract-free connectivity in 135+ countries with ${
      globalVal && globalVal.long_name
    }.`,
    keywords: `${
      globalVal && globalVal.long_name
    } international roaming, unlimited phone plans, international roaming plans, international calling, international services, data plans, roaming plans`,
  },
  account: {
    title: `Pay bills | Manage account | Upgrade plans | ${
      globalVal && globalVal.long_name
    }`,
    description: `Check your account information, invoice details, order status, or simply pay your bills. Get unlimited plans and International Roaming with ${
      globalVal && globalVal.long_name
    }.`,
    keywords: `${
      globalVal && globalVal.short_name
    } login, Pay your bill, bill pay, upgrade plan, check status, check order status, account information`,
  },
  postActivationHome: {
    title: `${
      globalVal && globalVal.short_name
    } account | Fastest 5G no-contract Network | ${
      globalVal && globalVal.long_name
    }`,
    description: `Welcome to the best-in-class 5G mobile network in America. With ${
      globalVal && globalVal.long_name
    }, get 24/7 customer service, enjoy flexible plans and a no-contract policy.`,
    keywords: `No contract, 24/7 customer support, International services, best 5G mobile network, best 5G phone network, best 5G cell phone carrier, best 5G cell phone deals`,
  },
  billing: {
    title: `Quick bill payment | Review your invoice | ${
      globalVal && globalVal.long_name
    }`,
    description: `Pay your ${
      globalVal && globalVal.long_name
    } bill, check the monthly usage on each line, or review your latest invoice - within a few clicks. Get on the nation's largest 5G network.`,
    keywords: `${
      globalVal && globalVal.long_name
    } bill, bill payment, online invoice, quick pay, online bill`,
  },
  privacy: {
    title: `${
      globalVal && globalVal.long_name
    } | Privacy policy | Know your rights`,
    description: `Your privacy and transparency are important to ${
      globalVal && globalVal.long_name
    }. Read our policies to understand the information we collect, use, and know your rights.`,
    keywords: `Privacy Policy, ${
      globalVal && globalVal.long_name
    } privacy policy, mobile services, Data privacy notice, ${
      globalVal && globalVal.long_name
    } cookie policy`,
  },
  earthHome: {
    title: `Home-EarthLink `,
    // description: `Never overpay for data with ${globalVal &&
    //     globalVal.long_name}. No contracts, Flexible plans, Unlimited talk & text. Best cell phone coverage. Great customer service.`,
    // keywords: `best mobile network, best phone network, best cell phone carrier, cell phone carrier, best cell phone deals no contract, no contract phone carriers, best cell phone coverage,`,
  },
  earthAccount: {
    title: `Account-EarthLink`,
  },
  earthDevice: {
    title: `Device-EarthLink`,
  },
  earthHelp: {
    title: `Help-EarthLink`,
  },
  earthBilling: {
    title: `Billing-EarthLink`,
  },
  earthTracking: {
    title: `Tracking History-EarthLink`,
  },
  earthActivation: {
    title: `Activation-EarthLink`,
  },
  earthLogin: {
    title: `Login-EarthLink`,
  },
  earthSetup: {
    title: `Setup-EarthLink`,
  },
  manageCards: {
    title: `Manage Cards-EarthLink`,
  },
};
