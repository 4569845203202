import React from "react";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import Button from "../../components/Button/Button";
import "./HomeSpotlightCards.scss";
import Fill from "../../assets/images/BillCalculator/fill-1-copy.svg";
import Fill1 from "../../assets/images/BillCalculator/fill-2-copy.svg";
import "../HomeComponents/BillPlanCard.scss";
import { globalVal } from "../../global/globalvalues";
import { pushTrackingEventsToGAAndFB } from "../../global/commonFunctions/GaAndFb";

type Props = {
  planDataObj?: any;
  onPlanSelect: (e: any) => void;
  unlimitedPlanDiscount?: any;
  normalPlanDiscount?: any;
  wowEligibilityCheckCounter?: number;
  planCost?: any;
  planPerLinesCost?: any;
  noOfLinesCost?: any;
  multiply?: any;
};

const PlanCard: React.FC<Props> = (props) => {
  const planSelectHandler = (data: any) => {
    props.onPlanSelect(data);
    pushTrackingEventsToGAAndFB({
      category: "Plans",
      actionType: "Selected Plan",
      label: `${data?.name} 1`,
    });
  };

  let planCost, planPerLinesCost: any;

  if (props.planDataObj.isUnlimited) {
    if (props.planDataObj.addLineSplitPrice) {
      let linePrice1 = 0;
      for (var i = 1; i <= props.multiply - 1; i++) {
        linePrice1 = linePrice1 + props.planDataObj.addLineSplitPrice[i];
      }
      linePrice1 =
        linePrice1 -
        props.unlimitedPlanDiscount.discountInDollar -
        props.unlimitedPlanDiscount.secondaryDiscountInDollar *
          (props.multiply - 1);
      planCost =
        ((100 - props.planDataObj.discountPctg) *
          props.planDataObj.baseLinePrice) /
          100 +
        linePrice1;
      planPerLinesCost = planCost / props.multiply;
    }
  } else {
    if (props.normalPlanDiscount) {
      planCost =
        ((100 - props.planDataObj.discountPctg) *
          (props.planDataObj.baseLinePrice -
            props.normalPlanDiscount?.discountInDollar)) /
          100 +
        (props.multiply - 1) *
          (props.planDataObj.additionalLinePrice -
            props.normalPlanDiscount?.secondaryDiscountInDollar);
      planPerLinesCost = Math.ceil(planCost / props.multiply);

      // localStorage.setItem("reachCoupon", coupon.data);
    }
  }

  return (
    <>
      <Card
        style={{
          width: "283px",
          height: "425px",
          // marginLeft: "0px",
          // marginRight: "0px",
          margin: "20px 23px",
        }}
        sx={{
          minWidth: 295,
          marginX: 4,
          maxWidth: 295,
          padding: 2,
          "&:hover": {
            border: `1px solid var(--border_dictator)`,
            borderBottom: `5px solid var(--border_dictator)`,
          },
        }}
      >
        <CardContent className="cards-head">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h4"
              component="div"
              sx={{ my: 1 }}
              style={{
                fontSize: "22px",
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "600",
              }}
            >
              {props.planDataObj.name}
            </Typography>
          </div>
          {/* pre purchase new design start */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              component="span"
              sx={{ my: 1 }}
              style={{
                fontSize: "18px",
                display: "inline-block",
                marginRight: "5px",
                boxSizing: "border-box",
                paddingBottom: "3px",
              }}
            >
              <img
                src={props.planDataObj.isUnlimited ? Fill : Fill1}
                alt=""
                height="18px"
              />
              {/* <img src={Fill} alt="" height="18px" /> */}
            </Typography>
            <Typography
              component="span"
              variant="h4"
              style={{ fontSize: "20px", fontWeight: "600" }}
            >
              {props.planDataObj.planData}GB
            </Typography>
            <Typography
              component="span"
              sx={{ my: 1 }}
              style={{
                fontSize: "15px",
                display: "inline-block",
                marginLeft: "5px",
              }}
            >
              {" "}
              - {props.planDataObj.isUnlimited ? "per line" : "sharable data"}
            </Typography>
          </div>
          <div className="BrandBillLine"></div>
          {/* pre purchase new design end */}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              component="span"
              style={{
                fontSize: "32px",
                fontFamily: "var(--font_family_Demibold)",
              }}
            >
              $
            </Typography>
            <Typography
              variant="h4"
              component="span"
              sx={{ my: 1 }}
              style={{ fontFamily: "var(--font_family_Demibold)" }}
            >
              {/* {props.planDataObj.baseLinePrice} */}
              {/* {props.wowEligibilityCheckCounter === 3
              ? props.planDataObj.baseLinePrice
              : props.planDataObj.isUnlimited
              ? props.planDataObj.baseLinePrice -
                props.unlimitedPlanDiscount?.discountInDollar
              : props.planDataObj.baseLinePrice -
                props.normalPlanDiscount?.discountInDollar} */}
              {Math.ceil(planPerLinesCost)}
            </Typography>
            <Typography component="span">
              <small>&nbsp;per line per month</small>
            </Typography>
          </div>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Typography
              sx={{ fontSize: 12, my: 1 }}
              color="text.secondary"
              gutterBottom
            >
              {globalVal.discountOfferPlanCards}
            </Typography>
          </div>
          <div
            style={{
              textAlign: "center",
              color: "var(--primary_color)",
              height: "30px",
            }}
          >
            <Typography component="span">
              <small>
                {`Additional lines: $${
                  props.wowEligibilityCheckCounter === 3
                    ? props.planDataObj.additionalLinePrice
                    : props.planDataObj.isUnlimited
                    ? props.planDataObj.additionalLinePrice -
                      props.unlimitedPlanDiscount?.secondaryDiscountInDollar
                    : props.planDataObj.additionalLinePrice -
                      props.normalPlanDiscount?.secondaryDiscountInDollar
                } per line, per month${
                  props.planDataObj.isUnlimited ? " for 2+ lines" : ", up to"
                }  ${
                  props.planDataObj.isUnlimited
                    ? ""
                    : props.planDataObj.maxLines - 1
                } ${props.planDataObj.isUnlimited ? "" : "lines"}`}
              </small>
            </Typography>
          </div>
          {/* <Typography component="div" sx={{ my: 3 }} color="text.secondary">
          <small>
            {`${
              props.planDataObj.isUnlimited
                ? "Individual data"
                : "sharable data"
            }`}{" "}
          </small>
        </Typography> */}
        </CardContent>
        <CardActions sx={{ mt: 3 }}>
          <Button
            onClick={() => planSelectHandler(props.planDataObj)}
            primary={true}
            sx={{ border: "1px solid var(--border_dictator) !important" }}
          >
            <Typography sx={{ color: "var(--border_dictator)" }}>
              Select Plan
            </Typography>
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default PlanCard;
