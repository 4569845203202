import firebase from './firebase';

const setAuthToken : any = () => {
        //     firebase.auth().onAuthStateChanged(user => {
        //     if (user) {
        //         user.getIdToken()
        //             .then(function(idToken) {
        //                 localStorage.setItem('accessToken', idToken);
        //             })
        //             .catch(function(error) {
        //                 console.log(error);
        //             });
        //     }
        // });

        return new Promise((resolve, reject) => {
                try {
                  firebase.auth()
                   .onAuthStateChanged(user => {
                    const token = user?.getIdToken();
                       resolve(token);
                   });
                } catch {
                  reject('api failed')
                }
        });
}


export default setAuthToken;