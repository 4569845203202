import {
   
    STORE_LOGIN_DATA
} from './actionTypes';

import { AuthActions, AuthState } from './types';

const initialState: AuthState = {   
     userData:{}

}

export default (state = initialState, action: AuthActions) => {
    switch (action.type) {
        case STORE_LOGIN_DATA:
            return {
                ...state,
                userData:action.payload.userData
            };

            default:
                return {
                    ...state,
                };
        

        }
        
    }