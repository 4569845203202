import {
    ACTIVE_PLANS,
    ACTIVE_PLANS_FAILURE,
    ACTIVE_PLANS_SUCCESS,
    COMPATIBILITY,
    COMPATIBILITY_SUCCESS,
    COMPATIBILITY_FAILURE,
    RESERVE_PROMO_COUPON,
    RESERVE_PROMO_COUPON_SUCCESS,
    RESERVE_PROMO_COUPON_FAILURE,
    GET_ALL_PLANS,
    GET_ALL_PLANS_SUCCESS,
    GET_ALL_PLANS_FAILURE,
} from './actionTypes';
import * as types from './types';

// get all plans
export const getAllPlans = (): types.GetAllPlanRequest => ({
    type: GET_ALL_PLANS,
});
export const getAllPlansSuccess = (payload: types.GetAllPlansSuccessPayload): types.GetAllPlanSuccess => ({
    type: GET_ALL_PLANS_SUCCESS,
    payload,
});
export const getAllPlansFailure = (payload: types.GetAllPlansFailurePayload): types.GetAllPlanFailure => ({
    type: GET_ALL_PLANS_FAILURE,
    payload,
});

// react plan
export const activePlans = (): types.ActivePlanRequest => ({
    type: ACTIVE_PLANS,
});

export const activePlansSuccess = (payload: types.ActivePlanSuccessPayload): types.ActivePlanSuccess => ({
    type: ACTIVE_PLANS_SUCCESS,
    payload,
});

export const activePlansFailure = (payload: types.ActivePlanFailurePayload): types.ActivePlanFailure => ({
    type: ACTIVE_PLANS_FAILURE,
    payload,
});

export const compatibility = (payload: types.CompatibilityRequestPayload): types.CompatibilityRequest => ({
    type: COMPATIBILITY,
    payload,
});

export const compatibilitySuccess = (payload: types.CompatibilitySuccessPayload): types.CompatibilitySuccess => ({
    type: COMPATIBILITY_SUCCESS,
    payload,
});

export const compatibilityFailure = (payload: types.CompatibilityFailurePayload): types.CompatibilityFailure => ({
    type: COMPATIBILITY_FAILURE,
    payload,
});


export const reservePromoCoupon = (payload: types.ReservePromoCouponRequestPayload): types.ReservePromoCouponRequest => ({
    type: RESERVE_PROMO_COUPON,
    payload,
});

export const reservePromoCouponSuccess = (payload: types.ReservePromoCouponRequestSuccessPayload): types.ReservePromoCouponSuccess => ({
    type: RESERVE_PROMO_COUPON_SUCCESS,
    payload,
});

export const reservePromoCouponFailure = (payload: types.ReservePromoCouponRequestFailurePayload): types.reservePromoCouponFailure => ({
    type: RESERVE_PROMO_COUPON_FAILURE,
    payload,
});
