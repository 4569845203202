import { Close } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import AndriodIcon from '../../assets/images/BringYourPhone/checkCompatibility_and.png';
import IosIcon from '../../assets/images/BringYourPhone/checkCompatibility_ios.png';
import ipadBanner from '../../assets/images/BringYourPhone/ipadBanner.webp';
import ipadBanner2 from '../../assets/images/BringYourPhone/ipadBanner2.webp';
import image from '../../assets/images/BringYourPhone/mainImage.webp';
import mobileBanner from '../../assets/images/BringYourPhone/mobileBanner.webp';
import ButtonNew from '../../components/Button/Button';
import SimpleModal from '../../components/Modal/Modal';
import TextFieldComponent from '../../components/Textfield/TextField';
import { pushTrackingEventsToGAAndFB } from '../../global/commonFunctions/GaAndFb';
import { updateToHubspot } from '../../global/commonFunctions/hubspot';
import { globalVal } from '../../global/globalvalues';
import { seoData } from '../../global/seoData';
import { bringYourPhone } from '../../global/texts/BringYourPhone/BringYourPhone';
import { HomeService } from '../../services/home.service';
import { PlanService } from '../../services/plan.service';
import './BringYourPhone.scss';
export function validateEmail(email: any) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

type Props = {};

const BringYourPhone = (props: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const ref1 = useRef<HTMLDivElement>(null);

    const { isData, compat } = useSelector((state: any) => state.plan);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoader, setisLoader] = useState(true);
    const [isSuccess, setisSuccess] = useState(false);
    const [imeiNumber, setimeiNumber] = useState('');
    const [imeiErr, setimeiErr] = useState('');
    const [checkClick, setcheckClick] = useState(false);
    const [imeiMailText, setimeiMailText] = useState('');
    const [emailCompatability, setemailCompatability] = useState('');
    const [emailErr, setemailErr] = useState('');
    const [haveTrouble, sethaveTrouble] = useState(false);
    const [target, settarget] = useState(null);
    const [name, setname] = useState('');
    const [isPostActive, setisPostActive] = useState(false);
    const [share, setshare] = useState(false);
    const [shareTarget, setshareTarget] = useState(null);
    const [imeiPormoCoupon, setimeiPormoCoupon] = useState('');
    const [imeiPormoHeading, setimeiPormoHeading] = useState('');
    const [imeiPormoText, setimeiPormoText] = useState('');
    const [imeiSocialText, setimeiSocialText] = useState('');
    const [isFail, setisFail] = useState(false);
    const model = compat?.data?.model || null;
    const modelNumber = model?.substr(7, 2);
    const make = compat?.data?.make || null;
    const iphoneVersion = 14;
    const [hideEsimBtn, sethideEsimBtn] = useState(false);

    const handleEnter = () => {
        // console.log("kiran");
    };

    const imeiHandler = (e: any) => {
        setimeiNumber(e.target.value.replace(/\s/g, ''));
        setimeiErr('');
    };

    const haveTroubleHandler = async () => {
        pushTrackingEventsToGAAndFB({
            category: 'Check Compatibility',
            actionType: 'Having Trouble',
        });
        let var1: any = await document.getElementById('having-trouble-div');
        await sethaveTrouble(true);
        await settarget(var1);
    };
    const closeTroubleHandler = () => {
        settarget(null);
        sethaveTrouble(false);
    };
    const shareHandler = async () => {
        // await pushTrackingEventsToGAAndFB({
        //     category: 'Check Compatibility',
        //     actionType: 'Share - Invite Friends',
        // });
        // let var1: any = await document.getElementById('share');
        // await this.setState({ share: true, shareTarget: var1 });
    };
    const closeShareHandler = () => {
        // this.setState({
        //     shareTarget: null,
        //     share: false,
        // });
    };
    const emailCompatibilityHandler = (e: any) => {
        setemailCompatability(e.target.value);
        setemailErr('');
    };
    const nameHandler = (e: any) => {
        setname(e.target.value);
    };
    const checkHandler = async (e: any) => {
        if (
            emailCompatability &&
            imeiNumber &&
            emailErr === '' &&
            imeiErr === '' &&
            imeiNumber.length === 15 &&
            validateEmail(emailCompatability) &&
            /^\d+$/.test(imeiNumber)
        ) {
            await setcheckClick(true);
            pushTrackingEventsToGAAndFB({
                category: 'Check Compatibility',
                actionType: 'Check',
                label: imeiNumber,
            });
            updateToHubspot(e, emailCompatability);
            dispatch(
                HomeService.getInTouchApi({
                    data: {
                        emailId: emailCompatability,
                        hubspotMap: {
                            email: emailCompatability,
                            is_test: globalVal.hubspot_is_test,
                            is_qa: globalVal.hubspot_is_qa,
                            // firstname: name,
                            ...(name !== '' && { firstname: name }),
                            imei_page_imei_entered: imeiNumber,
                        },
                    },
                }),
            );

            dispatch(
                PlanService.compatibilityApi({
                    imeiNumber: imeiNumber,
                }),
            );
        } else {
            if (emailCompatability === '') {
                setemailErr('Email cannot be empty');
            } else if (!validateEmail(emailCompatability)) {
                setemailErr('Please enter a valid email address.');
            }
            if (imeiNumber === '') {
                setimeiErr('IMEI or MEID number cannot be empty');
            } else if (imeiNumber !== '' && imeiNumber.length !== 15) {
                setimeiErr('Please enter a value of 15 characters long.');
            } else if (imeiNumber !== '' && !/^\d+$/.test(imeiNumber)) {
                setimeiErr('Invalid format.');
            }
        }
    };

    const tryAgainHandler = () => {
        setisSuccess(false);
        setisFail(false);
        setcheckClick(false);
    };

    const backButtonClick = () => {
        pushTrackingEventsToGAAndFB({
            category: 'Check Compatibility',
            actionType: 'Back',
        });
        setisSuccess(false);
    };

    const closeCompatModal = () => {
        setisSuccess(false);
        setisFail(false);
        setemailCompatability('');
        setimeiNumber('');
        setname('');
    };

    const viewPlanHandler = () => {
        pushTrackingEventsToGAAndFB({
            category: 'Check Compatibility',
            actionType: 'View Plans',
        });
        navigate('/home', { state: 'isPlans' });
        setTimeout(function () {
            scroller.scrollTo('bestCellPhonePlan', {
                duration: 1000,
                delay: 0,
                smooth: 'easeInOutQuart',
                smooh: 'easeOutQuart',
                offset: -100,
            });
        }, 1000);
    };

    useEffect(() => {
        setTimeout(() => {
            setisLoader(false);
        }, 2000);
        window.scroll(0, 0);
    }, []);

    useEffect(() => {
        if (make === 'Apple') {
            if (modelNumber >= iphoneVersion) {
                sethideEsimBtn(true);
            } else {
                sethideEsimBtn(false);
            }
        }
    }, [model, compat.data, iphoneVersion, make, modelNumber]);

    useEffect(() => {
        let data = compat.data;
        if (data && data.isValid) {
            setisSuccess(true);
            setcheckClick(false);
            updateToHubspot(data, emailCompatability);
            HomeService.getInTouchApi({
                data: {
                    emailId: emailCompatability,
                    hubspotMap: {
                        email: emailCompatability,
                        is_test: globalVal.hubspot_is_test,
                        is_qa: globalVal.hubspot_is_qa,
                        // firstname: name,
                        ...(name !== '' && { firstname: name }),
                        imei_page_imei_entered: data && data.imei,
                        imei_page_supported_carriers:
                            data &&
                            data.supportedCarriers &&
                            data.supportedCarriers.length > 0 &&
                            data.supportedCarriers[0]
                                ? data.supportedCarriers[0]
                                : '',
                    },
                },
            });

            if (imeiPormoCoupon) {
                dispatch(
                    PlanService.reservePromoCouponApi({
                        data: {
                            emailId: emailCompatability,
                            couponId: imeiPormoCoupon,
                            customerName: name,
                        },
                    }),
                );
            }
        } else {
            if (data) {
                setisFail(true);
                setcheckClick(false);
            }
        }
    }, [compat]);
    let data = compat.data;

    return (
        <div className="BringYourPhoneMain">
            <HelmetProvider>
                <Helmet>
                    <title>{seoData.imeiCompatibility.title}</title>
                    <meta property="og:site_name" content={globalVal && globalVal.long_name} />
                    <meta property="og:title" content={seoData.imeiCompatibility.title} />
                    <meta property="og:description" content={seoData.imeiCompatibility.description} />
                    <meta name="keywords" content={seoData.imeiCompatibility.keywords} />
                    <meta name="description" content={seoData.imeiCompatibility.description} />
                    <meta
                        name="robots"
                        content={`${
                            process.env.REACT_APP_SERVER_NAME === 'wow-prod' ? 'index, follow' : 'noindex, nofollow'
                        }`}
                    />
                </Helmet>
            </HelmetProvider>

            <div className="BringYourPhoneCarosel">
                <div className="OverlayPlan">
                    <div className="carouseImage">
                        <img className="web" width={'100%'} src={image} alt="illustration" />
                        <img className="iPadPro" src={ipadBanner} width={'100%'} alt="illustration" />
                        <img className="iPad" src={ipadBanner2} width={'100%'} alt="illustration" />
                        <img className="mobile" src={mobileBanner} width={'100%'} alt="illustration" />
                        <div className="contentCarousel">
                            <h1 className="Goodbye-mobile-store">{bringYourPhone.OverLay}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mediaQueriesBringYourPhone">
                <div className="BringYourPhoneContent">
                    <div className="compatibilityPlan1">
                        {!isSuccess ? (
                            <div onKeyPress={handleEnter}>
                                <div className="compatibilityPlanTitle">
                                    {bringYourPhone && bringYourPhone.compatibilityPlanTitle}
                                </div>

                                <div className="compatibilityPlanInput">
                                    <TextFieldComponent
                                        value={imeiNumber}
                                        // type={'number'}
                                        label="Enter Your Device IMEI or MEID Number"
                                        onChange={(e: any) => {
                                            if (/^[0-9 ]+$/.test(e.target.value) || e.target.value === '') {
                                                imeiHandler(e);
                                            }
                                        }}
                                        type="text"
                                        // type={
                                        //     window.screen.width <= 600
                                        //         ? "number"
                                        //         : "text"
                                        // }
                                        error={
                                            imeiErr !== '' ||
                                            (imeiNumber !== '' && !/^\d+$/.test(imeiNumber)) ||
                                            (imeiNumber !== '' && imeiNumber.length !== 15) ||
                                            (checkClick && imeiNumber === '')
                                        }
                                        helperText={
                                            imeiErr
                                                ? imeiErr
                                                : imeiNumber !== '' && !/^\d+$/.test(imeiNumber)
                                                ? 'Invalid format.'
                                                : imeiNumber !== '' && imeiNumber.length !== 15
                                                ? 'Please enter a value of 15 characters long.'
                                                : checkClick && imeiNumber === ''
                                                ? 'IMEI or MEID number cannot be empty'
                                                : ''
                                        }
                                        maxlength={20}
                                    />

                                    <div className="limit-div">
                                        {imeiNumber && imeiNumber.length ? imeiNumber.length : '0'}
                                        /15
                                    </div>

                                    <div className="phone-trouble-div">
                                        <div className="above-email-text">On most phones, dial *#06#</div>
                                        <div ref={ref}>
                                            <div
                                                className="having-trouble-div"
                                                id="having-trouble-div"
                                                onClick={haveTroubleHandler}
                                            >
                                                Having Trouble?
                                            </div>

                                            <Overlay
                                                show={haveTrouble}
                                                target={target}
                                                placement="bottom"
                                                container={ref.current}
                                                containerPadding={20}
                                            >
                                                <Popover id="popover-contained">
                                                    <Popover.Body>
                                                        <div
                                                            className="HaveTroubleToolTip"
                                                            onClick={closeTroubleHandler}
                                                        >
                                                            <div className="HaveTroubleHeader">
                                                                <Close
                                                                    onClick={closeTroubleHandler}
                                                                    className="closeIcon"
                                                                />
                                                                <div className="HaveTroubleHeaderText">
                                                                    {' '}
                                                                    How to find IMEI number?
                                                                </div>
                                                            </div>
                                                            <div className="haveTroubleDetaildiv">
                                                                <div className="detailImgDiv">
                                                                    <img src={IosIcon} alt="" className="detailImg" />
                                                                </div>
                                                                <div className="detailContent">
                                                                    <div className="detailHead">IOS</div>
                                                                    <div className="detailSubText addMarginBottom">
                                                                        Go to Settings - General - About - IMEI
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="haveTroubleDetaildiv">
                                                                <div className="detailImgDiv">
                                                                    <img
                                                                        src={AndriodIcon}
                                                                        alt=""
                                                                        className="detailImg"
                                                                    />
                                                                </div>
                                                                <div className="detailContent">
                                                                    <div className="detailHead">Andriod</div>
                                                                    <div className="detailSubText">
                                                                        Go to Settings - About Phone - Status - IMEI
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Popover.Body>
                                                </Popover>
                                            </Overlay>
                                        </div>
                                    </div>

                                    <div className="input-div">
                                        <TextFieldComponent
                                            value={emailCompatability}
                                            label="Email"
                                            onChange={emailCompatibilityHandler}
                                            error={
                                                emailErr !== '' ||
                                                (emailCompatability !== '' && !validateEmail(emailCompatability)) ||
                                                (checkClick && emailCompatability === '')
                                            }
                                            helperText={
                                                emailErr
                                                    ? emailErr
                                                    : emailCompatability !== '' && !validateEmail(emailCompatability)
                                                    ? 'Please enter a valid email address.'
                                                    : checkClick && emailCompatability === ''
                                                    ? 'Please enter a valid email address.'
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div className="input-div">
                                        <TextFieldComponent
                                            value={name}
                                            label="Name (Optional)"
                                            onChange={nameHandler}
                                        />
                                    </div>

                                    {
                                        <div className="check-button">
                                            {/* <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                      >
                        Success
                      </Button> */}
                                            <ButtonNew
                                                variant="contained"
                                                color="primary"
                                                fullWidth={true}
                                                size="large"
                                                primary={true}
                                                onClick={checkClick ? () => null : checkHandler}
                                                disabled={
                                                    imeiErr !== '' ||
                                                    (imeiNumber !== '' && !/^\d+$/.test(imeiNumber)) ||
                                                    (imeiNumber !== '' && imeiNumber.length !== 15) ||
                                                    imeiNumber === ''
                                                }
                                            >
                                                {checkClick ? (
                                                    <div className="loaderCheck">
                                                        <CircularProgress color="secondary" />
                                                    </div>
                                                ) : (
                                                    'Check'
                                                )}
                                            </ButtonNew>
                                        </div>
                                    }
                                </div>
                            </div>
                        ) : (
                            <div className="SuccessCompatibility">
                                <div className="BackButton" onClick={backButtonClick}>
                                    <ArrowBackIcon className="arrow_icon" />
                                    <div>Back</div>
                                </div>
                                <div className="individualRow">
                                    <div className="textLeft">Compatible Mobile Carrier</div>
                                    <div className="textRight">
                                        {data &&
                                            data.supportedCarriers &&
                                            data.supportedCarriers.map((item: any) => {
                                                return `${item} `;
                                            })}
                                    </div>
                                </div>
                                {data && data.make && data.model && (
                                    <div className="individualRow">
                                        <div className="textLeft">Device Make and Model</div>
                                        <div className="textRight">{`${data.make} ${data.model
                                            .split(' ')
                                            .splice(0, 3)
                                            .join(' ')}`}</div>
                                    </div>
                                )}
                                <div className="individualRow">
                                    <div className="textLeft">Mode</div>
                                    <div className="textRight">{`${data && data.mode}`}</div>
                                </div>
                                <div className="individualRow">
                                    <div className="textLeft">WiFi Calling Support</div>
                                    <div className="textRight">{`${data && data.wifiCalling}`}</div>
                                </div>
                                <div className="individualRow">
                                    <div className="textLeft">Is reported lost or stolen</div>
                                    <div className="textRight">{`${data && data.isLostOrStolen}`}</div>
                                </div>
                                {hideEsimBtn ? (
                                    <>
                                        <div className="individualRow">
                                            <div className="textLeft">eSIM Services</div>
                                            <div className="textRight noEsimSupport">{`${
                                                data && data.esimAvailable ? 'Not supported' : ''
                                            }`}</div>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}

                                {/* <div className="CardDiv">
                  <div className="innerDiv">
                    <div className="LeftDiv">
                      <img alt="gifticon" className="image" src={giftImage} />
                      <div>
                        <div className="text1">{imeiPormoHeading}</div>
                    
                        <div className="text2">{imeiPormoText}</div>
                      </div>
                    </div>
                    {imeiPormoCoupon && (
                      <div className="RightDiv">
                        <div className="innerRightDiv">
                          <div className="code">{imeiPormoCoupon}</div>
                          <div ref={ref1}>
                            <img
                              id="share"
                              onClick={shareHandler}
                              alt="shareIcon"
                              className="image"
                              src={shareImage}
                            />
                            <Overlay
                              show={share}
                              target={shareTarget}
                              placement="top"
                              container={ref1.current}
                              containerPadding={20}
                            >
                              <Popover id="popover-contained">
                                <Popover.Body>
                                  <div
                                    className="shareContainer"
                                    onClick={closeShareHandler}
                                  >
                                    <div className="shareHeader">
                                      <Close
                                        onClick={closeShareHandler}
                                        className="closeIcon"
                                      />
                                      <div className="shareHeaderText">
                                        Invite Friends
                                      </div>
                                    </div>
                                   
                                  </div>
                                </Popover.Body>
                              </Popover>
                            </Overlay>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div> */}
                                <div className="viewPlanBtn">
                                    <ButtonNew primary={true} variant="contained" onClick={viewPlanHandler}>
                                        View Plans
                                    </ButtonNew>
                                </div>
                            </div>
                        )}
                    </div>
                    {!isSuccess && (
                        <div className="AccordionComponent">
                            <div className="HeadingAccordian">{bringYourPhone && bringYourPhone.accordionTitle}</div>
                            {bringYourPhone &&
                                bringYourPhone.accordion.map((tab: any, index: string) => (
                                    <Accordion key={index}>
                                        <Accordion.Item eventKey={index}>
                                            <Accordion.Header>{tab.label}</Accordion.Header>
                                            <Accordion.Body
                                                dangerouslySetInnerHTML={{
                                                    __html: tab.description,
                                                }}
                                            >
                                                {/* {tab.description} */}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                ))}
                        </div>
                    )}
                </div>
            </div>

            {/* <Footer /> */}
            {isFail && (
                <SimpleModal
                    isShowModal={isSuccess || isFail}
                    onCloseModal={closeCompatModal}
                    showClose={true}
                    className="compatibilityPlanBring"
                >
                    <div className="BummerDiv">
                        <img alt="failure" className="modalImage" src={IosIcon} />

                        <div className="BummerContent">
                            Sorry! This device is not compatible with {globalVal && globalVal.short_name} mobile.
                        </div>
                        <div className="shopPhoneButton">
                            <ButtonNew
                                primary={true}
                                variant="contained"
                                onClick={() => window.open(`${globalVal && globalVal.shopPhone}`, '_blank')}
                            >
                                Shop phones
                            </ButtonNew>
                        </div>
                        <div className="tryAgain" onClick={tryAgainHandler}>
                            Try Again
                        </div>
                    </div>
                </SimpleModal>
            )}
        </div>
    );
};

export default BringYourPhone;
