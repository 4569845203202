import { Button } from "@mui/material";
import "./Button.scss";
interface IButtonProps {
  endIcon?: any;
  iconMarginLeft?: string;
  active?: any;
  backgroundColor?: string;
  fontSize?: string;
  fontWeight?: any;
  width?: string;
  height?: string;
  borderRadius?: string;
  margin?: string;
  marginTop?: string;
  color?: any;
  padding?: string;
  borderLeft?: string;
  minWidth?: string;
  onClick?: any;
  className?: string;
  disabled?: any;
  children?: any;
  boxShadow?: any;
  border?: string;
  opacity?: string;
  primary?: boolean;
  variant?: any;
  fullWidth?: boolean;
  size?: any;
  sx?: any;
  buttonType?: any;
}
const ButtonNew = (props: IButtonProps) => {
  let icon = null;
  if (props.endIcon) {
    icon = (
      <img
        alt=""
        src={props.endIcon}
        style={{
          marginLeft: props.iconMarginLeft ? props.iconMarginLeft : "0px",
        }}
      />
    );
  }
  if (props.primary) {
    return (
      <Button
        onClick={props.onClick}
        className={
          props.disabled
            ? "ButtonPrimary disabledBtn"
            : props.className
            ? `${props.className} ButtonPrimary`
            : "ButtonPrimary"
        }
        disabled={props.disabled ? props.disabled : false}
        endIcon={icon}
        variant={props.variant}
        color={props.color || "primary"}
        fullWidth={props.fullWidth}
        size={props.size}
        sx={props.sx}
        type={props.buttonType}
      >
        {props.children}
      </Button>
    );
  } else {
    return (
      <Button
        onClick={props.onClick}
        className={
          props.disabled
            ? "ButtonSecondary disabledBtn"
            : props.className
            ? `${props.className} ButtonSecondary`
            : "ButtonSecondary"
        }
        disabled={props.disabled ? props.disabled : false}
        endIcon={icon}
        variant={props.variant}
        type={props.buttonType}
      >
        {props.children}
      </Button>
    );
  }
};

export default ButtonNew;
