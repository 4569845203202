// get city.state by zipcode
export const GET_STATE_CITY_BY_ZIPCODE = "GET_STATE_CITY_BY_ZIPCODE";
export const GET_STATE_CITY_BY_ZIPCODE_SUCCESS =
  "GET_STATE_CITY_BY_ZIPCODE_SUCCESS";
export const GET_STATE_CITY_BY_ZIPCODE_FAILURE =
  "GET_STATE_CITY_BY_ZIPCODE_FAILURE";

// Calculate tax by address type 1
export const TAX_CALCULATION_BY_ADDRESS_TYPE1 =
  "TAX_CALCULATION_BY_ADDRESS_TYPE1";
export const TAX_CALCULATION_BY_ADDRESS_TYPE1_SUCCESS =
  "TAX_CALCULATION_BY_ADDRESS_TYPE1_SUCCESS";
export const TAX_CALCULATION_BY_ADDRESS_TYPE1_FAILURE =
  "TAX_CALCULATION_BY_ADDRESS_TYPE1_FAILURE";

// Calculate tax by address type 2
export const TAX_CALCULATION_BY_ADDRESS_TYPE2 =
  "TAX_CALCULATION_BY_ADDRESS_TYPE2";
export const TAX_CALCULATION_BY_ADDRESS_TYPE2_SUCCESS =
  "TAX_CALCULATION_BY_ADDRESS_TYPE2_SUCCESS";
export const TAX_CALCULATION_BY_ADDRESS_TYPE2_FAILURE =
  "TAX_CALCULATION_BY_ADDRESS_TYPE2_FAILURE";

// Create a customer
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAILURE = "CREATE_CUSTOMER_FAILURE";

// get a customer by username
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAILURE = "GET_CUSTOMER_FAILURE";
export const RESET_CUSTOMER = "RESET_CUSTOMER";

// get a customer by id
export const GET_SHIPMENT_ORDER_BY_ID = "GET_SHIPMENT_ORDER_BY_ID";
export const GET_SHIPMENT_ORDER_BY_ID_SUCCESS =
  "GET_SHIPMENT_ORDER_BY_ID_SUCCESS";
export const GET_SHIPMENT_ORDER_BY_ID_FAILURE =
  "GET_SHIPMENT_ORDER_BY_ID_FAILURE";

// check email availblity
export const CHECK_EMAIL_AVAILBLITY = "CHECK_EMAIL_AVAILBLITY";
export const CHECK_EMAIL_AVAILBLITY_SUCCESS = "CHECK_EMAIL_AVAILBLITY_SUCCESS";
export const CHECK_EMAIL_AVAILBLITY_FAILURE = "CHECK_EMAIL_AVAILBLITY_FAILURE";

// update customer
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";

// update secondary customer
export const UPDATE_SECONDARY_CUSTOMER = "UPDATE_SECONDARY_CUSTOMER";
export const UPDATE_SECONDARY_CUSTOMER_FAILURE =
  "UPDATE_SECONDARY_CUSTOMER_FAILURE";
export const UPDATE_SECONDARY_CUSTOMER_SUCCESS =
  "UPDATE_SECONDARY_CUSTOMER_SUCCESS";

// update customer source
export const UPDATE_CUSTOMER_SOURCE = "UPDATE_CUSTOMER_SOURCE";
export const UPDATE_CUSTOMER_SOURCE_SUCCESS = "UPDATE_CUSTOMER_SOURCE_SUCCESS";
export const UPDATE_CUSTOMER_SOURCE_FAILURE = "UPDATE_CUSTOMER_SOURCE_FAILURE";

// get coupon eligibility
export const ELIGIBILITY = "ELIGIBILITY";
export const ELIGIBILITY_SUCCESS = "ELIGIBILITY_SUCCESS";
export const ELIGIBILITY_FAILURE = "ELIGIBILITY_FAILURE";

// coupon api
export const COUPON = "COUPON";
export const COUPON_SUCCESS = "COUPON_SUCCESS";
export const COUPON_FAILURE = "COUPON_FAILURE";

// payment post api
export const PAYMENT = "PAYMENT";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAILURE = "PAYMENT_FAILURE";

// payment get api
export const PAYMENT_GET = "PAYMENT_GET";
export const PAYMENT_GET_SUCCESS = "PAYMENT_GET_SUCCESS";
export const PAYMENT_GET_FAILURE = "PAYMENT_GET_FAILURE";

// coupon reserve
export const COUPON_RESERVE = "COUPON_RESERVE";
export const COUPON_RESERVE_SUCCESS = "COUPON_RESERVE_SUCCESS";
export const COUPON_RESERVE_FAILURE = "COUPON_RESERVE_FAILURE";

// login with number
export const LOGIN_NUMBER = "LOGIN_NUMBER";
export const LOGIN_NUMBER_SUCCESS = "LOGIN_NUMBER_SUCCESS";
export const LOGIN_NUMBER_FAILURE = "LOGIN_NUMBER_FAILURE";

// shipping api

export const SHIPPING_GET_DATA = "SHIPPING_GET_DATA";
export const SHIPPING_SUCCESS = "SHIPPING_SUCCESS";
export const SHIPPING_FAILURE = "SHIPPING_FAILURE";

// hsd add api
export const HSD_ADD = "HSD_ADD";
export const HSD_ADD_SUCCESS = "HSD_ADD_SUCCESS";
export const HSD_ADD_FAILURE = "HSD_ADD_FAILURE";
