import { AppBar } from "@mui/material";
// import { globalVal } from "../../global/globalvalues";
import { Link, useLocation } from "react-router-dom";
import footerLogo from "../../assets/images/commonImages/wow_logo_white.svg";
import "./Footer.scss";
import applicationData from "../../../package.json";
import { pushTrackingEventsToGAAndFB } from "../../global/commonFunctions/GaAndFb";
import { globalVal } from "../../global/globalvalues";
// import Insta from "../../assets/images/commonImages/instaIcon.svg";
// import FB from "../../assets/images/commonImages/facebookIcon.svg";
// import YT from "../../assets/images/commonImages/youtubeIcon.svg";
// import Twitter from "../../assets/images/commonImages/twitterIcon.svg";

type Props = {
  background?: string;
  className?: string;
  isFixed?: boolean;
};

const Footer = (props: Props) => {
  const location = useLocation();
  let customerDetail: any = localStorage.getItem("customerDetail");
  customerDetail = JSON.parse(customerDetail);

  const sendGAEventsHandler = (event: any) => {
    pushTrackingEventsToGAAndFB({
      category: "Footer",
      actionType: `${event}`,
    });
  };
  return location.pathname === "/checkout" ? (
    <></>
  ) : (
    <AppBar
      position={!props.isFixed ? "absolute" : "static"}
      //   position="static"
      className={`footer ${props.className}`}
      style={{
        background: props.background ? props.background : "#57585a",
      }}
    >
      <div className="footerInner web">
        <div className="links">
          <div className="links1">
            <div>
              <img alt="" src={footerLogo} className="reachImg" />
            </div>
            {/* <div className="appsLinkmobile">
              <div
                className="icons"
                onClick={() => window.open(globalVal.facebook, "_blank")}
              >
                <img src={FB} alt="" height="20px" width="20px " />
              </div>
              <div
                className="icons"
                onClick={() => window.open(globalVal.youtube, "_blank")}
              >
                <img src={YT} alt="" height="20px" width="30px " />
              </div>
              <div
                className="icons"
                onClick={() => window.open(globalVal.twitter, "_blank")}
              >
                <img src={Twitter} alt="" height="20px" width="20px " />
              </div>
              <div
                className="icons"
                onClick={() => window.open(globalVal.instagram, "_blank")}
              >
                <img src={Insta} alt="" height="20px" width="20px " />
              </div>
            </div> */}
          </div>
          <div className="moreLinks">
            <div className="moreLinks1">
              {customerDetail && customerDetail?.simPaid ? null : (
                <div className="links2">
                  <Link
                    to="/home"
                    className="text-decoration-none"
                    onClick={() => sendGAEventsHandler("Plans")}
                  >
                    <div className="subLinks">Plans</div>
                  </Link>
                </div>
              )}
              <div className="links2">
                <Link
                  to="/contact-us"
                  className="text-decoration-none"
                  onClick={() => sendGAEventsHandler("Contact")}
                >
                  <div className="subLinks">Contact</div>
                </Link>
              </div>
              <div className="links2">
                <a
                  href={globalVal.homeInternet}
                  target="_blank"
                  className="text-decoration-none"
                >
                  <div className="subLinks">Home Internet</div>
                </a>
              </div>

              <div className="links2">
                {/* <div className="headerText">
                  About {globalVal && globalVal.short_name}
                </div> */}
                <Link
                  to="/international_services"
                  className="text-decoration-none"
                  onClick={() => sendGAEventsHandler("Services")}
                >
                  <div className="subLinks">Services</div>
                </Link>
              </div>
              <div className="links2">
                <Link
                  to="/imei-compatibility-check"
                  className="text-decoration-none"
                  onClick={() =>
                    sendGAEventsHandler("Check Device Compatibility")
                  }
                >
                  <div className="subLinks">Check Device Compatibility</div>
                </Link>
              </div>
              <div className="links2">
                <Link
                  to="/bill-calculator"
                  className="text-decoration-none"
                  onClick={() => sendGAEventsHandler("Bill Calculator")}
                >
                  <div className="subLinks">Bill Calculator</div>
                </Link>
              </div>
              <div className="links2">
                <Link
                  to="/terms"
                  className="text-decoration-none"
                  onClick={() => sendGAEventsHandler("Terms")}
                >
                  <div className="subLinks">Terms</div>
                </Link>
              </div>
              <div className="links2">
                <Link
                  to="/privacy-policy"
                  className="text-decoration-none"
                  onClick={() => sendGAEventsHandler("Policies")}
                >
                  <div className="subLinks">Policies</div>
                </Link>
              </div>
              {/* <div className="links3">
            
                <Link to="/about-us" className="text-decoration-none">
                  <div className="subLinks">About us</div>
                </Link>
              </div> */}
            </div>
          </div>

          {/* <div className="appsLinkweb">
            <div
              className="icons"
              onClick={() => window.open(globalVal.facebook, "_blank")}
            >
              <img src={FB} alt="" height="15px" width="20px " />
            </div>
            <div
              className="icons"
              onClick={() => window.open(globalVal.instagram, "_blank")}
            >
              <img src={Insta} alt="" height="15px" width="20px " />
            </div>

            <div
              className="icons"
              onClick={() => window.open(globalVal.twitter, "_blank")}
            >
              <img src={Twitter} alt="" height="15px" width="20px " />
            </div>
            <div
              className="icons"
              onClick={() => window.open(globalVal.youtube, "_blank")}
            >
              <img src={YT} alt="" height="15px" width="20px " />
            </div>
          </div> */}
        </div>
        <div className="copyright-text">© Copyright 2022</div>
        <div className="disclaimer-text">
          Disclaimer: WOW! mobile phone service and service support is provided
          by Reach Mobile, a third party not affiliated with WOW!. Services are
          billed by WOW! mobile powered by Reach, which is separate from other
          WOW! services.
        </div>
      </div>
      {/* mobile footer starts */}
      <div className="footerInner mobile">
        <div className="links">
          <div className="links1">
            <div>
              <img alt="" src={footerLogo} className="reachImg" />
            </div>
          </div>
          <div className="container">
            <div className="row">
              {customerDetail && customerDetail?.simPaid ? null : (
                <div className="col-4">
                  <div className="">
                    <Link to="/home" className="text-decoration-none">
                      <div className="subLinks">Plans</div>
                    </Link>
                  </div>
                </div>
              )}
              <div className="col-4">
                <div className="">
                  <Link to="/contact-us" className="text-decoration-none">
                    <div className="subLinks">Contact us</div>
                  </Link>
                </div>
              </div>
              <div className="col-4">
                <div className="">
                  <a
                    href={globalVal.homeInternet}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="homeinternetlink"
                  >
                    <div className="subLinks">Home Internet</div>
                  </a>
                </div>
              </div>

              <div className="col-4">
                <div className="">
                  <Link
                    to="/international_services"
                    className="text-decoration-none"
                  >
                    <div className="subLinks">Services</div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="">
                  <Link to="/bill-calculator" className="text-decoration-none">
                    <div className="subLinks">Bill Calculator</div>
                  </Link>
                </div>
              </div>
              <div className="col-4">
                <div className="">
                  <Link
                    to="/imei-compatibility-check"
                    className="text-decoration-none"
                  >
                    <div className="subLinks">Check Device Compatibility</div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="">
                  <Link to="/terms" className="text-decoration-none">
                    <div className="subLinks">Terms</div>
                  </Link>
                </div>
              </div>
              <div className="col-4">
                <div className="">
                  <Link to="/privacy-policy" className="text-decoration-none">
                    <div className="subLinks">Policies</div>
                  </Link>
                </div>
              </div>
              <div className="col-4"></div>
              <div className="col-4"></div>
            </div>
          </div>
        </div>
        <div className="copyright-text">© Copyright 2022</div>
        <div className="disclaimer-text">
          Disclaimer: WOW! mobile phone service and service support is provided
          by Reach Mobile, a third party not affiliated with WOW!. Services are
          billed by WOW! mobile powered by Reach, which is separate from other
          WOW! services.
        </div>
      </div>
      <div className="version_indicator">{`v ${applicationData.version}`}</div>
    </AppBar>
  );
};

export default Footer;
