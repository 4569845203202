import Modal from "../../Modal/Modal";
import TextFieldComponent from "../../Textfield/TextField";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { CircularProgress, Popover, Typography } from "@mui/material";
import ButtonNew from "../../Button/Button";
import { Link, useLocation } from "react-router-dom";
import "./WowDiscount.scss";

type Props = {
  isShowModal: boolean;
  onModalClose: any;
  handleSubmit: any;
  wowCustomerData: any;
  handleInputChange: any;
  formDataValidation: boolean;
  handleModalMenu: any;
  open: boolean;
  popoverId: any;
  anchorEl: any;
  handleCloseModelMenu: any;
  isAuthenticatedUser: boolean;
  isCheckEligibilityClicked: boolean;
  proceedToCheckoutHandler?: any;
  isPostActive?: any;
};

const WowDiscount = (props: Props) => {
  const location = useLocation();

  const {
    isShowModal,
    onModalClose,
    handleSubmit,
    wowCustomerData,
    handleInputChange,
    formDataValidation,
    handleModalMenu,
    open,
    popoverId,
    anchorEl,
    handleCloseModelMenu,
    isAuthenticatedUser,
    isCheckEligibilityClicked,
    proceedToCheckoutHandler,
    isPostActive,
  } = props;
  const popOverStyle = { p: 1, fontSize: "14px", color: "#9c9c9c" };

  return (
    <Modal
      isShowModal={isShowModal}
      onCloseModal={onModalClose}
      showClose={true}
      className="compatibilityPlanBring1"
    >
      <div className="p-3 text-center modalInner">
        <h5 className="eligibility-modal-header">
          Provide your WOW! internet account information to validate your
          eligibility
        </h5>
        <div className="HomePageLine m-auto"></div>
        <h6 className="text-secondary mt-3 eligibility-modal-Subheader">
          Offer available to eligible and verified WOW! residential internet
          customers only
        </h6>
        <form className="my-3" onSubmit={(e: any) => handleSubmit(e)}>
          <div className="form-group mb-3 ">
            <TextFieldComponent
              name="wowAccountNumber"
              value={wowCustomerData.wowAccountNumber}
              label="Last 4 digits of WOW! account number"
              maxlength={4}
              onChange={(e: any) => {
                if (/^[0-9]+$/.test(e.target.value) || e.target.value === "") {
                  handleInputChange(e);
                }
              }}
            />
            <div className="errorMessage">
              {formDataValidation && wowCustomerData.wowAccountNumber === ""
                ? "WOW! account number cannot be empty"
                : formDataValidation &&
                  wowCustomerData.wowAccountNumber !== "" &&
                  wowCustomerData.wowAccountNumber.length !== 4
                ? "WOW! account number must be 4 characters long"
                : ""}
            </div>
            <small className="form-text text-muted ">
              Where can I find{" "}
              <span style={{ color: "var(--primary_color)" }}>
                my account number?{" "}
                <span className="arrowMenuToggle" onClick={handleModalMenu}>
                  {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </span>
              </span>
            </small>
            <Popover
              id={popoverId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseModelMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{ minWidth: 560 }}
            >
              <Typography sx={popOverStyle}>
                1. Your WOW! billing statement
              </Typography>
              <Typography sx={popOverStyle}>
                2. The WOW! online account management website
              </Typography>
              <Typography sx={popOverStyle}>
                3. The WOW! order confirmation email or
              </Typography>
              <Typography sx={popOverStyle}>
                {" "}
                4. The WOW! online account management
              </Typography>
            </Popover>
          </div>
          <div className="form-group mb-3">
            <TextFieldComponent
              name="wowAccountServiceZipcode"
              value={wowCustomerData.wowAccountServiceZipcode}
              label="WOW! account service ZIP code"
              maxlength={5}
              onChange={(e: any) => {
                if (/^[0-9]+$/.test(e.target.value) || e.target.value === "") {
                  handleInputChange(e);
                }
              }}
            />
            <div className="errorMessage">
              {formDataValidation &&
              wowCustomerData.wowAccountServiceZipcode === ""
                ? "WOW! account service ZIP code cannot be empty"
                : formDataValidation &&
                  wowCustomerData.wowAccountServiceZipcode !== "" &&
                  wowCustomerData.wowAccountServiceZipcode.length !== 5
                ? "WOW! account service ZIP code must be 5 characters long"
                : ""}
            </div>
          </div>
          <div className="form-group mb-3">
            <TextFieldComponent
              name="wowCustomerLastName"
              value={wowCustomerData.wowCustomerLastName}
              label="WOW! account holder last name"
              onChange={(e: any) => {
                if (
                  /^[a-zA-Z ]+$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  handleInputChange(e);
                }
              }}
              disabled={isAuthenticatedUser}
            />
            <div className="errorMessage">
              {formDataValidation && wowCustomerData.wowCustomerLastName === ""
                ? "WOW! account holder last name cannot be empty"
                : ""}
            </div>
          </div>
          <ButtonNew
            buttonType={"submit"}
            primary={true}
            className={"eligibility-check-btn"}
          >
            {isCheckEligibilityClicked ? (
              <CircularProgress className="loaderCheck" color="secondary" />
            ) : (
              "Check Eligibility"
            )}
          </ButtonNew>
        </form>
        {isPostActive ? null : (
          <Link
            to={"/checkout"}
            style={{
              color: "var(--primary_color)",
              textDecoration: "none",
              fontWeight: "var(--font_weight_2)",
            }}
            state={{ userSkipped: true }}
          >
            {location.pathname.toLowerCase() !== "/ul-bundle-promo" && (
              <span onClick={() => proceedToCheckoutHandler("skip")}>
                Skip Eligibility Check
              </span>
            )}
          </Link>
        )}
      </div>
    </Modal>
  );
};

export default WowDiscount;
