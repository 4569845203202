import { v4 as uuidv4 } from 'uuid';
import { domainMap, domainName } from '../config/config';
import setAuthToken from './auth';
import axios from './axios';
import local from './localStorage';

type Params = {
    method: string;
    url: string;
    body?: any;
    token?: string;
    params?: any;
    headers?: any;
    auth?: boolean;
    other?: boolean;
    other1?: string;
    contentType?: string;
};
class ApiJunction {
    async makeRequest(params: Params) {
        const resToken = await setAuthToken();
        // localStorage.setItem('accessToken',resToken);

        let user: any =
            domainName === domainMap.T2 ? local.getItem('earthlinkCurrentUser') : local.getItem('currentuser');
        user = JSON.parse(user);
        //let token = localStorage.getItem('accessToken');
        let token =
            params.token || domainName === domainMap.T2 ? localStorage.getItem('earthlinkAccessToken') : resToken;
        // axios.interceptors.request.use(setHeaders(token));

        // axios.defaults.headers.common['txnid'] = user && user.uid;
        axios.defaults.headers.common['txnid'] = uuidv4();
        axios.defaults.headers.common['x-reach-mvne'] = 'TMOBILE';
        // axios.defaults.headers.common['authorization'] =
        //     params && params.auth ? 'fefc0fcf-5fa4-4966-9db1-fd7896c4b3e5' : `Bearer ${token}`;

        axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
        // axios.defaults.headers.common['authorization'] = '2e656744-dc7e-4d8a-bdec-6df49de51bb2';

        // axios.defaults.headers.common[
        //   params.auth ? "x-account-src" : "x-reach-src"
        // ] = params.auth ? `reach` : "web";

        axios.defaults.headers.common['x-reach-src'] = 'web';
        axios.defaults.headers.common['x-account-src'] = 'wow';

        // axios.defaults.headers.common['Content-Type'] = `multipart/form-data`;
        if (params.contentType) {
            axios.defaults.headers.post['Content-Type'] = params.contentType;
        } else {
            axios.defaults.headers.common['Content-Type'] = `application/x-www-form-urlencoded; charset=UTF-8`;
            axios.defaults.headers.patch['Content-Type'] = `application/x-www-form-urlencoded; charset=UTF-8`;
        }
        if (params.other) {
            axios.defaults.headers.common['x-is-primary'] = params.other ? 'Yes' : 'No';
        }
        if (params.other1) {
            axios.defaults.headers.common['x-reach-mvne'] = params.other1 ? params.other1 : '';
        }
        // axios.defaults.headers.post['header1'] = 'value'
        // return axios[params.method](params.url, params.body)

        if (params.method === 'get') {
            return axios.get(params.url, { params: params.params });
        } else if (params.method === 'post') {
            return axios
                .post(params.url, params.body)
                .then((res: any): any => {
                    // if (res.code === 200) {
                    //     return res.data;
                    // }
                    return res;
                })
                .then((err) => {
                    return err;
                });
        } else if (params.method === 'put') {
            return axios.put(params.url, params.body);
        } else if (params.method === 'delete') {
            return axios.delete(params.url, params.body);
        } else if (params.method === 'patch') {
            return axios.patch(params.url, params.body, params.headers);
        } else {
            return { success: false, msg: 'No method provided, get, post?' };
        }
    }

    login(params: Params) {
        return axios.post(params.url, params.body);
    }
}

// function setHeaders(token) {
//     return function (config) {

//         let tokenHeader = `Bearer ${token}`
//         config.headers['Authorization'] = tokenHeader;
//         config.headers['Content-Type'] = 'application/json';

//         return config;
//     };
// }

export default new ApiJunction();
