import React, { useState, useEffect } from "react";
import ButtonNew from "../Button/Button";
import TextFieldComponent from "../Textfield/TextField";
import { validateEmail } from "../../global/commonFunctions/ValidateEmail";
import { updateToHubspot } from "../../global/commonFunctions/hubspot";
import { globalVal } from "../../global/globalvalues";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { HomeService } from "../../services/home.service";
import { homePageWow } from "../../global/texts/Homepage";

import "./StillExporing.scss";
import {
  getInTouchFailure,
  resetGetInTouch,
} from "../../redux/HomePage/actions";
import { pushTrackingEventsToGAAndFB } from "../../global/commonFunctions/GaAndFb";

type Props = {
  // homeAction: any
};

const StillExporing = (props: Props) => {
  const dispatch = useDispatch();
  const { getInTouch, getInTouchError } = useSelector(
    (state: any) => state.home
  );
  const [emailSharedCoverage, setemailSharedCoverage] = useState(false);
  const [submitClick, setsubmitClick] = useState(false);
  const [email, setEmail] = useState("");

  const submitHandler = (e: any) => {
    setsubmitClick(true);
    if (email) {
      updateToHubspot(e, email);

      dispatch(
        HomeService.getInTouchApi({
          data: {
            emailId: email,
            hubspotMap: {
              email: email,
              is_test: globalVal.hubspot_is_test,
              is_qa: globalVal.hubspot_is_qa,
            },
          },
        })
      );
      pushTrackingEventsToGAAndFB({
        category: "Still Exploring",
        actionType: "Subscribe",
      });
    }
  };

  const emailHandler = (e: any) => {
    setEmail(e.target.value);
  };

  const helperTextHandler = () => {
    if (submitClick && email === "") {
      return "Email cannot be empty";
    }
    return email !== "" && !validateEmail(email) ? "Enter valid email" : "";
  };

  useEffect(() => {
    dispatch(resetGetInTouch({}));

    return () => {
      dispatch(resetGetInTouch({}));
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(getInTouch) && getInTouch.status === "SUCCESS") {
      setEmail("");
      setsubmitClick(false);
      setemailSharedCoverage(true);
    } else {
      setEmail("");
      setsubmitClick(false);
      setemailSharedCoverage(false);
    }
  }, [getInTouch]);

  useEffect(() => {
    if (!_.isEmpty(getInTouchError)) {
      setEmail("");
      setsubmitClick(false);
    }
  }, [getInTouchError]);

  return (
    <div className="HomePageSignUp">
      <div className="HomePageTitle">Still exploring?</div>
      <div className="HomePageLine"></div>
      <div className="HomePageSignUpSubTitle">
        {homePageWow.stillExploring.title}
      </div>
      {emailSharedCoverage ? (
        <div>
          <div className="EmailSuccess">
            {/* <img src={CheckMarkIcon} alt="" className="emailSuccessImg" /> */}
            <div className="emailSuccessText">
              Yaay! Thanks for signing up with us!
            </div>
          </div>
          <div className="keepintouchText">
            {homePageWow.stillExploring.keepintouchText}
          </div>
        </div>
      ) : (
        <div>
          <div
            className="HomePageSignUpTxtField"
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                submitHandler(e);
              }
            }}
          >
            {/* <img src={ThreeLines} className="threeLine web" /> */}
            {/* <img src={ThreeLinesMobile} className="threeLine mobile" /> */}
            <div className="homeLeadgenInput">
              <TextFieldComponent
                value={email}
                label="Enter your email address"
                onChange={emailHandler}
                error={
                  (submitClick && !email) ||
                  (email !== "" && !validateEmail(email))
                }
                helperText={helperTextHandler()}
              />
            </div>
            <div className="homeLeadgenBtn">
              <ButtonNew
                onClick={submitHandler}
                variant="contained"
                color="primary"
                fullWidth={true}
                size="large"
                primary={true}
                sx={{
                  fontFamily: "var(--font_family_Semibold) !important",
                  fontSize: "16px",
                  fontWeight: "var(--font_weight_2)",
                }}
              >
                Submit
              </ButtonNew>
            </div>
          </div>
          {/* <div className="HomePageSignUpBtn">
            <Button primary={false} onClick={this.submitHandler}>
                Submit
            </Button>
        </div> */}
        </div>
      )}
    </div>
  );
};

export default StillExporing;
